import React, { useEffect, useState } from "react";
import {
  Button as MuiButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";
import SearchBar from "./SearchBar";
import ViewTeamPopup from "./ViewTeamPopup";
import {
  deleteOrg,
  fetchData,
  fetchFilteredData,
  handleDownloadCSV,
  editOrg,
  formatDate,
} from "./utils";
import CodePopup from "./CodePopup";

const PaginationControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  & > button {
    margin: 0 5px;
  }

  .page-info {
    margin: 0 10px;
    font-weight: bold;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Table = () => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [viewTeamModalOpen, setViewTeamModalOpen] = useState(false);
  const [codeModalOpen, setCodeModalOpen] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [selectedCode, setSelectedCode] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editOrganization, setEditOrganization] = useState({
    id: "",
    name: "",
    manager_email: "",
    max_capacity: 0,
  });

  useEffect(() => {
    fetchData(currentPage, itemsPerPage, setLoading, setFilteredData, setCount);
  }, [currentPage]);

  useEffect(() => {
    fetchFilteredData(
      setLoading,
      filteredData,
      setFilteredData,
      setCount,
      searchTerm,
      fetchData,
      currentPage,
      itemsPerPage
    );
  }, [searchTerm]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(count / itemsPerPage);

  const onCloseViewTeamModal = () => {
    setViewTeamModalOpen(false);
  };

  const onCloseCodeModal = () => {
    setCodeModalOpen(false);
  };

  const handleEditClick = (organization) => {
    setEditOrganization(organization);
    setEditModalOpen(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditOrganization((prevOrg) => ({
      ...prevOrg,
      [name]: value,
    }));
  };

  const handleEditSubmit = async () => {
    await editOrg(editOrganization);
    setEditModalOpen(false);
    fetchData(currentPage, itemsPerPage, setLoading, setFilteredData, setCount);
  };

  return (
    <>
      <ViewTeamPopup
        open={viewTeamModalOpen}
        onClose={onCloseViewTeamModal}
        id={selectedOrg}
      />

      <CodePopup
        open={codeModalOpen}
        onClose={onCloseCodeModal}
        code={selectedCode}
      />

      <Dialog open={editModalOpen} onClose={() => setEditModalOpen(false)}>
        <DialogTitle>Edit Organizations</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Organization Name"
            name="name"
            value={editOrganization.name}
            onChange={handleEditChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Manager Email"
            name="manager_email"
            value={editOrganization.manager_email}
            onChange={handleEditChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Max Capacity"
            name="max_capacity"
            type="number"
            value={editOrganization.max_capacity}
            onChange={handleEditChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={() => setEditModalOpen(false)} color="secondary">
            Cancel
          </MuiButton>
          <MuiButton onClick={handleEditSubmit} color="primary">
            Save
          </MuiButton>
        </DialogActions>
      </Dialog>

      <SearchBar
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleDownloadCSV={() => {
          handleDownloadCSV(filteredData);
        }}
      />
      <p style={{ marginBottom: "20px" }}>Number Of Organizations: {count}</p>
      <div className="data-table-diagram">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Bundle</th>
                  <th>Manager Email</th>
                  <th>Capacity</th>
                  <th>Created At</th>
                  <th>Deactivated At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 ? (
                  filteredData.map((item) => (
                    <tr key={item.id}>
                      <td>{item.name || "N/A"}</td>
                      <td>{item.is_active ? "Active" : "Inactive"}</td>
                      <td>
                        {item.SignupCodes[0]?.Bundle.display_name || "N/A"}
                      </td>
                      <td>{item.manager_email || "N/A"}</td>
                      <td>
                        {item.current_capacity} / {item.max_capacity}
                      </td>
                      <td>{formatDate(item.created_at)}</td>
                      <td>
                        {item.is_active
                          ? "N/A"
                          : formatDate(item.deactivated_at) || "N/A"}
                      </td>{" "}
                      <td>
                        <ButtonContainer>
                          <MuiButton
                            onClick={() => {
                              setViewTeamModalOpen(true);
                              setSelectedOrg(item.id);
                            }}
                            variant="contained"
                            sx={{
                              backgroundColor: "#007bff",
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#0056b3",
                              },
                            }}
                          >
                            View Team
                          </MuiButton>
                          <MuiButton
                            onClick={() => {
                              setCodeModalOpen(true);
                              setSelectedCode({
                                code: item.SignupCodes[0]?.code,
                                code_expiry: item.SignupCodes[0]?.code_expiry,
                                regenerated_at:
                                  item.SignupCodes[0]?.code_regenerated_at,
                                organization_id: item.id,
                              });
                            }}
                            variant="contained"
                            sx={{
                              backgroundColor: "#007bff",
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#0056b3",
                              },
                            }}
                          >
                            View Code
                          </MuiButton>
                          <MuiButton
                            onClick={() => handleEditClick(item)}
                            variant="contained"
                            sx={{
                              backgroundColor: "#ffc107", // Yellow color for "Edit"
                              color: "#000",
                              "&:hover": {
                                backgroundColor: "#e0a800",
                              },
                            }}
                          >
                            Edit
                          </MuiButton>
                          <MuiButton
                            onClick={() => {
                              if (
                                window.confirm(
                                  "Are you sure you want to deactivate this organization?"
                                )
                              ) {
                                deleteOrg(
                                  item.id,
                                  filteredData,
                                  setFilteredData
                                );
                              }
                            }}
                            variant="contained"
                            sx={{
                              backgroundColor: "red",
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#cc0000",
                              },
                            }}
                            startIcon={<DeleteIcon />}
                          >
                            Deactivate
                          </MuiButton>
                        </ButtonContainer>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8">No organizations found</td>
                  </tr>
                )}
              </tbody>
            </table>
            <PaginationControls>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              >
                First
              </MuiButton>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </MuiButton>
              <span className="page-info">
                Page {currentPage} of {totalPages}
              </span>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </MuiButton>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                Last
              </MuiButton>
            </PaginationControls>
          </>
        )}
      </div>
    </>
  );
};

export default Table;
