import { post } from "../../../global/globalUtils";

export const validateResetToken = async (token) => {
  try {
    const response = await post("/auth/validate-reset-token", {
      resetToken: token,
    });

    if (!response.ok) {
      const errorData = await response.json(); // Get more detailed error info if available
      throw new Error(errorData.message || "Validation failed");
    }

    const data = await response.json(); // Parsing the JSON response
    return {
      success: data.status === "success",
      message: data.message,
    };
  } catch (error) {
    console.error("Token validation failed:", error);
    return { success: false, message: error.message };
  }
};

export const resetPassword = async (token, password, confirmPassword) => {
  if (!token || !password || !confirmPassword) {
    console.error("Missing required fields");
    return { success: false, message: "All fields are required" };
  }

  if (password !== confirmPassword) {
    console.error("Passwords do not match");
    return { success: false, message: "Passwords do not match" };
  }

  try {
    const response = await post("/auth/reset-password", {
      token,
      password,
    });
    const data = await response.json();

    if (data.status === "success") {
      return { success: true, message: "Password reset successful" };
    } else {
      console.error("Password reset failed");
      return { success: false, message: "Password reset failed" };
    }
  } catch (error) {
    console.error("Password reset failed:", error);
    return { success: false, message: error.message };
  }
};

export const passwordRegex =
  /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])(?=.*[a-z]).{8,}$/;

// Password validation function
export const validatePassword = (password) => {
  return passwordRegex.test(password)
    ? ""
    : "Password must have at least 8 characters, 1 capital letter, 1 special character, and 1 number";
};

// Compare two passwords
export const comparePasswords = (password, confirmPassword) => {
  if (password !== confirmPassword) {
    return "Passwords do not match";
  } else if (!passwordRegex.test(password)) {
    return "Password must have at least 8 characters, 1 capital letter, 1 special character, and 1 number";
  } else {
    return "";
  }
};
