import React from "react";
import styled from "styled-components";

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background: ${({ theme }) => (theme === "dark" ? "#444" : "#fff")};
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: ${({ theme }) => (theme === "dark" ? "#fff" : "#1e2836")};
  position: relative; /* For positioning the close button */
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 25px;
  color: ${({ theme }) => (theme === "dark" ? "#fff" : "#1e2836")};
  cursor: pointer;
`;

const PopupTitle = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 18px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;

const SmallButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  color: white;
  background-color: ${({ color }) => color};

  &:hover {
    opacity: 0.9;
  }
`;

const CancellationPopup = ({ theme, title, onCancel, onConfirm, onClose }) => {
  return (
    <PopupOverlay>
      <PopupContent theme={theme}>
        <CloseButton theme={theme} onClick={onClose}>
          &times;
        </CloseButton>
        <PopupTitle>{title}</PopupTitle>
        <p>
          Your Plus subscription will be canceled but will remain active until
          the end of your billing period on Jan 16, 2025.
        </p>
        <ButtonContainer>
          <SmallButton color="#ccc" onClick={onCancel}>
            Go Back
          </SmallButton>
          <SmallButton color="#f44336" onClick={onConfirm}>
            Confirm
          </SmallButton>
        </ButtonContainer>
      </PopupContent>
    </PopupOverlay>
  );
};

export default CancellationPopup;
