import React from "react";
import { BookmarkProvider } from "../components/side-panel/tabs/bookmarks/BookmarkContext";
import { SearchProvider } from "../components/side-panel/tabs/global-search/SearchContext";
import { ThemeProvider } from "../components/themes/ThemeContext";
import GlobalStyle from "../global/GlobalStyle";
import { ToastContainer } from "react-toastify";
import { Toaster } from "sonner";
import { TranslationLoadingProvider } from "../components/column/TranslationLoadingContext";
import { TutorialProvider } from "../components/tutorial/TutorialContext";

const Providers = ({ children }) => {
  return (
    <ThemeProvider>
      <TranslationLoadingProvider>
        <TutorialProvider>
          <BookmarkProvider>
            <SearchProvider>
              {/* GlobalStyle can be considered a global provider for styled-components */}
              <GlobalStyle />
              <ToastContainer limit={2} style={{ zIndex: 10 }} />
              <Toaster richColors position="top-center" />
              {children}
            </SearchProvider>
          </BookmarkProvider>
        </TutorialProvider>
      </TranslationLoadingProvider>
    </ThemeProvider>
  );
};

export default Providers;
