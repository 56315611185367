import React, { useEffect, useState } from "react";
import { Button as MuiButton } from "@mui/material";
import styled from "styled-components";
import ViewTeamPopup from "./ViewTagPopup";
import { fetchData, getTags } from "./utils";
import { website } from "../../../../global/globalUtils";
import avatar from "../../../../assets/images/default-avatar.avif";

const PaginationControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; /* Center align items vertically */
  margin-top: 20px;

  & > button {
    margin: 0 5px;
  }

  .page-info {
    margin: 0 10px;
    font-weight: bold;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  gap: 10px; /* Space between buttons */
`;

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin-bottom: 20px;
  width: 100%;
`;

const SearchInput = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex: 1;
  margin-right: 20px;
  outline: none;
  max-width: 400px;

  &:focus {
    border-color: #007bff;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const StyledLabel = styled.label`
  color: #333;
  margin-bottom: 4px;
  margin-right: 8px;
`;

const StyledSelect = styled.select`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
  color: #333;
  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const Table = () => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [channels, setChannels] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [viewTagsModal, setViewTagsModal] = useState(false);
  const [tags, setTags] = useState([]);
  const [source, setSource] = useState("");
  const [postCountOrder, setPostCountOrder] = useState("desc");

  useEffect(() => {
    fetchData(
      searchTerm,
      currentPage,
      itemsPerPage,
      setChannels,
      setCount,
      setLoading,
      source,
      postCountOrder
    );
  }, [currentPage, itemsPerPage, searchTerm, source, postCountOrder]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(count / itemsPerPage);

  return (
    <section className="content-area-table">
      <ViewTeamPopup
        open={viewTagsModal}
        onClose={() => setViewTagsModal(false)}
        tags={tags}
      />
      <SearchBarContainer>
        <h1>Channels Table</h1>
        <SearchInput
          type="text"
          placeholder="Search Channels By Title or Username..."
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
        />
        <SelectContainer>
          <div>
            <StyledLabel htmlFor="source">Source:</StyledLabel>
            <StyledSelect
              name="source"
              id="source"
              onChange={(e) => setSource(e.target.value)}
            >
              <option value="">All Sources</option>
              <option value="Telegram">Telegram</option>
              <option value="Twitter">Twitter</option>
            </StyledSelect>
          </div>
          <div>
            <StyledLabel htmlFor="postCountOrder">
              Post Count Order:
            </StyledLabel>
            <StyledSelect
              name="postCountOrder"
              id="postCountOrder"
              onChange={(e) => setPostCountOrder(e.target.value)}
            >
              <option value="desc">Descending Order</option>
              <option value="asc">Ascending Order</option>
            </StyledSelect>
          </div>
        </SelectContainer>
      </SearchBarContainer>
      <p style={{ marginBottom: "20px" }}>Number Of Channels: {count}</p>
      <div className="data-table-diagram">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th>Icon</th>
                  <th>Username</th>
                  <th>Title</th>
                  <th>Post Count</th>
                  <th>Subscriber Count</th>
                  <th>Type</th>
                  <th>Source URL</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {channels.length > 0 ? (
                  channels.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <img
                          src={
                            item.profile_picture_url
                              ? item.profile_picture_url
                              : item.image
                              ? `${website}${item.image}`
                              : avatar
                          }
                          alt="Channel Icon"
                          onError={(e) => {
                            e.target.onerror = null; // Prevent infinite loop if fallback image also fails
                            e.target.src = avatar; // Set fallback image
                          }}
                          style={{ width: "50px", height: "50px" }}
                        />
                      </td>
                      <td>{item.sender_username || "N/A"}</td>
                      <td>{item.chat_title || "N/A"}</td>
                      <td>{item.total_posts.toLocaleString()}</td>
                      <td>{item.subscriber_count.toLocaleString()}</td>
                      <td>{item.Source?.name}</td>
                      <td>{item.source_url}</td>
                      <td>
                        <ButtonContainer>
                          <MuiButton
                            onClick={() => {
                              getTags(item.id, setTags, setViewTagsModal);
                            }}
                            variant="contained"
                            sx={{
                              backgroundColor: "#007bff",
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#0056b3",
                              },
                            }}
                          >
                            View Tags
                          </MuiButton>
                        </ButtonContainer>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8">No channels found</td>
                  </tr>
                )}
              </tbody>
            </table>
            <PaginationControls>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              >
                First
              </MuiButton>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </MuiButton>
              <span className="page-info">
                Page {currentPage} of {totalPages}
              </span>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </MuiButton>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                Last
              </MuiButton>
            </PaginationControls>
          </>
        )}
      </div>
    </section>
  );
};

export default Table;
