import { Popup } from "react-leaflet";
import styled from "styled-components";
import { useCallback } from "react";
import L from "leaflet";
import { website } from "../../../global/globalUtils";
import { Marker } from "react-leaflet";
import {
  createCustomIcon,
  getCategoryByCid,
  formatDateOrTime,
} from "../map_utils/MapUtiles";
import { useFilter } from "../contexts/FilterContext";

const InnerContent = styled.div`
  text-align: center;
  padding: 10px;
  border-radius: 8px;

  div {
    margin-bottom: 10px; /* Add margin between elements */
  }

  div:last-child {
    margin-bottom: 0; /* Remove margin from the last element */
  }
`;

const CategoryLabel = styled.div`
  font-weight: bold; /* Bold text */
  color: #007bff; /* Primary color */
  font-size: 18px; /* Larger font size */
  margin-bottom: 5px; /* Add margin below */
`;

const DetailText = styled.div`
  font-size: 14px; /* Adjust font size */
  color: #333; /* Darker text color */
  margin-bottom: 5px; /* Add margin below */
`;

const TimestampText = styled.span`
  font-size: 14px; /* Smaller font size */
  color: #777; /* Gray color for timestamp */
`;

export const MyMarker = ({
  item,
  index,
  handleMarkerClick,
  addMarkerToRef,
}) => {
  const { dbCategories } = useFilter();
  const category = getCategoryByCid(dbCategories, item.cid);

  const handleMouseOver = useCallback((e) => {
    e.target.openPopup();
  }, []);

  const handleMouseOut = useCallback((e) => {
    e.target.closePopup();
  }, []);

  const markerRef = useCallback(
    (marker) => {
      if (marker) {
        addMarkerToRef(item.pid, marker); // Store marker reference when available
      }
    },
    [item, addMarkerToRef]
  );

  return (
    <Marker
      key={`${item.pid}-${index}`}
      position={[item.lat, item.lng]}
      icon={createCustomIcon(L, website, category)}
      ref={markerRef}
      eventHandlers={{
        click: handleMarkerClick,
        mouseover: handleMouseOver,
        mouseout: handleMouseOut,
      }}
    >
      <Popup>
        <InnerContent>
          <CategoryLabel>{item.channel_name}</CategoryLabel>
          <DetailText>{item?.text || "No details"}</DetailText>
          <TimestampText>
            {formatDateOrTime(item?.timestamp || new Date())}
          </TimestampText>
        </InnerContent>
      </Popup>
    </Marker>
  );
};
