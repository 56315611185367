export const steps = [
  {
    target: "body",
    content: (
      <div>
        <h2>Welcome to Teledeck</h2>
        <p>
          Teledeck is a news monitoring platform that allows you to monitor news
          from various sources in real-time.
        </p>
      </div>
    ),
    placement: "center",
  },
  {
    target: "body",
    content: (
      <div>
        <h2>Tutorial</h2>
        <p>
          The idea is to create columns that will display news from various
          sources based on your preferences. Let's get started!
        </p>
      </div>
    ),
    placement: "center",
  },
  {
    target: "#new-column",
    content: "Click here to create your first column.",
    placement: "bottom",
    spotlightClicks: true,
  },
  {
    target: "#name-column",
    content:
      "Setup your column here by giving it a name, selecting an icon, and setting up notifications.",
    placement: "right",
    spotlightClicks: true,
  },
  {
    target: "#add-channel",
    content: "You can add channels to your column here.",
    placement: "right",
    spotlightClicks: true,
  },
  {
    target: "#done-button",
    content: "Click here when you're done.",
    placement: "bottom",
    spotlightClicks: true,
    disableOverlay: true,
  },
  {
    target: "#filters-dropdown",
    content: "You can filter your column here.",
    placement: "bottom",
    spotlightClicks: true,
  },
  {
    target: "#column-header-search",
    content: "Search for a keyword in your column here.",
    placement: "bottom",
  },
  {
    target: "#column-header-date",
    content: "Set a maximum date for your column here.",
    placement: "bottom",
  },
  {
    target: "#column-header-lang",
    content: "Translate your column to a different language here.",
    placement: "bottom",
  },
  {
    target: "#column-header-export",
    content: "Export your column to a CSV file here or download its media.",
    placement: "bottom",
  },
  {
    target: "#column-header-filters",
    content: "Filter your column by text, image, video, or previews here.",
    placement: "bottom",
  },
  {
    target: "#sidepanel-button",
    content: "This is where you click to enter the side panel.",
    placement: "left",
    spotlightClicks: true,
  },
  {
    target: "#sidepanel-trending",
    content: "This is where you can see the trending news.",
    placement: "left",
  },
  {
    target: "#sidepanel-bookmarks",
    content:
      "This is where you can see your bookmarked posts and categorize them.",
    placement: "left",
  },
  {
    target: "#sidepanel-search",
    content: "This is where you can search all columns at once.",
    placement: "left",
  },
  {
    target: "#sidepanel-editor",
    content:
      "This is where you create and author your own news posts based on your bookmarks.",
    placement: "left",
  },
  {
    target: "#map-button",
    content:
      "This is where you can see a live map of news events as they happen in the world's hotspots.",
    placement: "bottom",
    spotlightClicks: true,
  },
];
