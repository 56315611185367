import React, { useEffect } from "react";
import Joyride, { STATUS } from "react-joyride";
import { useTutorial } from "./TutorialContext";
import { steps } from "./utils";

const Tutorial = () => {
  const { stepIndex, setStepIndex, run, setRun } = useTutorial();

  const stepsWithDisabledNext = [2, 5, 6, 12];
  const isLastStep = stepIndex === steps.length - 1;

  const completeTutorial = () => {
    setRun(false);
    localStorage.setItem("tutorialCompleted", "true");
    window.location.reload();
  };

  useEffect(() => {
    const hasCompletedTour =
      localStorage.getItem("tutorialCompleted") === "true";
    if (!hasCompletedTour) {
      setRun(true); // Start the tutorial if not completed
    }
  }, [setRun]);

  const handleJoyrideCallback = (data) => {
    const { status, action, index } = data;

    // Handle tutorial completion or skipping
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      completeTutorial();
    }

    // Handle the last step explicitly
    if (action === "next" && isLastStep) {
      completeTutorial();
    }

    if (action === "next") {
      setStepIndex(index + 1); // Move to the next step
    } else if (action === "prev") {
      setStepIndex(index - 1); // Move to the previous step
    }
  };

  return (
    <Joyride
      steps={steps}
      key={stepIndex}
      run={run}
      stepIndex={stepIndex} // Use manually controlled step index
      continuous
      showSkipButton
      showProgress
      callback={handleJoyrideCallback}
      disableOverlayClose={true}
      styles={{
        options: {
          arrowColor: "#fff",
          backgroundColor: "#333",
          textColor: "#fff",
          primaryColor: "#007bff",
        },
        buttonNext: stepsWithDisabledNext.includes(stepIndex)
          ? { display: "none" } // Hide the "Next" button for specific steps
          : {},
      }}
      locale={{
        skip: "Skip Tutorial", // Customize "Skip" button label
        last: "Finish Tutorial", // Customize "Finish" button label
      }}
    />
  );
};

export default Tutorial;
