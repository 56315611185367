import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaFireAlt, FaSearch, FaTimes, FaChartLine } from "react-icons/fa";
import { useSearch } from "../global-search/SearchContext";
import Skeleton from "react-loading-skeleton";
import { getTrendingTopics } from "./utils";
import { toast } from "sonner";
import AccordionInputContainer from "./TrendingInput";

const Header = styled.h2`
  text-align: center;
  margin-bottom: 15px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  position: relative;
`;

const TrendingList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
`;

const TrendingItem = styled.li`
  padding: 10px 15px;
  margin: 10px 0;
  border-radius: 50px;
  background-color: ${({ theme }) => theme.popup_background};
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;

  &:hover {
    background-color: ${({ theme, $topThree }) =>
      $topThree ? "#d32f2f" : "#1e2836"};
    color: #fff;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-3px);

    svg {
      color: ${({ $topThree }) => ($topThree ? "#fff" : "#ff4500")};
    }
  }

  svg {
    margin-right: 10px;
    color: #ff4500;
  }
`;

const TopicName = styled.span`
  flex: 1;
  font-size: 18px;
  margin-right: 10px;
`;

const SelectedTopic = styled.div`
  position: relative;
  margin: 10px 0;
  text-align: center;
  color: #007bff;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  svg {
    margin-right: 8px;
  }

  strong {
    color: ${({ theme }) => theme.text};
  }
`;

const CancelButton = styled.button`
  background-color: transparent;
  border: none;
  color: #ff4500;
  cursor: pointer;
  transition: color 0.2s;
  margin-left: 10px;
  margin-bottom: 10px;

  &:hover {
    color: #d44;
  }

  svg {
    margin-right: 5px;
    font-size: 16px;
  }
`;

const Counter = styled.span`
  background-color: #007bff;
  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 50%;
  font-size: 12px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 15px;
  min-height: 15px;
`;

const TrendingTab = () => {
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [trendingData, setTrendingData] = useState([]);
  const [location, setLocation] = useState("anywhere");
  const [time, setTime] = useState(10);
  const [language, setLanguage] = useState("arabic");
  const [topic_count] = useState(12);
  const { setSearchState } = useSearch();
  const [loading, setLoading] = useState(true);
  const [displayLanguage, setDisplayLanguage] = useState("arabic");

  useEffect(() => {
    const fetchAndTranslateTopics = async () => {
      try {
        setLoading(true);
        const data = await getTrendingTopics(
          setLoading,
          language,
          location,
          time,
          topic_count,
          displayLanguage
        );

        setTrendingData(data);
      } catch (error) {
        console.error("Error fetching and translating topics:", error);
        toast.error("Failed to fetch trending topics");
      } finally {
        setLoading(false);
      }
    };

    fetchAndTranslateTopics();
  }, [language, location, time, topic_count, displayLanguage]);

  const handleTopicSelect = (topic) => {
    setSelectedTopic(topic);
    setSearchState(topic);
  };

  const handleCancelSearch = () => {
    setSelectedTopic(null);
    setSearchState("");
  };

  return (
    <>
      <Header>Trending Topics</Header>
      <AccordionInputContainer
        language={language}
        setLanguage={setLanguage}
        displayLanguage={displayLanguage}
        setDisplayLanguage={setDisplayLanguage}
        time={time}
        setTime={setTime}
        location={location}
        setLocation={setLocation}
      />
      {selectedTopic && (
        <SelectedTopic>
          <FaSearch />
          Searching for:&nbsp;<strong>{selectedTopic}</strong>
          <CancelButton onClick={handleCancelSearch}>
            <FaTimes />
          </CancelButton>
        </SelectedTopic>
      )}
      <TrendingList>
        {loading ? (
          Array.from({ length: 14 }).map((_, index) => (
            <TrendingItem key={index}>
              <Skeleton
                width={25}
                height={25}
                style={{
                  marginRight: 10,
                }}
              />
              <Skeleton width={80} height={25} />
              <Skeleton
                width={25}
                height={25}
                style={{
                  marginLeft: 10,
                }}
              />
            </TrendingItem>
          ))
        ) : trendingData?.length > 0 ? (
          trendingData.map((topic, index) => (
            <TrendingItem
              key={index}
              onClick={() => handleTopicSelect(topic.name)}
              $topThree={index < 3}
            >
              {index < 3 ? (
                time == 10 ? (
                  <FaChartLine />
                ) : (
                  <FaFireAlt />
                )
              ) : index >= 3 && index < 6 ? (
                <FaFireAlt />
              ) : null}
              <TopicName>{topic.name}</TopicName>
              <Counter>{topic.count}</Counter>
            </TrendingItem>
          ))
        ) : (
          <p style={{ textAlign: "center", color: "#888" }}>
            No trending topics available for the selected criteria
          </p>
        )}
      </TrendingList>
    </>
  );
};

export default TrendingTab;
