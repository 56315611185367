import React, { createContext, useContext, useState } from "react";

// Create a context for the global search state
const SearchContext = createContext();

// Provider component to wrap around the part of the app that needs access to the search state
export const SearchProvider = ({ children }) => {
  const [globalSearch, setGlobalSearch] = useState("");
  const [globalDate, setGlobalDate] = useState("");
  const [globalColumns, setGlobalColumns] = useState([]);
  const [globalIsExactMatch, setGlobalIsExactMatch] = useState(() => {
    const saved = localStorage.getItem("globalExactMatch");
    if (saved) {
      // Parse the saved object
      const savedObject = JSON.parse(saved);
      // Convert keys back to numbers and create Map entries
      const entries = Object.entries(savedObject).map(([key, value]) => [
        Number(key), // Convert string key back to number
        value,
      ]);
      return new Map(entries);
    }
    return new Map();
  });

  const setColumnExactMatch = (columnId, value) => {
    setGlobalIsExactMatch((prev) => {
      const newMap = new Map(prev).set(columnId, value);
      // Save to localStorage
      localStorage.setItem(
        "globalExactMatch",
        JSON.stringify(Object.fromEntries(newMap))
      );
      return newMap;
    });
  };

  // Clear function for when needed
  const clearExactMatchSettings = () => {
    const newMap = new Map();
    // Set all existing keys to true
    globalIsExactMatch.forEach((_, columnId) => {
      newMap.set(columnId, true);
    });

    setGlobalIsExactMatch(newMap);
    localStorage.setItem(
      "globalExactMatch",
      JSON.stringify(Object.fromEntries(newMap))
    );
  };

  return (
    <SearchContext.Provider
      value={{
        globalSearch,
        setGlobalSearch,
        globalDate,
        setGlobalDate,
        globalColumns,
        setGlobalColumns,
        globalIsExactMatch,
        setColumnExactMatch,
        clearExactMatchSettings,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

// Custom hook to use the SearchContext
export const useSearch = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error("useSearch must be used within a SearchProvider");
  }
  return context;
};
