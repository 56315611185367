import React, { createContext, useState, useContext } from "react";

// Create the context
export const TranslationLoadingContext = createContext();

// Create the provider component
export const TranslationLoadingProvider = ({ children }) => {
  const [translationLoading, setTranslationLoading] = useState(false);

  return (
    <TranslationLoadingContext.Provider
      value={{ translationLoading, setTranslationLoading }}
    >
      {children}
    </TranslationLoadingContext.Provider>
  );
};

// Create a custom hook to use the TranslationLoadingContext
export const useTranslationLoading = () => {
  const context = useContext(TranslationLoadingContext);
  if (!context) {
    throw new Error(
      "useTranslationLoading must be used within a TranslationLoadingProvider"
    );
  }
  return context;
};
