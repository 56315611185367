import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ContentRow } from "./ContentRow";
import SettingsButton from "./SettingsButton";
import { toast } from "sonner";
import { requestChannel } from "../contact/ChannelRequestAPI";
import { FaCaretDown, FaPlus } from "react-icons/fa";

const ExpandableSection = styled.div`
  max-height: ${(props) => (props.$isexpanded ? "400px" : "0")};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  opacity: ${(props) => (props.$isexpanded ? "1" : "0")};
  margin: ${(props) => (props.$isexpanded ? "1rem 0" : "0")};
  border-radius: 8px;
  padding: ${(props) => (props.$isexpanded ? "1rem" : "0")};
`;

const RequestForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FormRow = styled.div`
  display: flex;
  gap: 1rem;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.25rem;
`;

const Label = styled.label`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.column_text};
  opacity: 0.8;
  margin-left: 0.25rem;
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid ${({ theme }) => theme.border_color};
  border-radius: 6px;
  background: ${({ theme }) => theme.column_background};
  color: ${({ theme }) => theme.column_text};
  width: 60%;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.blue};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.blue}20;
  }

  &::placeholder {
    color: ${({ theme }) => theme.column_text};
    opacity: 0.5;
  }
`;

const Select = styled.select`
  padding: 0.75rem;
  border: 1px solid ${({ theme }) => theme.border_color};
  border-radius: 6px;
  background: ${({ theme }) => theme.column_background};
  color: ${({ theme }) => theme.column_text};
  width: 60%;
  cursor: pointer;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.blue};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.blue}20;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ActionButton = styled(SettingsButton)`
  flex: 1;
  max-width: 300px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const InfoText = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.column_text};
  opacity: 0.7;
  margin-top: 0.5rem;
  line-height: 1.4;
`;

const ChannelList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 1rem 0;
  max-height: 120px;
  overflow-y: auto;
  padding: 0.25rem;

  /* Customize scrollbar for WebKit browsers */
  &::-webkit-scrollbar {
    width: 8px; /* Adjust the width to make it thinner */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888; /* Customize the thumb color */
    border-radius: 4px; /* Add some rounding to the thumb */
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Customize the track color */
  }

  /* Customize scrollbar for Firefox */
  scrollbar-width: thin; /* Make the scrollbar thinner */
  scrollbar-color: #888 #f1f1f1; /* Customize the thumb and track colors */
`;

const ChannelChip = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.75rem;
  border-radius: 16px;
  background: ${({ theme }) =>
    theme.isDark ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.05)"};
  border: 1px solid ${({ theme }) => theme.border_color};
  font-size: 0.875rem;

  strong {
    color: ${({ theme }) => theme.column_text};
  }

  span.platform {
    color: ${({ theme }) => theme.column_text};
    opacity: 0.7;
    font-size: 0.75rem;
  }
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.column_text};
  opacity: 0.6;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s;
  margin-left: 0.25rem;

  &:hover {
    opacity: 1;
  }

  .material-icons {
    font-size: 16px;
  }
`;

const AddButton = styled(SettingsButton)`
  width: auto;
  padding: 0.5rem 1rem;
`;

const ChannelRequestRow = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [channels, setChannels] = useState([]);
  const [formData, setFormData] = useState({
    username: "",
    type: "telegram",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    console.log("channels", channels);
  }, [channels]);

  const handleAddChannel = (e) => {
    e.preventDefault();
    if (formData.username.trim()) {
      setChannels([
        ...channels,
        {
          username: formData.username.trim(),
          type: formData.type,
        },
      ]);
      // Reset form data
      setFormData({
        username: "",
        type: "telegram",
      });
    }
  };

  const handleRemoveChannel = (index) => {
    setChannels(channels.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (channels.length === 0) {
      toast.error("Please add at least one channel to submit");
      setIsSubmitting(false);
      return;
    }

    try {
      const result = await requestChannel(channels);

      if (result.success) {
        toast.success("Channel requests submitted successfully");
      } else {
        toast.error("Failed to submit channel requests");
      }

      // Clear form and collapse on success
      setChannels([]);
      setFormData({
        username: "",
        type: "telegram",
      });
      setIsExpanded(false);
    } catch (error) {
      console.error("Failed to submit channel requests:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <ContentRow title="Request Extra Channels">
        <SettingsButton
          onClick={() => setIsExpanded(!isExpanded)}
          $backgroundColor={isExpanded ? "transparent" : undefined}
        >
          {isExpanded ? "Cancel" : "Request"}
          <FaCaretDown style={{ marginLeft: "4px" }} />
        </SettingsButton>
      </ContentRow>

      <ExpandableSection $isexpanded={isExpanded}>
        <RequestForm onSubmit={handleAddChannel}>
          <FormRow>
            <InputWrapper>
              <Label htmlFor="channel-username">Channel Username</Label>
              <Input
                id="channel-username"
                type="text"
                value={formData.username}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    username: e.target.value,
                  }))
                }
                placeholder="e.g., @channelname"
                required
              />
            </InputWrapper>

            <InputWrapper>
              <Label htmlFor="channel-type">Platform</Label>
              <Select
                id="channel-type"
                value={formData.type}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    type: e.target.value,
                  }))
                }
              >
                <option value="telegram">Telegram</option>
                <option value="twitter">Twitter</option>
              </Select>
            </InputWrapper>

            <AddButton
              type="submit"
              disabled={!formData.username.trim()}
              style={{ marginTop: "1.5rem" }}
            >
              {`${channels.length === 0 ? "Add" : "Add More"}`}
              <FaPlus style={{ marginLeft: "8px" }} />
            </AddButton>
          </FormRow>
        </RequestForm>

        <>
          <ChannelList>
            {channels.map((channel, index) => (
              <ChannelChip key={index}>
                <strong>{channel.username}</strong>
                <span className="platform">{channel.type}</span>
                <RemoveButton
                  onClick={() => handleRemoveChannel(index)}
                  title="Remove channel"
                >
                  <span className="material-icons">close</span>
                </RemoveButton>
              </ChannelChip>
            ))}
          </ChannelList>

          <InfoText>
            Please ensure all channels exist and are accessible. We'll review
            your requests and add the channels that meet our guidelines.
          </InfoText>

          <ButtonGroup>
            <ActionButton
              onClick={handleSubmit}
              disabled={isSubmitting || channels.length === 0}
              $backgroundColor={!isSubmitting ? "#4CAF50" : undefined}
              $color={!isSubmitting ? "white" : undefined}
            >
              {isSubmitting
                ? "Submitting..."
                : `${channels.length} Channel${
                    channels.length > 1 || channels.length === 0 ? "s" : ""
                  } Added`}
            </ActionButton>
          </ButtonGroup>
        </>
      </ExpandableSection>
    </>
  );
};

export default ChannelRequestRow;
