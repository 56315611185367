import React from "react";
import MainMapComponent from "./map_components/MainMapComponent";
import { FilterProvider } from "./contexts/FilterContext";
import { MapProvider } from "./contexts/MapContext";
import SidePanelButton from "./side-panel/SideButton";
import NavBar from "./navbar/NavBar";
import { LocationsProvider } from "./contexts/LocationsContext";
import { ClickedPostProvider } from "./contexts/ClickedPostContext";
import { MapEventSourceProvider } from "./contexts/MapEventSourceProvider";

const Map = () => {
  return (
    <FilterProvider>
      <MapEventSourceProvider>
        <MapProvider>
          <LocationsProvider>
            <ClickedPostProvider>
              <MainMapComponent />
              <NavBar />
              <SidePanelButton />
            </ClickedPostProvider>
          </LocationsProvider>
        </MapProvider>
      </MapEventSourceProvider>
    </FilterProvider>
  );
};
export default Map;
