import AreaCards from "../components/charts/business-charts/AreaCards";
const Dashboard = () => {
  return (
    <div className="content-area">
      <AreaCards />
      {/* <AreaCharts /> */}
    </div>
  );
};

export default Dashboard;
