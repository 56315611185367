import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Select from "react-select";
import { DatePicker } from "../../../../../global/styled-components/DatePicker";
import { useTheme } from "../../../../themes/ThemeContext";
import { useFilter } from "../../../contexts/FilterContext";
import { groupCategories } from "./FilterTab_utils";
import { TextField } from "@mui/material";
import { useLocations } from "../../../contexts/LocationsContext";

export const StyledSelect = styled(Select)`
  font-size: 14px;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 20px;
`;

const SectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const SectionTitle = styled.h4`
  color: ${({ theme }) => theme.text};
  margin-bottom: 20px;
  margin-top: 10px;
  width: 100%;
  text-align: left;
  font-size: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const ApplyButton = styled.button`
  background-color: #1e2836;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 40%;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }s
`;

const ResetButton = styled.button`
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 40%;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  max-width: 100%;
`;

const StyledLabel = styled.label`
  font-size: 1em;
`;

const CheckboxContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

const FiltersSidebar = () => {
  const { theme } = useTheme();
  const {
    setSearch,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    setCategories,
    dbCategories,
    hasImages,
    setHasImages,
    hasVideos,
    setHasVideos,
    hasText,
    setHasText,
  } = useFilter();

  const { mapDataLoading } = useLocations();

  const [localStartDate, setLocalStartDate] = useState(startDate);
  const [localEndDate, setLocalEndDate] = useState(endDate);
  const [localCategories, setLocalCategories] = useState([]);
  const [localSearch, setLocalSearch] = useState("");
  const [filterImages, setFilterImages] = useState(hasImages);
  const [filterVideos, setFilterVideos] = useState(hasVideos);
  const [filterText, setFilterText] = useState(hasText);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      const groupedOptions = groupCategories(dbCategories);
      setOptions(groupedOptions);
    };
    fetchCategories();
  }, [dbCategories]);

  const applyFilters = () => {
    setStartDate(localStartDate);
    setEndDate(localEndDate);
    // Only pass an array of values (category IDs) to setCategories
    setCategories(localCategories.map((category) => category.value));
    setSearch(localSearch);
    setHasImages(filterImages);
    setHasVideos(filterVideos);
    setHasText(filterText);
  };

  const resetFilters = () => {
    setLocalStartDate(null);
    setLocalEndDate(null);
    setLocalCategories([]);
    setLocalSearch("");
    setStartDate(null);
    setEndDate(null);
    setCategories([]);
    setSearch("");
    setHasText(true);
    setHasImages(true);
    setHasVideos(true);
  };

  const hasFiltersApplied = () => {
    return (
      localStartDate ||
      localEndDate ||
      localCategories.length > 0 ||
      localSearch
    );
  };

  return (
    <>
      <h2 style={{ textAlign: "center" }}>Map Filters</h2>
      <SectionContainer>
        {/* Search Term */}
        <InputContainer>
          <TextField
            label="Search Term"
            placeholder="Enter search term..."
            variant="outlined"
            fullWidth
            value={localSearch}
            onChange={(e) => setLocalSearch(e.target.value)}
            size="small"
            sx={{
              backgroundColor: theme === "dark" ? "#333" : "#F8F8F8",
            }}
            slotProps={{
              input: {
                style: {
                  color: theme === "dark" ? "#fff" : "#000",
                },
              },
            }}
          />
        </InputContainer>
        <StyledSelect
          isMulti
          className="toggle-button"
          value={localCategories}
          onChange={setLocalCategories}
          options={options}
          placeholder="Select Categories"
          // formatGroupLabel={formatGroupLabel}
          styles={{
            control: (base, state) => ({
              ...base,
              fontSize: "16px",
              backgroundColor: theme === "dark" ? "#333" : "#F8F8F8",
            }),
            menu: (base) => ({
              ...base,
              backgroundColor: theme === "dark" ? "#333" : "#fff",
            }),
            singleValue: (base) => ({
              ...base,
              color: theme === "dark" ? "#fff" : "#000",
            }),
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isFocused
                ? theme === "dark"
                  ? "#555"
                  : "#ddd"
                : theme === "dark"
                ? "#333"
                : "#fff",
              color: state.isFocused
                ? theme === "dark"
                  ? "#fff"
                  : "#000"
                : theme === "dark"
                ? "#fff"
                : "#000",
              cursor: "pointer",
            }),
            input: (base) => ({
              ...base,
              color: theme.text,
            }),
            groupHeading: (base) => ({
              ...base,
              color: theme.text,
            }),
          }}
        />
        <DateContainer>
          <InputContainer>
            <StyledLabel>Start Date</StyledLabel>
            <DatePicker
              type="datetime-local"
              className="toggle-button"
              $theme={theme}
              value={localStartDate !== null ? localStartDate : ""}
              onChange={(e) => setLocalStartDate(e.target.value)}
              placeholder="Select Start Date"
            />
          </InputContainer>
          <InputContainer>
            <StyledLabel>End Date</StyledLabel>
            <DatePicker
              type="datetime-local"
              className="toggle-button"
              $theme={theme}
              value={localEndDate !== null ? localEndDate : ""}
              onChange={(e) => setLocalEndDate(e.target.value)}
              placeholder="Select End Date"
            />
          </InputContainer>
        </DateContainer>
        {/* Media Type Filters */}
        <CheckboxContainer>
          <StyledLabel>
            <input
              type="checkbox"
              checked={filterImages}
              onChange={() => setFilterImages((prev) => !prev)}
            />
            Images
          </StyledLabel>
          <StyledLabel>
            <input
              type="checkbox"
              checked={filterVideos}
              onChange={() => setFilterVideos((prev) => !prev)}
            />
            Videos
          </StyledLabel>
          <StyledLabel>
            <input
              type="checkbox"
              checked={filterText}
              onChange={() => setFilterText((prev) => !prev)}
            />
            Text
          </StyledLabel>
        </CheckboxContainer>
      </SectionContainer>
      <ButtonContainer>
        <ApplyButton onClick={applyFilters} disabled={mapDataLoading}>
          Apply Filters
        </ApplyButton>
        {hasFiltersApplied() && (
          <ResetButton onClick={resetFilters} disabled={mapDataLoading}>
            Reset Filters
          </ResetButton>
        )}
      </ButtonContainer>
    </>
  );
};

export default FiltersSidebar;
