import { useEffect, useState } from "react";
import { getAuth, website } from "../../../../global/globalUtils";

const AreaProgressChart = () => {
  const [serviceData, setServiceData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [maxCount, setMaxCount] = useState(0); // To store the maximum count

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        // Fetch data from the API
        const response = await getAuth("/dashboard/posts-per-category");
        const data = await response.json();

        if (data.status === "success") {
          // Find the maximum count across all categories
          const max = Math.max(...data.data.map((category) => category.count));
          setMaxCount(max); // Set the max count for scaling

          setServiceData(data.data); // Set the data to the fetched categories
        }
      } catch (error) {
        console.error("Error fetching category data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategoryData();
  }, []);

  return (
    <div className="progress-bar">
      <div className="progress-bar-info">
        <h4 className="progress-bar-title">
          Number of Posts by Category
        </h4>
      </div>
      {isLoading ? (
        <p>Loading service data...</p>
      ) : (
        <div className="progress-bar-list">
          {serviceData.map((category) => (
            <div key={category.id} className="progress-bar-item">
              <div className="bar-item-info">
                <img
                  src={`${website}/${category.icon}`}
                  alt={`${category.category} icon`}
                  style={{ width: "40px", height: "40px", marginRight: "5px" }}
                />
                <p className="bar-item-info-name">{category.category}</p>
                <p className="bar-item-info-value">{category.count} posts</p>
              </div>
              <div className="bar-item-full">
                <div
                  className="bar-item-filled"
                  style={{
                    width: `${(category.count / maxCount) * 100}%`, // Adjust based on max count
                  }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AreaProgressChart;
