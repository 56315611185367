import { getAuth } from "../../../../global/globalUtils";
import AreaCard from "../areaCards/AreaCard";
import "../areaCards/AreaCards.scss";
import { useEffect, useState } from "react";

const AreaCards = () => {
  const [userCount, setUserCount] = useState({
    totalUsers: 0,
    unverifiedUsers: 0,
    verifiedUsers: 0,
  });
  const [channelRequests, setChannelRequests] = useState(0);

  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const response = await getAuth("/dashboard/user-count");
        const data = await response.json();

        if (data.status === "success") {
          setUserCount({
            totalUsers: data.data.totalUsers,
            unverifiedUsers: data.data.unverifiedUsers,
            verifiedUsers: data.data.verifiedUsers,
          });
        }
      } catch (error) {
        console.error("Error fetching user count:", error);
      }
    };

    const fetchChannelRequests = async () => {
      try {
        const response = await getAuth("/dashboard/channel-request-count");
        const data = await response.json();

        if (data.status === "success") {
          setChannelRequests(data.data);
        }
      } catch (error) {
        console.error("Error fetching channel requests:", error);
      }
    };

    fetchUserCount();
    fetchChannelRequests();
  }, []);

  return (
    <section className="content-area-cards">
      <AreaCard
        colors={["#e4e8ef", "#f29a2e"]}
        percentFillValue={65}
        cardInfo={{
          title: "Users",
          value: userCount.totalUsers,
          text: "Total number of users.",
        }}
      />
      <AreaCard
        colors={["#e4e8ef", "#475be8"]}
        percentFillValue={80}
        cardInfo={{
          title: "Unverified Users",
          value: userCount.unverifiedUsers,
          text: "Total number of unverified users.",
        }}
      />
      <AreaCard
        colors={["#e4e8ef", "#4ce13f"]}
        percentFillValue={70}
        cardInfo={{
          title: "Channel Requests",
          value: channelRequests,
          text: "Total number of channel requests.",
        }}
      />
    </section>
  );
};

export default AreaCards;
