import { toast } from "sonner";
import { postAuth } from "../../../../global/globalUtils";

export const sanitizeFilename = (name) => {
  // Remove invalid file name characters regex
  return name.replace(/[/\\?%*:|"<>]/g, "-");
};

export const isDateRangeValid = (from, to, hoursLimit) => {
  const limitInMilliseconds = hoursLimit * 60 * 60 * 1000;
  const diffTime = Math.abs(to - from);
  const isValid = diffTime <= limitInMilliseconds;
  const message = `Date range must be within ${hoursLimit} hours.`;
  return { isValid, message };
};

export const handleDownloadCSV = async ({
  From,
  To,
  searchInput,
  selectedLanguage,
  setCSVLoading,
}) => {
  try {
    setCSVLoading(true);
    if (From === "" || To === "") {
      toast.error("Please fill all fields");
      return;
    }

    const from = new Date(From);
    const to = new Date(To);
    if (from >= to) {
      toast.error("From date should be before To date");
      return;
    }

    const { isValid, message } = isDateRangeValid(
      from,
      to,
      process.env.REACT_APP_POST_EXPORT_TIME_LIMIT
    );

    if (!isValid) {
      toast.error(message);
      return;
    }

    const response = await postAuth("/post/download-csv", {
      from: from.toISOString(),
      to: to.toISOString(),
      searchString: searchInput,
      Language: selectedLanguage.value,
    });

    if (response.ok) {
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;

      const filename = searchInput
        ? `${sanitizeFilename(searchInput)}_posts.csv`
        : "filtered_posts.csv";
      a.download = filename;

      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(downloadUrl);
      toast.success("File has been downloaded successfully.");
    } else {
      const errorData = await response.json();
      toast.error(errorData.message || "Failed to download file.");
    }
  } catch (error) {
    toast.error("An unexpected error occurred. Please try again.");
    console.error("Error during handleDownloadCSV:", error);
  } finally {
    setCSVLoading(false);
  }
};

export const handleDownload = async ({
  From,
  To,
  searchInput,
  mediaSelection,
  setMediaLoading,
}) => {
  try {
    setMediaLoading(true);

    if (From === "" || To === "") {
      toast.error("Please fill all fields");
      return;
    }

    const from = new Date(From);
    const to = new Date(To);
    if (from >= to) {
      toast.error("From date should be before To date");
      return;
    }

    const { isValid, message } = isDateRangeValid(
      from,
      to,
      process.env.REACT_APP_POST_DOWNLOAD_TIME_LIMIT
    );

    if (!isValid) {
      toast.error(message);
      return;
    }

    if (!mediaSelection.images && !mediaSelection.videos) {
      toast.error("Please select at least one type of media");
      return;
    }

    // Send to backend
    const response = await postAuth("/post/download-column-media", {
      searchString: searchInput,
      mediaSelection,
      from: from.toISOString(),
      to: to.toISOString(),
    });

    if (response.ok) {
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.href = downloadUrl;
      a.download = "media.zip";

      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(downloadUrl);

      toast.success("Media has been downloaded successfully.");
    } else {
      const errorData = await response.json();
      toast.error(errorData.message || "Failed to download media.");
    }
  } catch (error) {
    toast.error("An unexpected error occurred.");
    console.error("Error during handleDownload:", error);
  } finally {
    setMediaLoading(false);
  }
};
