import React from "react";
import styled, { keyframes } from "styled-components";
import {
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  TwitterIcon,
  WhatsappIcon,
  TelegramIcon,
} from "react-share";
import { SiMicrosoftword } from "react-icons/si";
import { useTheme } from "../../themes/ThemeContext";

// Keyframes for the opening animation
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Keyframes for the closing animation
const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

// Styled components for the tooltip and its elements
const TooltipContainer = styled.div`
  position: absolute;
  top: 140%; /* Position below the parent element */
  left: -100%; /* Align to the left of the parent element */
  background-color: ${({ theme }) =>
    theme === "light" ? "#f0f0f0" : "#222f3e"};
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 10000;
  animation: ${({ $isOpen }) => ($isOpen ? fadeIn : fadeOut)} 0.3s ease forwards;
  display: ${({ $isOpen }) => ($isOpen ? "block" : "none")};
`;

const Tip = styled.div`
  position: absolute;
  bottom: 100%; /* Position above the tooltip */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid
    ${({ theme }) => (theme === "light" ? "#f0f0f0" : "#222f3e")}; /* Color of the iceberg tip */
`;

const ShareButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const WordIcon = styled(SiMicrosoftword)`
  font-size: 30px;
  color: #2b579a;
  cursor: pointer;
`;

const SharePopup = ({ isOpen, text }) => {
  const { theme } = useTheme();
  const exportToWord = () => {
    const header = `
    <html xmlns:o='urn:schemas-microsoft-com:office:office'
          xmlns:w='urn:schemas-microsoft-com:office:word'
          xmlns='http://www.w3.org/TR/REC-html40'>
      <head><meta charset='utf-8'><title>Export HTML To Word Document</title></head>
      <body style="line-height: 1.5;">
  `;

    const footer = `</body></html>`;

    // Format the text input:
    // - Ensure every new line becomes a new <p>
    // - Add <br> for extra line spacing between elements
    const formattedTextInput = text
      .replace(/\n/g, "<p></p>") // Replace newlines with empty paragraphs
      .replace(/<hr\s*\/?>/g, "<br><br>") // Ensure horizontal rules get extra spacing
      .replace(/<br\s*\/?>/g, "<br><br>"); // Ensure <br> has double line breaks in Word

    const sourceHTML = header + formattedTextInput + footer;

    const source =
      "data:application/vnd.ms-word;charset=utf-8," +
      encodeURIComponent(sourceHTML);

    const fileDownload = document.createElement("a");
    document.body.appendChild(fileDownload);
    fileDownload.href = source;
    fileDownload.download = "Teledeck.doc";
    fileDownload.click();
    document.body.removeChild(fileDownload);
  };

  return (
    <TooltipContainer $isOpen={isOpen} theme={theme}>
      <Tip theme={theme} />
      <ShareButtonsContainer>
        <TwitterShareButton url={" "} title={text} hashtag={`#teledeck`}>
          <TwitterIcon size={30} round />
        </TwitterShareButton>
        <WhatsappShareButton url={" "} title={text} separator="">
          <WhatsappIcon size={30} round />
        </WhatsappShareButton>
        <TelegramShareButton url={" "} title={text}>
          <TelegramIcon size={30} round />
        </TelegramShareButton>
        <WordIcon onClick={exportToWord} />
      </ShareButtonsContainer>
    </TooltipContainer>
  );
};

export default SharePopup;
