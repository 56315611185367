import React, { useState, useEffect, useRef } from "react";
import { ContentRow } from "./components/ContentRow";
import { useColumns } from "../../column/ColumnsContext";
import SettingsButton from "./components/SettingsButton";
import styled from "styled-components";
import { FaCaretDown, FaArrowUp, FaTimes } from "react-icons/fa";
import { useTheme } from "../../themes/ThemeContext";
import { useNavigate } from "react-router-dom";
import CancellationPopup from "./components/CancellationPopup";
import ChannelRequestRow from "./components/ChannelRequest";

const DropdownMenu = styled.div`
  position: absolute;
  background-color: ${({ theme }) =>
    theme === "dark" ? "#333" : "#fff"}; /* Background color based on theme */
  border: 1px solid ${({ theme }) => (theme === "dark" ? "#ffffff" : "#1e2836")}; /* Border color based on theme */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 8px;
  z-index: 1000;
  display: ${({ show }) =>
    show ? "block" : "none"}; /* Show or hide based on state */
  top: 100%; /* Position the dropdown below the button */
  left: -50%; /* Align the dropdown with the left edge of the button */
  min-width: 100px; /* Set a minimum width */
`;

const SettingsButtonContainer = styled.div`
  position: relative; /* Make the button container the positioning context */
  display: inline-block; /* Ensure it wraps around its content */
`;

const DropdownItem = styled.div`
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
  color: ${({ theme }) =>
    theme === "dark" ? "#fff" : "#1e2836"}; /* Text color based on theme */

  &:hover {
    background-color: ${({ theme }) =>
      theme === "dark"
        ? "#444"
        : "#f0f0f0"}; /* Hover background color based on theme */
  }
`;

const General = () => {
  const { resetColumnProperties } = useColumns();
  const { theme } = useTheme();
  const navigate = useNavigate();

  const [showDropdown, setShowDropdown] = useState(false);
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  const handleReset = async () => {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith("inputValue-")) {
        localStorage.removeItem(key);
      }
    });

    await resetColumnProperties();
    window.location.reload();
  };

  useEffect(() => {
    const handleClickOutsideDropdown = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutsideDropdown);
    } else {
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, [showDropdown]);

  return (
    <>
      <ContentRow title="Subscription">
        <SettingsButtonContainer ref={dropdownRef}>
          <SettingsButton
            onClick={toggleDropdown}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            Manage
            <FaCaretDown style={{ marginLeft: "4px" }} />
          </SettingsButton>
          <DropdownMenu theme={theme} show={showDropdown}>
            <DropdownItem theme={theme} onClick={() => navigate("/bundles")}>
              <FaArrowUp />
              Upgrade Plan
            </DropdownItem>
            <DropdownItem
              theme={theme}
              onClick={() => setShowCancelPopup(true)}
            >
              <FaTimes /> Cancel Plan
            </DropdownItem>
          </DropdownMenu>
        </SettingsButtonContainer>
      </ContentRow>

      <ChannelRequestRow />

      <ContentRow title="Reset All Column Filters">
        <SettingsButton
          $backgroundColor="#f44336"
          $hoverBackgroundColor="#d32f2f"
          $hoverColor="white"
          $color="white"
          $borderColor="transparent"
          onClick={handleReset}
        >
          Reset
        </SettingsButton>
      </ContentRow>

      {showCancelPopup && (
        <CancellationPopup
          theme={theme}
          title="Cancel Subscription"
          onClose={() => setShowCancelPopup(false)}
          onCancel={() => setShowCancelPopup(false)}
          onConfirm={() => {
            setShowCancelPopup(false);
          }}
        />
      )}
    </>
  );
};

export default General;
