export const lightTheme = {
  body: "#F0F0F0",
  text: "#363537",
  toggleBorder: "#FFF",

  main: "#FFFFFF",

  background: "#EBEBEB",

  // popup_background: '#EBEBEB',
  popup_background: "#F8F8F8",

  background_preview: "#efefef",

  filter_background: "#f4f4f4",

  post_hover_background: "#dbdbdb",

  button_background: "#e0e0e0",
  map_button_background: "#9e9393",

  button_text: "#01204e",
  bookmark_button: "#87a5e4",

  border_color: "#D3D3D3",
  column_background: "#F8F8F8",
  column_text: "#000000",

  play_color: "rgba(245,245,245,0.9)",
  play_overlay_color: "rgba(0,0,0,0.6)",

  nav_background: "#1E2836",
  nav_hover: "#12171f",

  play_background: "#1E2836",

  // filter_background:'#F5F5F5',

  blue: "#2481CC",

  chip_active: "#60a8eb",
  chip_inactive: "#969696;",

  delete_chip: "#bfbfbf",
  hover_background: "#d4d4d4",
  arrow_color: "#363537",

  popup_shadow: "#939393",

  cancel_hover: "#b3b3b3",

  done_button: "#4caf50",
  done_hover: "#008409",
};

export const darkTheme = {
  body: "#121212",
  text: "#FAFAFA",
  toggleBorder: "#6B8096",

  main: "#383838",

  background: "#212121",
  popup_background: "#383838",

  background_preview: "#4e4e4e",

  filter_background: "#323436",

  post_hover_background: "#323436",

  button_background: "#454545",
  map_button_background: "#454545",
  button_text: "#FAFAFA",
  bookmark_button: "#6985C0",

  border_color: "#F5F5F5",
  column_background: "#2d2d2d",
  column_text: "#e3e3e3",

  play_color: "rgba(245,245,245,0.9)",
  play_overlay_color: "rgba(0,0,0,0.5)",

  nav_background: "#171717",
  nav_hover: "#404040",

  play_background: "#FAFAFA",

  // filter_background:'#363636',

  blue: "#87C9FF",

  chip_active: "#0d4f8c",
  chip_inactive: "#686868",

  delete_chip: "#7c7c7c",
  hover_background: "#4e4e4e",
  arrow_color: "#F5F5F5",

  popup_shadow: "#303030",

  cancel_hover: "#707070",

  done_button: "#157e18",
  done_hover: "#006b07",
};
