import React, { useState, useEffect, useCallback } from "react";
import styled, { useTheme } from "styled-components";
import Select from "react-select";
import debounce from "lodash/debounce";
import Swal from "sweetalert2";
import { updateColumnInDB } from "../localdb/DBColumn";
import { fetchIcons } from "./utils/iconSearchUtil";
import {
  updateColumnsToAPI,
  deleteColumnFromAPI,
} from "../column/column-utils/ColumnUtils";
import SecondColumn from "./popup_internal_columns/SecondColumn";
import ThirdColumn from "./popup_internal_columns/ThirdColumn";
import { useColumns } from "../column/ColumnsContext";
import Notifications from "./popup_components/NotificationSelector";
import { fadeIn } from "../settings/Settings";
import { toast } from "sonner";
import { CloseButton } from "./AddColumnPopup";

const PopupBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContainer = styled.div`
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  overflow: hidden;
  animation: ${fadeIn} 0.3s ease-out;
  @media (max-width: 769px) {
    max-height: 80vh;
    overflow-y: auto;
  }
`;

const SeperatedButtons = styled.div`
  display: flex;
  justify-content: end;
  gap: 16px;
  padding-bottom: 16px;
`;

const HeaderElements = styled.div`
  width: 320px;
  border-right: 1px solid #ccc;
  padding: 0px 20px;
  @media (max-width: 1200px) {
    width: 685px;
    border-bottom: 1px solid #ccc;
    border-right: none;
    display: flex;

    justify-items: flex-start;
    gap: 24px;
  }
  @media (max-width: 769px) {
    width: 320px;
    flex-direction: column;
  }
`;

const SecondColumnContainer = styled.div`
  width: 320px;
  border-right: 1px solid #ccc;
  padding: 0px 20px 20px 20px;
`;

const ThirdColumnContainer = styled.div`
  width: 320px;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PopupHeader = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  padding: 0 16px;
`;

const HeaderGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  background-color: #1e2836;
  color: white;
`;

const ThemedInput = styled.input`
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  border: 1px solid ${({ theme }) => theme.border_color};
  margin-bottom: 32px;
  width: 90%;
  padding: 10px;
  border-radius: 5px;
  outline: 1px solid #dadada;
  border: none;
  box-sizing: border-box;
  transition: outline 0.2s linear;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  &:focus {
    outline: 1px solid #9e9e9e;
    border: none;
    box-shadow: none;
  }
  @media (max-width: 1200px) {
    padding: 16px;
  }
`;

const ThemedButton = styled.button`
  border: none;
  padding: 12px 36px;
  border-radius: 16px;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  background-color: ${({ theme }) => theme.chip_inactive};
  color: ${({ theme }) => theme.text};
  transition: ease 0.2s;
  &:hover {
    background-color: ${({ theme }) => theme.cancel_hover};
  }
`;

const DeleteButton = styled.button`
  font-weight: bold;
  background-color: red;
  color: white;
  border: none;
  padding: 12px 16px;
  border-radius: 16px;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const ColumnsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
      "header header"
      "second third";

    & > *:nth-child(1) {
      grid-area: header;
    }

    & > *:nth-child(2) {
      grid-area: second;
    }

    & > *:nth-child(3) {
      grid-area: third;
    }
  }

  @media (max-width: 769px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "header"
      "second"
      "third";

    & > *:nth-child(1) {
      grid-area: header;
    }

    & > *:nth-child(2) {
      grid-area: second;
    }

    & > *:nth-child(3) {
      grid-area: third;
    }
  }
`;

const IconSelect = ({
  options,
  loading,
  selectedIcon,
  onSelectIcon,
  onSearch,
}) => {
  const theme = useTheme();

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
      width: 140,
      marginBottom: 16,
      backgroundColor: theme.popup_background,
      color: theme.text,
      [`@media (max-width: 1200px)`]: {
        marginBottom: 0,
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: 4,
    }),
    clearIndicator: (base) => ({
      ...base,
      padding: 4,
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0 6px",
      color: theme.text,
    }),
    singleValue: (base) => ({
      ...base,
      color: theme.text,
    }),
    input: (base) => ({
      ...base,
      margin: 0,
      padding: 0,
      color: theme.text,
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: 50,
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: theme.popup_background,
      color: theme.text,
      marginTop: 0,
      width: "160px",
    }),
    menuList: (base) => ({
      ...base,
      backgroundColor: theme.popup_background,
      color: theme.text,
    }),
    option: (base, { isFocused, isSelected }) => ({
      ...base,
      backgroundColor: isSelected
        ? theme.chip_active
        : isFocused
        ? theme.chip_inactive
        : theme.popup_background,
      color: theme.text,
      ":active": {
        backgroundColor: isSelected ? theme.chip_active : theme.chip_inactive,
      },
    }),
  };

  return (
    <Select
      options={
        loading ? [] : options.filter((option) => option.value !== selectedIcon)
      }
      getOptionLabel={(e) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span className="material-icons" style={{ marginRight: 10 }}>
            {e.label}
          </span>
          {e.label}
        </div>
      )}
      getOptionValue={(e) => e.value}
      styles={customStyles}
      onInputChange={(inputValue) => onSearch(inputValue)}
      noOptionsMessage={() =>
        loading ? (
          <div className="loading-message">
            <div className="spinner"></div> Loading...
          </div>
        ) : (
          "No options"
        )
      }
      onChange={(selectedOption) => onSelectIcon(selectedOption.value)}
      value={
        options.find((option) => option.value === selectedIcon) || {
          value: selectedIcon,
          label: selectedIcon,
        }
      }
    />
  );
};

const EditPopup = ({ column, onClose, onSave }) => {
  const [columnName, setColumnName] = useState(column.name);
  const [selectedIcon, setSelectedIcon] = useState(column.icon);
  const [iconOptions, setIconOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [availableChannels, setAvailableChannels] = useState([]);
  const [chosenChannels, setChosenChannels] = useState(column.channels || []);
  const { removeColumn, updateColumn } = useColumns();
  const [notificationEnabled, setNotificationEnabled] = useState(
    column.notifications ? true : false
  );
  const [notificationSound, setNotificationSound] = useState(
    column.isLoudSound
  );

  const handleNotificationChange = (event) => {
    setNotificationEnabled(event.target.value === "On");
  };

  const handleNotificationSoundChange = (event) => {
    setNotificationSound(event.target.value);
  };

  useEffect(() => {
    const loadIcons = async (query = "") => {
      setLoading(true);
      const icons = await fetchIcons(query);
      setIconOptions(icons);
      setLoading(false);
    };

    loadIcons();
  }, []);

  const debouncedFetchIcons = useCallback(
    debounce(async (query) => {
      setLoading(true);
      const icons = await fetchIcons(query);
      setIconOptions(icons);
      setLoading(false);
    }, 300),
    []
  );

  const handleSearch = (query) => {
    setLoading(true);
    debouncedFetchIcons(query);
  };

  const handleSave = async () => {
    if (!columnName.trim()) {
      toast.error("Column name cannot be empty");
      return;
    }

    if (chosenChannels.length === 0) {
      toast.error("You must select at least one channel");
      return;
    }

    const updatedColumn = {
      ...column,
      name: columnName,
      icon: selectedIcon,
      notifications: notificationEnabled ? 1 : 0,
      isLoudSound: notificationSound,
      channels: chosenChannels,
    };

    try {
      await updateColumnInDB(column.id, updatedColumn);
      const newChannels = chosenChannels.map((channel) => channel.id);
      const updatedColumnForAPI = {
        ...column,
        name: columnName,
        icon: selectedIcon,
        notifications: notificationEnabled ? 1 : 0,
        isLoudSound: notificationSound,
        channels: newChannels,
      };

      await updateColumnsToAPI(updatedColumnForAPI);
      await updateColumn(updatedColumn);
      onSave(updatedColumn);
      onClose();
    } catch (error) {
      toast.error("Failed to save the column. Please try again.");
    }
  };

  const handleDelete = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this column!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteColumnFromAPI(column.id);
          removeColumn(column.id); // Update local state to remove the column
          toast.success("The column has been deleted.");

          onClose();
        } catch (error) {
          toast.error("Failed to delete the column.");
        }
      }
    });
  };

  const handleAddChannel = (channel) => {
    setAvailableChannels((prev) => prev.filter((ch) => ch.id !== channel.id));
    setChosenChannels((prev) => [...prev, channel]);
  };

  const handleRemoveChannel = (channel) => {
    setChosenChannels((prev) => prev.filter((ch) => ch.id !== channel.id));
    setAvailableChannels((prev) => [channel, ...prev]);
  };

  return (
    <PopupBackground>
      <PopupContainer>
        <HeaderGroup>
          <PopupHeader>Edit Column</PopupHeader>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </HeaderGroup>
        <ColumnsContainer>
          <HeaderElements className="afj-notification-container">
            <div>
              <label
                style={{
                  display: "block",
                  marginBottom: "16px",
                  marginTop: "16px",
                  fontWeight: "600",
                  fontSize: "16px",
                }}
              >
                Column Name
              </label>
              <ThemedInput
                type="text"
                placeholder="Column Name"
                value={columnName}
                onChange={(e) => setColumnName(e.target.value)}
                required
              />
            </div>
            <div>
              <label className="afj-edit-column-notification">Icon</label>
              <IconSelect
                options={iconOptions}
                onSearch={handleSearch}
                loading={loading}
                onSelectIcon={setSelectedIcon}
                selectedIcon={selectedIcon}
              />
            </div>
            <Notifications
              onNotificationChange={handleNotificationChange}
              onNotificationSoundChange={handleNotificationSoundChange}
              column={column}
            />
          </HeaderElements>

          <SecondColumnContainer>
            <SecondColumn
              channels={availableChannels}
              onAddChannel={handleAddChannel}
              chosenChannels={chosenChannels}
              setChannels={setAvailableChannels}
              deactivateRegularColumn={true}
            />
          </SecondColumnContainer>
          <ThirdColumnContainer>
            <ThirdColumn
              onClose={onClose}
              chosenChannels={chosenChannels}
              onRemoveChannel={handleRemoveChannel}
              selectedIcon={selectedIcon}
              hideButtons={true}
              deactivateRegularColumn={true}
              notificationEnabled={notificationEnabled}
              notificationSound={notificationSound}
            />
            <SeperatedButtons>
              <DeleteButton onClick={handleDelete}>Delete Column</DeleteButton>
              <ThemedButton onClick={handleSave}>Save</ThemedButton>
            </SeperatedButtons>
          </ThirdColumnContainer>
        </ColumnsContainer>
      </PopupContainer>
    </PopupBackground>
  );
};

export default EditPopup;
