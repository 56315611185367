import { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { FaArrowUpLong } from "react-icons/fa6";
import "./AreaCharts.scss";
import { getAuth } from "../../../../global/globalUtils";
import Spinner from "../../../../global/loaders/Spinner";

const formatNumber = (num) => {
  if (num === undefined || num === null) return "0";
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const calculateMaxDomain = (data) => {
  const maxValue = Math.max(
    ...data.map((item) =>
      Math.max(item.telegram || 0, item.twitter || 0, item.total || 0)
    )
  );
  return Math.ceil(maxValue / 50000) * 50000;
};

const AreaBarChart = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [statistics, setStatistics] = useState({});
  const [valueChange, setValueChange] = useState("");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [years] = useState([
    new Date().getFullYear() - 2,
    new Date().getFullYear() - 1,
    new Date().getFullYear(),
  ]);
  const [sources] = useState(["telegram", "twitter"]);
  const [selectedSource, setSelectedSource] = useState("");

  useEffect(() => {
    const fetchChartData = async () => {
      setIsLoading(true);
      try {
        const response = await getAuth(
          `/dashboard/monthly-post-count?selectedYear=${selectedYear}&source=${selectedSource}`
        );
        const data = await response.json();

        if (data.status === "success") {
          setChartData(data.data.monthlyData);
          setStatistics(data.data.statistics);

          // Calculate value change based on total posts
          if (data.data.monthlyData.length > 1) {
            const lastMonth =
              data.data.monthlyData[data.data.monthlyData.length - 1].total;
            const previousMonth =
              data.data.monthlyData[data.data.monthlyData.length - 2].total;
            const change =
              previousMonth !== 0
                ? ((lastMonth - previousMonth) / previousMonth) * 100
                : lastMonth > 0
                ? 100
                : 0;
            setValueChange(change.toFixed(2));
          } else {
            setValueChange(0);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchChartData();
  }, [selectedYear, selectedSource]);

  // Update the formatTooltipValue function
  const formatTooltipValue = (value, name) => {
    return [`${formatNumber(value)} posts`, name];
  };

  // Update the formatYAxisLabel function
  const formatYAxisLabel = (value) => {
    if (value >= 1000000) {
      return `${(value / 1000000).toFixed(1)}M`;
    }
    if (value >= 1000) {
      return `${(value / 1000).toFixed(1)}k`;
    }
    return formatNumber(value);
  };

  const formatLegendValue = (value) => {
    const labels = {
      telegram: "Telegram",
      twitter: "Twitter",
      total: "Total Posts",
    };
    return labels[value] || value;
  };

  return (
    <div className="bar-chart">
      <div className="bar-chart-info">
        <h5 className="bar-chart-title">News Posts Monitoring Statistics</h5>
        <div className="chart-info-data">
          <div className="info-data-text">
            <FaArrowUpLong
              style={{
                transform: valueChange >= 0 ? "none" : "rotate(180deg)",
                color: valueChange >= 0 ? "green" : "red",
              }}
            />
            <p>
              {valueChange > 0
                ? `${valueChange}% increase from last month`
                : valueChange < 0
                ? `${Math.abs(valueChange)}% decrease from last month`
                : "No change from last month"}
            </p>
          </div>
          {isLoading ? (
            <div className="total-stats">
              <Spinner loading={isLoading} size={16} />
            </div>
          ) : (
            <div className="total-stats">
              <p style={{ color: "#00A36C" }}>
                Total Posts: {formatNumber(statistics.total)}
              </p>
              <p>Twitter: {formatNumber(statistics.twitter)}</p>
              <p>Telegram: {formatNumber(statistics.telegram)}</p>
            </div>
          )}
        </div>
      </div>
      <div className="year-selector">
        <div>
          <label htmlFor="year">Select Year: </label>
          <select
            id="year"
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="year">Select Source: </label>
          <select
            id="year"
            value={selectedSource}
            onChange={(e) => setSelectedSource(e.target.value)}
          >
            <option value={""}>All</option>
            {sources.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="bar-chart-wrapper">
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Spinner size={64} />
          </div>
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={200}
              data={chartData}
              margin={{
                top: 5,
                right: 5,
                left: 0,
                bottom: 5,
              }}
            >
              <XAxis
                padding={{ left: 10 }}
                dataKey="month"
                tickSize={0}
                axisLine={false}
                tick={{
                  fill: "#676767",
                  fontSize: 16,
                }}
                tickFormatter={(month) => month.slice(0, 3)}
              />
              <YAxis
                padding={{ bottom: 10, top: 10 }}
                tickFormatter={formatYAxisLabel}
                axisLine={false}
                tickSize={0}
                tick={{
                  fill: "#676767",
                }}
                domain={[0, calculateMaxDomain(chartData)]}
                ticks={Array.from(
                  {
                    length:
                      Math.floor(calculateMaxDomain(chartData) / 50000) + 1,
                  },
                  (_, i) => i * 50000
                )}
                interval={0} // Force display all ticks
              />
              <Tooltip
                formatter={formatTooltipValue}
                cursor={{ fill: "transparent" }}
              />
              <Legend
                iconType="circle"
                iconSize={10}
                verticalAlign="top"
                align="right"
                formatter={formatLegendValue}
                wrapperStyle={{
                  marginTop: "15px",
                }}
              />
              <Bar
                dataKey="telegram"
                name="Telegram"
                fill="#229ED9" // Official Telegram blue
                activeBar={false}
                isAnimationActive={false}
                barSize={24}
                radius={[4, 4, 4, 4]}
              />
              <Bar
                dataKey="twitter"
                name="Twitter"
                fill="#000000" // Black for Twitter
                activeBar={false}
                isAnimationActive={false}
                barSize={24}
                radius={[4, 4, 4, 4]}
              />
              <Bar
                dataKey="total"
                name="Total Posts"
                fill="rgb(76, 225, 63)"
                activeBar={false}
                isAnimationActive={false}
                barSize={24}
                radius={[4, 4, 4, 4]}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default AreaBarChart;
