import React, { createContext, useState, useContext } from "react";

const TutorialContext = createContext();

export const TutorialProvider = ({ children }) => {
  const [stepIndex, setStepIndex] = useState(0);
  const [run, setRun] = useState(false);

  return (
    <TutorialContext.Provider value={{ stepIndex, setStepIndex, run, setRun }}>
      {children}
    </TutorialContext.Provider>
  );
};

export const useTutorial = () => useContext(TutorialContext);
