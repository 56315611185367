import React, { useState } from "react";
import styled from "styled-components";
import { useBookmarks } from "./BookmarkContext";
import { CloseButton } from "../../../column_popups/PostSharePopup";

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.column_background};
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 300px;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const CategoryList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center; /* Center the items within the flex container */
`;

const CategoryItem = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align items to the left */
  gap: 8px; /* Space between the checkbox and the label */
  font-size: 16px;
  width: 100%;
  max-width: 300px;
`;

const Checkbox = styled.input`
  margin: 0;
  padding: 0;
  flex-shrink: 0; /* Prevent the checkbox from shrinking */
`;

const CategoryText = styled.span`
  flex: 1; /* Allow the text to take up remaining space */
  white-space: nowrap; /* Prevent the text from wrapping */
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const DeleteButton = styled.button`
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 12px 25px;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 600;
  box-shadow: 0 4px 12px rgba(255, 77, 77, 0.3);
  margin-top: 10px;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #cc0000;
    transform: translateY(-3px);
  }
`;

const DeleteTitle = styled.h3`
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
`;

const DeleteCategoryModal = ({ onClose }) => {
  const { categories, clearSelectedCategories } = useBookmarks();
  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleSelectCategory = (category) => {
    setSelectedCategories((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    );
  };

  const handleSelectAll = () => {
    if (selectedCategories.length === categories.length) {
      setSelectedCategories([]); // Deselect all
    } else {
      setSelectedCategories(categories); // Select all
    }
  };

  return (
    <>
      <Overlay onClick={onClose} />
      <ModalContainer>
        <DeleteTitle>Select Categories to Delete</DeleteTitle>
        <CloseButton onClick={onClose}>×</CloseButton>
        <CategoryList>
          {categories.map((category) => (
            <CategoryItem key={category}>
              <Checkbox
                type="checkbox"
                checked={selectedCategories.includes(category)}
                onChange={() => handleSelectCategory(category)}
              />
              <CategoryText>{category}</CategoryText>
            </CategoryItem>
          ))}
          {categories.length > 1 && (
            <CategoryItem>
              <Checkbox
                type="checkbox"
                checked={selectedCategories.length === categories.length}
                onChange={handleSelectAll}
              />
              <CategoryText>Select All</CategoryText>
            </CategoryItem>
          )}
        </CategoryList>

        <ButtonContainer>
          <DeleteButton
            onClick={() => {
              clearSelectedCategories(selectedCategories);
              onClose();
            }}
            disabled={selectedCategories.length === 0}
          >
            Delete
          </DeleteButton>
        </ButtonContainer>
      </ModalContainer>
    </>
  );
};

export default DeleteCategoryModal;
