import React, { useEffect, useState } from "react";
import { Button, IconButton, CircularProgress } from "@mui/material";
import {
  Visibility,
  VisibilityOff,
  ContentCopy,
  Autorenew,
} from "@mui/icons-material";
import { FaCrown } from "react-icons/fa";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";
import { formatDate } from "../Organizations/utils";
import {
  copyToClipboard,
  deleteUser,
  fetchData,
  regenerateCode,
} from "./UsersUtils";

const PaginationControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  & > button {
    margin: 0 5px;
  }

  .page-info {
    margin: 0 10px;
    font-weight: bold;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const OrganizationInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  font-size: 15px;

  .info-item {
    margin-right: 20px;
    display: flex;
    align-items: center;
  }

  .info-label {
    font-weight: bold;
    margin-right: 5px;
  }

  .code-container {
    display: flex;
    align-items: center;
  }

  .code-display {
    margin-left: 10px;
    font-family: monospace;
    background-color: #e0e0e0;
    padding: 5px;
    border-radius: 5px;
  }
`;

const Table = () => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [users, setUsers] = useState([]);
  const [orgDetails, setOrgDetails] = useState({});
  const [showCode, setShowCode] = useState(false);
  const [codeRegenerating, setCodeRegenerating] = useState(false);

  const totalPages = Math.ceil(count / itemsPerPage);

  useEffect(() => {
    fetchData(
      setUsers,
      setOrgDetails,
      setCount,
      setLoading,
      currentPage,
      itemsPerPage
    );
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <section className="content-area-table">
      <div className="content-area">
        <h1
          style={{
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Team Members Table
        </h1>
        {/* Organization Info Section */}
        <OrganizationInfo>
          <div className="info-item">
            <span className="info-label">Organization Name:</span>
            {orgDetails.name || "N/A"}
          </div>
          <div className="info-item">
            <span className="info-label">Max Capacity:</span>
            {orgDetails.maxCapacity || "N/A"}
          </div>
          <div className="info-item">
            <span className="info-label">Current Members:</span>
            {count || "N/A"}
          </div>
          <div className="info-item code-container">
            <span className="info-label">Organization Code:</span>
            {showCode ? (
              <span className="code-display">
                {orgDetails.organizationCode || "N/A"}
              </span>
            ) : (
              <span className="code-display">******</span>
            )}
            <IconButton onClick={() => setShowCode(!showCode)}>
              {showCode ? <VisibilityOff /> : <Visibility />}
            </IconButton>
            <IconButton
              onClick={() => copyToClipboard(orgDetails.organizationCode)}
            >
              <ContentCopy />
            </IconButton>
            <IconButton
              onClick={() => {
                regenerateCode(
                  orgDetails.id,
                  setOrgDetails,
                  setCodeRegenerating
                );
              }}
              disabled={codeRegenerating}
            >
              {codeRegenerating ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <Autorenew />
              )}
            </IconButton>
          </div>
          <div className="info-item">
            <span className="info-label">Code Expiration:</span>
            {orgDetails.codeExpiration
              ? formatDate(orgDetails.codeExpiration)
              : "N/A"}
          </div>
        </OrganizationInfo>

        <div className="data-table-diagram">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <>
              <table>
                <thead>
                  <tr>
                    <th>Name</th>

                    <th>Phone Number</th>
                    <th>Email</th>
                    <th>Total Time Spent (hours)</th>
                    <th>Joined At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {users?.length > 0 ? (
                    users.map((item, index) => (
                      <tr key={`${item.id}-${index}`}>
                        <td>{item.name || "N/A"} </td>
                        <td>{item.phone || "N/A"}</td>
                        <td>
                          {item.email}
                          {(item.role === "admin" ||
                            item.role === "business") && (
                            <FaCrown
                              style={{
                                color: "gold",
                                marginLeft: "5px",
                                fontSize: "25px",
                                cursor: "pointer",
                              }}
                              title="This user is the admin"
                            />
                          )}
                        </td>

                        <td>{item.total_time_spent || "N/A"}</td>
                        <td>
                          {item.created_at
                            ? formatDate(item.created_at)
                            : "N/A"}
                        </td>
                        <td>
                          {item.role === "admin" || item.role === "business" ? (
                            <p style={{ textAlign: "center" }}>
                              No Actions for Admin
                            </p>
                          ) : (
                            <ButtonContainer>
                              <Button
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you sure you want to delete this user?"
                                    )
                                  ) {
                                    deleteUser(item.id, users, setUsers);
                                  }
                                }}
                                variant="contained"
                                sx={{
                                  backgroundColor: "red",
                                  color: "#fff",
                                  "&:hover": {
                                    backgroundColor: "#cc0000",
                                  },
                                }}
                                startIcon={<DeleteIcon />}
                              >
                                Delete
                              </Button>
                            </ButtonContainer>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" style={{ textAlign: "center" }}>
                        No Users in Your Organization
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              {/* Pagination Controls */}
              <PaginationControls>
                <Button
                  variant="contained"
                  onClick={() => handlePageChange(1)}
                  disabled={currentPage === 1}
                >
                  First
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </Button>
                <span className="page-info">
                  Page {currentPage} of {totalPages}
                </span>
                <Button
                  variant="contained"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handlePageChange(totalPages)}
                  disabled={currentPage === totalPages}
                >
                  Last
                </Button>
              </PaginationControls>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Table;
