import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useColumns } from "../column/ColumnsContext";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import Popup from "../column_popups/AddColumnPopup";
import { BookmarkProvider } from "../side-panel/tabs/bookmarks/BookmarkContext";
import SidePanelButton from "../side-panel/SideButton";
import logo from "../../assets/images/Teledeck.svg";
import "../../assets/css/Sidebar.css";
import NavItem from "./NavItem";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../authentication/auth-context/AuthContext";
import { toast } from "sonner";
import { handleNavigation } from "../../global/globalUtils";

const SideBarContainer = styled.div`
  width: 100%;
  height: calc(100vh - env(safe-area-inset-bottom));
  padding: 0;
  margin: 0;
`;

const NavBarContainer = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
`;

const NavBar = styled.div`
  width: ${(props) => props.width}px;
  height: 100%;
  background-color: ${({ theme }) => theme.nav_background};
  transition: width 0.1s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const NavItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 360px);
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  box-sizing: border-box;
  position: relative;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    transition: background-color 0.3s linear;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.border_color};
      transition: background-color 0.3s linear;
    }
  }
`;

const GradientOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 15px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0));
  pointer-events: none;
`;

const ColumnContainer = styled.div`
  width: calc(100% -${(props) => props.$margin_left}px);
  margin-left: ${(props) => props.$margin_left}px;
  transition: margin 0.1s;
`;

const NavButtons = styled.div`
  position: absolute;
  bottom: 20px;
  overflow-x: hidden;
  width: 100%;
`;

const PopUpContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ItemContainer = styled.div`
  width: 200px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: white;
  box-sizing: border-box;
  padding: 20px 10px;
  background-color: ${({ theme }) => theme.nav_background};

  &:hover {
    background-color: ${({ theme }) => theme.nav_hover};
  }
`;

const ItemText = styled.div`
  font-size: 14px;
  overflow: hidden;
  white-space: wrap;
  text-overflow: ellipsis;
  max-width: 100px;
`;

const ItemIcon = styled.i`
  font-size: 24px;
  color: white;
`;

const ItemNumber = styled.div`
  padding: 2px 6px;
  border-radius: 12px;
  font-size: 12px;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
`;

const SearchLabel = styled.p`
  font-size: 14px;
  color: white;
  margin: 0;
  margin-left: 15px;
  padding: 0;
  display: ${({ $isExpanded, $isVisible }) =>
    !$isExpanded && $isVisible ? "block" : "none"};
  opacity: ${({ $isExpanded }) => ($isExpanded ? 0 : 1)};
  visibility: ${({ $isExpanded, $isVisible }) =>
    !$isExpanded && $isVisible ? "visible" : "hidden"};
  transition: opacity 0.5s ease, visibility 0.5s ease; /* Adjust transition duration */
`;

const SideBar = ({ children }) => {
  const [navWidth, setNavWidth] = useState(200);
  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState("");
  const { columns, reorderColumns } = useColumns();
  const popupRef = useRef(null);
  const navItemsRef = useRef(null);
  const gradientRef = useRef(null);
  const [search, setSearch] = useState("");
  const [filteredColumns, setFilteredColumns] = useState(columns);
  const navigate = useNavigate();
  const { role, bundle } = useAuth();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);

    // If collapsing, delay before showing the text
    if (isExpanded) {
      setTimeout(() => {
        setIsVisible(true);
      }, 500); // Match this to your transition time
    } else {
      setIsVisible(false); // Hide the text when expanding
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const element = navItemsRef.current;
      const scrollTop = element.scrollTop;
      const opacity = 1 - Math.min(1, scrollTop / 10);

      if (gradientRef.current) {
        gradientRef.current.style.opacity = opacity.toString();
      }
    };

    const navItemsElement = navItemsRef.current;
    navItemsElement.addEventListener("scroll", handleScroll);

    return () => navItemsElement.removeEventListener("scroll", handleScroll);
  }, []);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    reorderColumns(result.source.index, result.destination.index);
  };

  const toggleNavWidth = () => {
    setIsExpanded(false);
    setNavWidth(navWidth === 200 ? 45 : 200);
  };

  const togglePopup = (type) => {
    setPopupType(type);
    setShowPopup(!showPopup);
  };

  const searchColumnTitles = () => {
    if (!search.trim()) {
      setFilteredColumns(
        columns.map((col, index) => ({ ...col, originalIndex: index }))
      );
    } else {
      const filtered = columns
        .filter((col) => col.name.toLowerCase().includes(search.toLowerCase()))
        .map((col, index) => ({
          ...col,
          originalIndex: columns.findIndex((x) => x.id === col.id),
        }));

      setFilteredColumns(filtered);
    }
  };

  useEffect(() => {
    setFilteredColumns(columns);
    searchColumnTitles();
  }, [search, columns]);

  const handleDoubleClick = async (clickedIndex) => {
    const originalIndex = filteredColumns[clickedIndex].originalIndex;
    await reorderColumns(originalIndex, 0);
  };

  const handleScrollToColumn = (name, index) => {
    const elementId = `column-${name}-${index}`;
    const element = document.getElementById(elementId);

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });

      // Apply a grey dimming effect using filter
      element.style.transition = "filter 1s";
      element.style.filter = "brightness(0.8) grayscale(20%)"; // Dimming effect with slight grayscale

      setTimeout(() => {
        element.style.filter = "none";
      }, 1000);
    }
  };

  return (
    <SideBarContainer>
      <NavBarContainer>
        <NavBar width={navWidth}>
          <img src={logo} alt="logo" style={{ width: "200px" }} />
          <div
            className={`afj-search-container ${isExpanded ? "expanded" : ""}`}
          >
            <SearchContainer onClick={toggleExpand} title="Search Columns">
              <span className="material-icons afj-search-icon">search</span>
              <SearchLabel $isExpanded={isExpanded} $isVisible={isVisible}>
                Search Columns
              </SearchLabel>{" "}
            </SearchContainer>
            <input
              type="text"
              className="afj-search-input"
              placeholder="Find a Column..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onBlur={toggleExpand}
            />
          </div>
          <NavItemsContainer ref={navItemsRef}>
            <GradientOverlay ref={gradientRef} />
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppableNav" direction="vertical">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {filteredColumns.map((column, index) => (
                      <Draggable
                        key={column.id}
                        draggableId={column.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            onClick={() =>
                              handleScrollToColumn(column.name, index)
                            }
                          >
                            <ItemContainer>
                              <ItemIcon
                                className="material-icons"
                                {...provided.dragHandleProps}
                              >
                                {column.icon}
                              </ItemIcon>
                              <ItemText
                                onDoubleClick={() => handleDoubleClick(index)}
                              >
                                {column.name}
                              </ItemText>
                              <ItemNumber>{index + 1}</ItemNumber>
                            </ItemContainer>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </NavItemsContainer>

          <NavButtons>
            {(role === "admin" || role === "business") && (
              <NavItem
                icon="admin_panel_settings"
                name="Admin"
                isClickable={true}
                onClick={() => {
                  navigate(
                    `/${role === "admin" ? "admin" : "admin/business-users"}`
                  );
                }}
              />
            )}

            <NavItem
              icon="map"
              name="Map"
              isClickable={true}
              onClick={() => handleNavigation(bundle, navigate, "/map")}
              map
            />

            <NavItem
              icon="edit_document"
              name="Text Editor"
              isClickable={true}
              onClick={() => {
                if (bundle === "free_trial") {
                  toast.error("This feature is not available in your plan");
                  navigate("/bundles");
                } else {
                  window.location.href = "/bookmark-editor";
                }
              }}
            />

            <NavItem
              icon="add"
              name="New Column"
              isClickable={true}
              onClick={() => togglePopup("newColumn")}
              newColumn
            />
            <NavItem
              icon={
                navWidth === 200
                  ? "keyboard_double_arrow_left"
                  : "keyboard_double_arrow_right"
              }
              name="Minimize"
              isClickable={true}
              onClick={toggleNavWidth}
            />
            <NavItem
              icon="settings"
              name="Settings"
              isClickable={true}
              onClick={() => togglePopup("settings")}
            />
          </NavButtons>
        </NavBar>
      </NavBarContainer>

      <BookmarkProvider>
        <ColumnContainer $margin_left={navWidth}>{children}</ColumnContainer>
        <SidePanelButton />
      </BookmarkProvider>

      {showPopup && (
        <PopUpContainer>
          <div ref={popupRef}>
            <Popup onClose={() => setShowPopup(false)} type={popupType} />
          </div>
        </PopUpContainer>
      )}
    </SideBarContainer>
  );
};

export default SideBar;
