import { postAuth } from "../../../global/globalUtils";
import { toast } from "sonner";

export const fetchChannelsWithTags = async (
  search,
  chosenChannelIds,
  tags,
  source,
  page,
  setLoading
) => {
  try {
    setLoading(true);
    // Include chosenChannelIds in the request payload
    const response = await postAuth(`/channel/?page=${page}`, {
      search,
      source,
      tags,
      exclude: chosenChannelIds,
    });

    if (!response.ok) {
      toast.error("Failed to fetch channels");
      return null;
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching channels with tags:", error);
    return null;
  } finally {
    setLoading(false);
  }
};

export const shuffleArray = (array) => {
  let shuffledArray = array.slice(); // Create a copy of the array
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};
