import styled from "styled-components";
import calendarIcon from "../../assets/images/calendar.svg";
import whitecalendarIcon from "../../assets/images/whiteCalendar.svg";

export const DatePicker = styled.input`
  width: 100%;
  height: 60%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  padding: 8px ${({ paddingHorizontal }) => paddingHorizontal || "12px"}; /* Default horizontal padding is 12px */

  border-radius: 5px;
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  border-color: ${({ theme }) => theme.border_color};
  margin-bottom: 5px;
  font-size: 12px;

  &::-webkit-calendar-picker-indicator {
    opacity: 1;
    font-size: ${({ fontSize }) => fontSize || "20px"};
    background-image: ${({ $theme }) =>
      $theme === "light"
        ? `url(${calendarIcon})`
        : `url(${whitecalendarIcon})`};
    background-size: cover;
    cursor: pointer;
  }

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.primary};
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5);
  }
`;
