import React, { createContext, useContext, useState, useEffect } from "react";
import { getColumnsFromDB } from "../localdb/DBColumn";
import {
  syncColumnsWithAPI,
  updateColumnsPositionsToAPI,
} from "./column-utils/ColumnUtils";

const ColumnsContext = createContext();

export const ColumnsProvider = ({ children }) => {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const fetchColumns = async () => {
      try {
        await syncColumnsWithAPI();
        let apiColumns = await getColumnsFromDB();

        apiColumns = apiColumns.map((column) => ({
          ...column,
          chips: {
            textOnly: false,
            images: false,
            videos: false,
            previews: false,
          },
        }));

        const sortedColumns = apiColumns.sort(
          (a, b) => a.column_position - b.column_position
        );

        setColumns(sortedColumns);
      } catch (error) {
        console.error("Failed to fetch columns:", error);
      }
    };
    fetchColumns();
  }, []);

  const reorderColumns = async (startIndex, endIndex) => {
    const result = Array.from(columns);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    const reorderedColumns = result.map((column, index) => ({
      ...column,
      column_position: index + 1,
    }));

    setColumns(reorderedColumns);

    await updateColumnsPositionsToAPI(reorderedColumns);
  };

  const addColumn = (newColumn) => {
    newColumn.channels = newColumn.Channels;
    delete newColumn.Channels;
    const updatedColumns = [...columns, newColumn];
    setColumns(
      updatedColumns.sort((a, b) => a.column_position - b.column_position)
    );
  };

  const removeColumn = (columnId) => {
    const updatedColumns = columns.filter((column) => column.id !== columnId);
    setColumns(
      updatedColumns.sort((a, b) => a.column_position - b.column_position)
    );
  };

  const updateColumn = (updatedColumn) => {
    const updatedColumns = columns.map((column) =>
      column.id === updatedColumn.id ? updatedColumn : column
    );
    setColumns(
      updatedColumns.sort((a, b) => a.column_position - b.column_position)
    );
  };

  const resetColumnProperties = async () => {
    const updatedColumns = columns.map((column) => ({
      ...column,
      get_image: 0,
      get_preview: 0,
      get_text_only: 0,
      get_video: 0,
    }));

    setColumns(updatedColumns);

    await updateColumnsPositionsToAPI(updatedColumns);
  };

  const columnsCount = columns.length;

  return (
    <ColumnsContext.Provider
      value={{
        columns,
        reorderColumns,
        addColumn,
        removeColumn,
        updateColumn,
        resetColumnProperties,
        columnsCount,
      }}
    >
      {children}
    </ColumnsContext.Provider>
  );
};

// Custom hook to use the context
export const useColumns = () => useContext(ColumnsContext);
