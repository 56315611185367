import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import icon from "../../assets/images/chatbot.svg";
import DateFilterHeader from "./DateFilter";

const ChatContainer = styled.div`
  position: fixed;
  z-index: 1000;
`;

const ChatButtonWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: inline-block;
  z-index: 1000;
`;

const ChatButton = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.primary || "#1e2836"};
  color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  .icon-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    &.chat-icon {
      opacity: ${({ $isOpen }) => ($isOpen ? 0 : 1)};
      transform: rotate(${({ $isOpen }) => ($isOpen ? "180deg" : "0deg")});
    }

    &.close-icon {
      opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
      transform: rotate(${({ $isOpen }) => ($isOpen ? "0deg" : "-180deg")});
    }
  }
`;

const ChatBox = styled(motion.div)`
  position: fixed;
  background-color: ${({ theme }) => theme.popup_background || "#fff"};
  color: ${({ theme }) => theme.text || "#333"};
  border: 1px solid ${({ theme }) => theme.border_color || "#ddd"};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: 350px;
  min-height: 250px;
  max-width: 800px;
  max-height: 80vh;
  resize: both;
`;

const ChatBody = styled(motion.div)`
  flex: 1;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.scrollbar_thumb || "#888"};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.scrollbar_track || "#f1f1f1"};
  }

  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.scrollbar_thumb || "#888"}
    ${({ theme }) => theme.scrollbar_track || "#f1f1f1"};
`;

const ChatInputContainer = styled.div`
  padding: 10px;
  border-top: 1px solid ${({ theme }) => theme.border_color || "#ddd"};
  display: flex;
  gap: 10px;
`;

const ChatInput = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.border_color || "#ddd"};
  border-radius: 8px;
  outline: none;
  font-size: 14px;
  background-color: ${({ theme }) => theme.popup_background || "#fff"};
  color: ${({ theme }) => theme.text || "#333"};
`;

const ChatSendButton = styled.button`
  background-color: #1e2836;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  width: 70px;

  &:hover {
    background-color: ${({ theme }) => theme.hover || "#333"};
  }
`;

const ChatMessage = styled(motion.div)`
  background-color: ${({ $isUser }) => ($isUser ? "#f0f0f0" : "#e3eaf5")};
  color: ${({ $isUser }) => ($isUser ? "#333" : "#000")};
  padding: 8px;
  border-radius: 8px;
  max-width: 80%;
  align-self: ${({ $isUser }) => ($isUser ? "flex-end" : "flex-start")};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  line-height: 1.5;
`;

const ToastMessage = styled(motion.div)`
  position: absolute;
  right: calc(100% + 15px);
  bottom: 25%;
  background-color: ${({ theme }) => theme.primary || "#1e2836"};
  color: white;
  padding: 8px 16px;
  border-radius: 8px;
  white-space: nowrap;
  font-size: 14px;
  pointer-events: none;
`;

const ChatBot = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const chatBodyRef = useRef(null);
  const chatBoxRef = useRef(null);
  const [boxSize, setBoxSize] = useState({ width: 400, height: 400 });
  const [boxPosition, setBoxPosition] = useState({
    top: window.innerHeight - 390, // Initialize from bottom
    left: window.innerWidth - 420, // Initialize from right
  });
  const [dragging, setDragging] = useState(false);
  const dragStartPos = useRef({ x: 0, y: 0 });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isChatOpen) {
      setBoxPosition({
        top: window.innerHeight - 490,
        left: window.innerWidth - 420,
      });
    }
  }, [isChatOpen]);

  useEffect(() => {
    const chatBox = chatBoxRef.current;
    if (!chatBox) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;
        const newSize = {
          width: Math.min(Math.max(width, 300), 800),
          height: Math.min(Math.max(height, 400), window.innerHeight * 0.8),
        };
        setBoxSize(newSize);
      }
    });

    resizeObserver.observe(chatBox);
    return () => resizeObserver.disconnect();
  }, []);

  const toggleChat = () => {
    setIsChatOpen((prev) => !prev);
  };

  const handleMouseEnter = () => {
    if (!isChatOpen) {
      setShowToast(true);
    }
  };

  const handleMouseLeave = () => {
    setShowToast(false);
  };

  const handleSend = async () => {
    if (inputValue.trim()) {
      const userMessage = { text: inputValue, isUser: true };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      setInputValue("");

      try {
        setLoading(true);

        // Add default time to the dates
        const fromDateTime = dateRange.from
          ? `${dateRange.from}T00:00:00`
          : null;
        const toDateTime = dateRange.to ? `${dateRange.to}T23:59:59` : null;

        const response = await fetch("https://chatbot.teledeck.news/chat-bot", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            question: inputValue,
            start_date: fromDateTime,
            end_date: toDateTime,
          }),
        });

        const data = await response.json();

        const botMessage = { text: data.message, isUser: false };
        setMessages((prevMessages) => [...prevMessages, botMessage]);
      } catch (error) {
        console.error("Error sending message:", error);
        const errorMessage = {
          text: "An error occurred while processing your request. Please try again.",
          isUser: false,
        };
        setMessages((prevMessages) => [...prevMessages, errorMessage]);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  const scrollToBottom = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleDragStart = (e) => {
    e.preventDefault();
    setDragging(true);
    dragStartPos.current = { x: e.clientX, y: e.clientY };
  };

  const handleDrag = (e) => {
    if (!dragging) return;

    const deltaX = e.clientX - dragStartPos.current.x;
    const deltaY = e.clientY - dragStartPos.current.y;

    setBoxPosition((prev) => ({
      top: Math.min(
        Math.max(prev.top + deltaY, 0),
        window.innerHeight - boxSize.height
      ),
      left: Math.min(
        Math.max(prev.left + deltaX, 0),
        window.innerWidth - boxSize.width
      ),
    }));

    dragStartPos.current = { x: e.clientX, y: e.clientY };
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  useEffect(() => {
    if (dragging) {
      document.addEventListener("mousemove", handleDrag);
      document.addEventListener("mouseup", handleDragEnd);
    } else {
      document.removeEventListener("mousemove", handleDrag);
      document.removeEventListener("mouseup", handleDragEnd);
    }

    return () => {
      document.removeEventListener("mousemove", handleDrag);
      document.removeEventListener("mouseup", handleDragEnd);
    };
  }, [dragging]);

  return (
    <ChatContainer>
      <ChatButtonWrapper
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <ChatButton
          onClick={toggleChat}
          title={isChatOpen ? "Close chat" : "Open chat"}
          $isOpen={isChatOpen}
        >
          <div className="icon-container chat-icon">
            <img
              src={icon}
              alt="Chat"
              width="36px"
              height="36px"
              style={{ color: "white" }}
            />
          </div>
          <div className="icon-container close-icon">
            <span
              className="material-icons"
              style={{
                fontSize: "30px",
              }}
            >
              close
            </span>
          </div>
        </ChatButton>

        <AnimatePresence>
          {showToast && !isChatOpen && (
            <ToastMessage
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.2 }}
            >
              Ask our AI
            </ToastMessage>
          )}
        </AnimatePresence>
      </ChatButtonWrapper>

      <AnimatePresence>
        {isChatOpen && (
          <ChatBox
            ref={chatBoxRef}
            style={{
              top: `${boxPosition.top}px`,
              left: `${boxPosition.left}px`,
              width: `${boxSize.width}px`,
              height: `${boxSize.height}px`,
            }}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.3 }}
          >
            <DateFilterHeader
              dateRange={dateRange}
              setDateRange={setDateRange}
              onMouseDown={handleDragStart}
            />

            <ChatBody ref={chatBodyRef}>
              <ChatMessage
                key={0}
                $isUser={false}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                {
                  "Welcome to the chat! 📰 Ask me about the news and I will try to help you with the information. 😊"
                }
              </ChatMessage>
              {messages.map((message, index) => (
                <ChatMessage
                  key={index}
                  $isUser={message.isUser}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  {message.text.split("\n").map((line, i) => (
                    <React.Fragment key={i}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </ChatMessage>
              ))}
              {loading && (
                <ChatMessage $isUser={false}>
                  <span>⏳ Loading...</span>
                </ChatMessage>
              )}
            </ChatBody>
            <ChatInputContainer>
              <ChatInput
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyPress}
                placeholder="Type your message..."
              />
              <ChatSendButton onClick={handleSend} disabled={loading}>
                Send
              </ChatSendButton>
            </ChatInputContainer>
          </ChatBox>
        )}
      </AnimatePresence>
    </ChatContainer>
  );
};

export default ChatBot;
