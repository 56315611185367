import React, { createContext, useContext, useState, useRef } from "react";

// Create the context
const LocationsContext = createContext();

// Create the provider component
export const LocationsProvider = ({ children }) => {
  const [locations, setLocations] = useState([]);
  const [fullPostLoading, setFullPostLoading] = useState(false);
  const [mapDataLoading, setMapDataLoading] = useState(false);
  const markersRef = useRef({}); // Use a ref to store marker references
  const markerClusterRef = useRef(null); // Use a ref to store the marker cluster reference

  return (
    <LocationsContext.Provider
      value={{
        locations,
        setLocations,
        markersRef,
        markerClusterRef,
        fullPostLoading,
        setFullPostLoading,
        mapDataLoading,
        setMapDataLoading,
      }}
    >
      {children}
    </LocationsContext.Provider>
  );
};

// Custom hook to use the LocationsContext
export const useLocations = () => {
  const context = useContext(LocationsContext);
  if (!context) {
    throw new Error("useLocations must be used within a LocationsProvider");
  }
  return context;
};
