import { toast } from "sonner";
import { postAuth } from "../../../global/globalUtils";

export const verifyEmail = async (code) => {
  if (!code) {
    console.error("Code is required");
    return { success: false, message: "Verification Code is required" };
  }

  const response = await postAuth("/auth/verify-email", { code });
  const data = await response.json();
  if (!response.ok) {
    console.error("Failed to verify email: ", data.message);
    return {
      success: false,
      message: data.message || "Failed to verify email",
    };
  }

  if (data.status === "success") {
    return { success: true, message: "Verification successful" };
  } else {
    console.error("Failed to verify email: ", data.message);
    return {
      success: false,
      message: data.message || "Failed to verify email",
    };
  }
};

export const resendCode = async (setIsResending) => {
  try {
    setIsResending(true);
    const response = await postAuth("/auth/resend-code");
    const data = await response.json();

    if (!response.ok) {
      console.error("Failed to resend code: ", data.message);
      toast.error(data.message || "Failed to resend code");
      return {
        success: false,
        message: data.message || "Failed to resend code",
      };
    }

    if (data.status === "success") {
      toast.success("Code sent successfully");
      return { success: true, message: "Code resent successfully" };
    } else {
      console.error("Failed to resend code: ", data.message);
      toast.error(data.message || "Failed to resend code");
      return {
        success: false,
        message: data.message || "Failed to resend code",
      };
    }
  } catch (error) {
    console.error("Error resending code: ", error);
    toast.error("Something went wrong. Please try again later.");
    return {
      success: false,
      message: "Something went wrong. Please try again later.",
    };
  } finally {
    setIsResending(false);
  }
};
