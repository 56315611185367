import React from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import { useTheme } from "../../../themes/ThemeContext";

const StyledAccordion = styled(Accordion)`
  background-color: ${({ theme }) =>
    theme === "dark" ? "#2d2d2d" : "#F8F8F8"} !important;
  color: ${({ theme }) => (theme === "dark" ? "#FFF" : "#000")};
  border-radius: 8px !important;
  overflow: hidden;
  margin-bottom: 15px;

  &::before {
    display: none; /* Removes default MUI divider above accordion */
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  background-color: ${({ theme }) =>
    theme === "dark" ? "#2d2d2d" : "#F8F8F8"} !important;
  color: ${({ theme }) => (theme === "dark" ? "#FFF" : "#000")}!important;
  padding: 0 16px;

  .MuiAccordionSummary-content {
    margin: 0 !important; /* Removes unwanted margin */
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  background-color: ${({ theme }) =>
    theme === "dark" ? "#2d2d2d" : "#F8F8F8"} !important;
  color: ${({ theme }) => (theme === "dark" ? "#FFF" : "#000")};
`;

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 15px; /* Space between inputs */
  padding: 10px 0;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* One column on smaller screens */
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 120px; /* Minimum width for consistent alignment */
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  margin-left: 5px;
`;

const Select = styled.select`
  padding: 10px;
  background-color: ${({ theme }) => (theme === "dark" ? "#444" : "#FFF")};
  color: ${({ theme }) => (theme === "dark" ? "#FFF" : "#000")};
  border: 1px solid ${({ theme }) => (theme === "dark" ? "#666" : "#ddd")};
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

const AccordionInputContainer = ({
  language,
  setLanguage,
  displayLanguage,
  setDisplayLanguage,
  time,
  setTime,
  location,
  setLocation,
}) => {
  const { theme } = useTheme();

  return (
    <StyledAccordion theme={theme}>
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="filter-options-content"
        id="filter-options-header"
        theme={theme}
      >
        Filter Options
      </StyledAccordionSummary>
      <StyledAccordionDetails theme={theme}>
        <InputContainer>
          <InputWrapper>
            <Label htmlFor="language">Source Language</Label>
            <Select
              id="language"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              theme={theme}
            >
              <option value="arabic">Arabic</option>
              <option value="hebrew">Hebrew</option>
            </Select>
          </InputWrapper>
          <InputWrapper>
            <Label htmlFor="displayLanguage">Display Language</Label>
            <Select
              id="displayLanguage"
              value={displayLanguage}
              onChange={(e) => setDisplayLanguage(e.target.value)}
              theme={theme}
            >
              <option value="arabic">Arabic</option>
              <option value="hebrew">Hebrew</option>
              <option value="english">English</option>
            </Select>
          </InputWrapper>
          <InputWrapper>
            <Label htmlFor="time">Time Period</Label>
            <Select
              id="time"
              value={time}
              onChange={(e) => setTime(Number(e.target.value))}
              theme={theme}
            >
              <option value={10}>Trending Now</option>
              <option value={60}>Last Hour</option>
              <option value={6 * 60}>Last 6 Hours</option>
              <option value={24 * 60}>Last 24 Hours</option>
            </Select>
          </InputWrapper>
          <InputWrapper>
            <Label htmlFor="location">Location</Label>
            <Select
              id="location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              theme={theme}
            >
              <option value="anywhere">Worldwide</option>
              <option value="lebanon">Lebanon</option>
              <option value="gaza">Gaza</option>
              <option value="palestine">Palestine</option>
              <option value="israel">Israel</option>
            </Select>
          </InputWrapper>
        </InputContainer>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default AccordionInputContainer;
