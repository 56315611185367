import React, { useState } from "react";
import styled from "styled-components";
import { BookmarkComponent } from "./tabs/bookmarks/Bookmarks";
import SearchComponent from "./tabs/global-search/SearchTab";
import TrendingTab from "./tabs/trending/TrendingTab";
import DeleteCategoryModal from "./tabs/bookmarks/DeletePopup";
import TelegramPopup from "../post/widgets/TelegramWidget";
import TwitterPopup from "../post/widgets/TwitterWidget";
import { useAuth } from "../authentication/auth-context/AuthContext";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import DownloadTab from "./tabs/global-download/DownloadTab";

const SidePanelContainer = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  width: 400px;
  height: 100vh;
  padding: 20px;
  background-color: ${({ theme }) => theme.column_background};
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 22;
  transform: translateX(${(props) => (props.$show ? "0" : "100%")});
  transition: transform 0.3s ease;
  max-height: 95vh;

  @media (max-width: 768px) {
    width: 80%;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.chip_inactive};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.background};
    border-radius: 10px;
    border: 3px solid ${({ theme }) => theme.chip_inactive};
  }
`;

const Tabs = styled.div`
  display: flex;
  border-bottom: 2px solid ${({ theme }) => theme.border || "#eaeaea"};
  overflow: hidden;
  justify-content: center;
  @media (max-width: 768px) {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`;

const Tab = styled.div`
  font-size: 14px; /* Reduced font size */
  padding: 8px 8px; /* Reduced padding */
  cursor: pointer;
  display: flex;
  position: relative;
  gap: 5px;
  transition: background-color 0.3s;
  border-bottom: ${({ theme, $selected }) =>
    $selected ? `2px solid ${theme.background}` : "none"};

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  &:hover {
    background-color: ${({ theme }) =>
      theme.post_hover_background || "#f5f5f5"};
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    right: 0;
    height: 3px;
    background-color: #1e2836;
    transform: ${({ $selected }) => ($selected ? "scaleX(1)" : "scaleX(0)")};
    transform-origin: bottom left;
    transition: transform 0.3s;
  }

  p {
    margin: 0 0 0 8px;
    font-size: 14px; /* Reduced font size */
  }

  .material-icons {
    font-size: 20px; /* Reduced icon size */
  }

  ${({ $hideOnMobile }) =>
    $hideOnMobile &&
    `
    @media (max-width: 768px) {
      display: none; /* Hide the tab on mobile screens */
    }
  `}
`;

const SidePanelTabs = ({ show }) => {
  const [showBookmarks, setShowBookmarks] = useState(true);
  const [showSearch, setShowSearch] = useState(false);
  const [showTrending, setShowTrending] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [isPostPopupOpen, setIsPostPopupOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [currentsender, setCurrentSender] = useState("");
  const [currentSource, setCurrentSource] = useState("");
  const [currentTweetId, setCurrentTweetId] = useState("");
  const { bundle } = useAuth();
  const navigate = useNavigate();

  return (
    <>
      {isDeleteModalOpen && (
        <DeleteCategoryModal
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
        />
      )}
      {isPostPopupOpen &&
        (currentSource === "Telegram" ? (
          <TelegramPopup
            senderusername={currentsender}
            post_id={currentId}
            onClose={() => setIsPostPopupOpen(false)}
          />
        ) : (
          <TwitterPopup
            tweetId={currentTweetId}
            onClose={() => setIsPostPopupOpen(false)}
          />
        ))}
      <SidePanelContainer $show={show}>
        <Tabs>
          <Tab
            id="sidepanel-bookmarks"
            $selected={showBookmarks}
            onClick={() => {
              setShowBookmarks(true);
              setShowSearch(false);
              setShowTrending(false);
              setShowDownload(false);
            }}
          >
            <span className="material-icons">bookmark</span>
            <span>Bookmarks</span>
          </Tab>
          <Tab
            id="sidepanel-trending"
            $selected={showTrending}
            onClick={() => {
              setShowBookmarks(false);
              setShowSearch(false);
              setShowDownload(false);
              if (bundle === "free_trial") {
                toast.error("This feature is not available in your plan");
                navigate("/bundles");
              } else setShowTrending(true);
            }}
          >
            <span className="material-icons">trending_up</span>
            <span>Trending</span>
          </Tab>
          <Tab
            id="sidepanel-search"
            $selected={showSearch}
            onClick={() => {
              setShowBookmarks(false);
              setShowSearch(true);
              setShowTrending(false);
              setShowDownload(false);
            }}
          >
            <span className="material-icons">search</span>
            <span>Search</span>
          </Tab>
          <Tab
            id="sidepanel-search"
            $selected={showDownload}
            onClick={() => {
              setShowBookmarks(false);
              setShowSearch(false);
              setShowTrending(false);
              setShowDownload(true);
            }}
          >
            <span className="material-icons">download</span>
            <span>Download</span>
          </Tab>
        </Tabs>
        {showBookmarks && (
          <BookmarkComponent
            setIsPostPopupOpen={setIsPostPopupOpen}
            setCurrentId={setCurrentId}
            setCurrentSender={setCurrentSender}
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            setCurrentSource={setCurrentSource}
            setCurrentTweetId={setCurrentTweetId}
          />
        )}
        {showSearch && <SearchComponent />}
        {showTrending && <TrendingTab />}
        {showDownload && <DownloadTab />}
      </SidePanelContainer>
    </>
  );
};

export default SidePanelTabs;
