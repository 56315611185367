import React from "react";
import "../AreaTable.scss";
import Table from "./Table";
import AddOrg from "../add-organization/AreaTable";

const AreaTable = () => {
  return (
    <>
      <section className="content-area-table">
        <AddOrg />
        <Table />
      </section>
    </>
  );
};

export default AreaTable;
