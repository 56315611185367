import AreaBarChart from "./AreaBarChart";
import AreaProgressChart from "./AreaProgressChart";

const AreaCharts = () => {
  return (
    <>
      <section className="content-area-charts">
        <AreaBarChart />
      </section>
      <section className="content-area-charts">
        <AreaProgressChart />
      </section>
    </>
  );
};

export default AreaCharts;
