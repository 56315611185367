import { Routes, Route, Navigate } from "react-router-dom";
import Users from "./components/table-components/Users/AreaTable";
import Dashboard from "./screens/Dashboard";
import BusinessDashboard from "./screens/BusinessDashboard";
import PageNotFound from "./screens/PageNotFound";
import Orgs from "./components/table-components/Organizations/AreaTable";
import Channels from "./components/table-components/Channels/Table";
import Tags from "./components/table-components/Tags/Table";
import ChannelRequests from "./components/table-components/channel-requests/AreaTable";
import BusinessUsers from "./components/table-components/BusinessUsers/Table";
import Payments from "./components/table-components/Payments/Table";
import Subscriptions from "./components/table-components/subscriptions/Table";
import Bundles from "./components/table-components/bundles/AreaTable";
import { useAuth } from "../components/authentication/auth-context/AuthContext";
import { Outlet } from "react-router-dom";
import Sidebar from "./components/sidebar/Sidebar";
import "./App.scss";

const AdminRoutes = () => {
  const { isAuthenticated, role } = useAuth();

  if (!isAuthenticated || role === "user") {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="admin">
      <Routes>
        <Route
          element={
            <main className="page-wrapper">
              <Sidebar />
              <div className="content-wrapper">
                <Outlet />
              </div>
            </main>
          }
        >
          {/* Only Admins can access all the routes */}
          {role === "admin" && (
            <>
              <Route path="/" element={<Dashboard />} />
              <Route path="/users" element={<Users />} />
              <Route path="/organizations" element={<Orgs />} />
              <Route path="/channels" element={<Channels />} />
              <Route path="/tags" element={<Tags />} />
              <Route path="/channel-requests" element={<ChannelRequests />} />
              <Route path="/payments" element={<Payments />} />
              <Route path="/subscriptions" element={<Subscriptions />} />
              <Route path="/bundles" element={<Bundles />} />
            </>
          )}

          {/* Business Users can only access Business Users route */}
          {role === "business" && (
            <>
              <Route path="/business-users" element={<BusinessUsers />} />
              <Route
                path="/business-dashboard"
                element={<BusinessDashboard />}
              />
            </>
          )}

          {/* Page Not Found for unknown paths */}
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </div>
  );
};

export default AdminRoutes;
