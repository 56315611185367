import { post } from "../../../global/globalUtils";

export const loginUser = async (formData) => {
  const { email, password } = formData;

  const requestBody = {
    email,
    password,
  };

  try {
    const response = await post("/auth/login", requestBody);
    const result = await response.json();
    if (response.ok) {
      return { success: true, data: result };
    } else {
      console.error("login Error:", result.message);
      return { success: false, message: result.message || "Login failed" };
    }
  } catch (error) {
    console.error("Network Error:", error);
    return { success: false, message: error.message || "Network Error" };
  }
};

export const forgotPassword = async (email, setLoading) => {
  if (!email) {
    console.error("Email is required");
    return { success: false, message: "Email is required" };
  }

  // Validate email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    console.error("Invalid email address");
    return { success: false, message: "Invalid email address" };
  }

  try {
    setLoading(true);

    const response = await post("/auth/forgot-password", { email: email });
    const data = await response.json();

    if (!response.ok) {
      console.error("Failed to send reset password email: ", data.message);
      return {
        success: false,
        message: data.message || "Failed to send email",
      };
    }

    if (data.status === "success") {
      return { success: true, message: "Email sent successfully" };
    } else {
      console.error("Failed to send reset password email: ", data.message);
      return {
        success: false,
        message: data.message || "Failed to send email",
      };
    }
  } catch (error) {
    console.error("Error sending reset password email: ", error);
    return {
      success: false,
      message: "An unexpected error occurred. Please try again later.",
    };
  } finally {
    setLoading(false);
  }
};
