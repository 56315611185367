import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  CircularProgress,
} from "@mui/material";
import { formatDate, regenerateCode } from "./utils";

const CodePopup = ({ open, onClose, code }) => {
  const [codeData, setCodeData] = useState(code);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCodeData(code);
  }, [code]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Organization Code</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Organization Code</TableCell>
                <TableCell>Code Expires At</TableCell>
                <TableCell>Code Last Regenerated At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{codeData?.code || "N/A"}</TableCell>
                <TableCell>
                  {formatDate(codeData?.code_expiry) || "N/A"}
                </TableCell>
                <TableCell>
                  {formatDate(codeData?.regenerated_at) || "N/A"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        {/* Button for regenerating the code */}
        <Button
          onClick={() => {
            regenerateCode(code?.organization_id, setCodeData, setLoading);
          }}
          color="primary"
          disabled={loading}
          variant="contained"
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Regenerate Code"
          )}
        </Button>

        {/* Close Button */}
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CodePopup;
