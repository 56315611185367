import { postAuth } from "../../../../global/globalUtils";

export const requestChannel = async (channels) => {
  try {
    const response = await postAuth("/channel/request", {
      channels,
    });
    const data = await response.json();
    if (data.status === "success") {
      return {
        success: true,
        message: data.message,
      };
    } else {
      return {
        success: false,
        message: data.message,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: "An error occurred while sending the request",
    };
  }
};
