import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import ContentDisplay from "./post-components/ContentDisplay";
import { getPost } from "../localdb/DBUtils";
import { useBookmarks } from "../side-panel/tabs/bookmarks/BookmarkContext";
import { website } from "../../global/globalUtils";
import Spinner from "../../global/loaders/Spinner";
import SharePopup from "../column_popups/PostSharePopup";
import CategoryPopup from "./post-popups/CategoryPopup";
import {
  downloadMedia,
  extractTweetId,
  extractTwitterProfileUrl,
  formatDate,
  formatDateWithAddedHours,
  highlightText,
} from "./utils";
import avatar from "../../assets/images/default-avatar.avif";
import TranslatePopup from "./post-popups/TranslatePopup";
import { useSearch } from "../side-panel/tabs/global-search/SearchContext";
import { useTranslationLoading } from "../column/TranslationLoadingContext";
import { toast } from "sonner";

const HeaderIconsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px; // Adjust the gap as needed
  position: absolute;
  top: 28px;
  right: 10px;
  z-index: 2;
`;

const HeaderIcon = styled.div`
  position: relative;
  display: flex;
  padding: 5px;
  opacity: ${({ $isbookmarked }) => ($isbookmarked === "true" ? 1 : 0)};
  cursor: pointer;
  transition: opacity 0.1s linear;
  &:hover {
    opacity: 1;
  }
`;

const PostContainer = styled.div`
  background-color: ${({ theme }) => theme.column_background};
  border-radius: 0px;
  margin: 0px;
  border-bottom: 1px solid ${({ theme }) => theme.border_color};
  position: relative;
  &:hover ${HeaderIcon} {
    opacity: 1; // Apply to all HeaderIcon components when PostContainer is hovered
  }
`;

const Header = styled.a`
  display: flex;
  padding: 10px 5px 10px 5px;
  font-size: 16px;
  width: 100%;
  align-items: flex-start;
  color: ${({ theme }) => theme.column_text || "black"};
  min-height: 40px;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color 0.1s linear;
  text-decoration: none;
  &:hover {
    background-color: ${({ theme }) => theme.post_hover_background};
  }
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
`;

const ChannelName = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.column_text || "grey"};
  font-weight: 600;
  white-space: nowrap;
`;

const TextBody = styled.div`
  overflow-wrap: break-word;
  position: relative;
  max-height: ${(props) => (props.$isexpanded === "true" ? "none" : "250px")};
  overflow: hidden;
  direction: rtl;

  &:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 10px; // Height of the gradient effect

    color: ${({ theme }) => theme.column_text || "grey"};

    display: ${(props) =>
      props.$isexpanded === "true"
        ? "none"
        : "block"}; // Only show when not expanded
  }
`;

const ReadMoreButton = styled.button`
  background-color: transparent;
  font-size: 14px;
  color: ${({ theme }) => theme.column_text};
  border: none;
  width: 100%;
  padding-top: 5px;
  cursor: pointer;
  display: block;
  text-align: center;
  &:hover {
    background-color: ${({ theme }) => theme.post_hover_background};
  }
`;

const PostTime = styled.div`
  width: 100%;
  display: flex;
  padding: 5px 5px;
  box-sizing: border-box;
  justify-content: space-between;
  font-size: 13px;
`;

const PostBody = styled.div`
  box-sizing: border-box;
  color: ${({ theme }) => theme.column_text || "grey"};
  padding: 5px 5px;
  padding-bottom: 0px;
  cursor: pointer;
  transition: background-color 0.1s linear;
  &:hover {
    background-color: ${({ theme }) => theme.post_hover_background};
  }
`;

const PostUrl = styled.a`
  color: ${({ theme }) => theme.blue};
  display: flex;
  align-items: center;
`;

const SpinnerContainer = styled.div`
  display: flex;
  position: absolute;
  padding: 5px;
  top: 5px;
  z-index: 2;
  right: 10px;
  justify-content: flex-end;
  cursor: pointer;
  transition: opacity 0.1s linear;
  &:hover {
    opacity: 1;
  }
`;

const CenteredSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; // Adjust this value as needed
`;

function Post({
  post,
  column,
  onOpenPopup,
  inputValue,
  is_a_bookmark = false,
  translatedText = null,
  onTranslate,
}) {
  const [isBookmarked, setIsBookmarked] = useState(is_a_bookmark);
  const { bookmarkedPosts, addPostToContext, deleteContextPost, categories } =
    useBookmarks();
  const [isExpanded, setIsExpanded] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const textRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isTranslateModalOpen, setIsTranslateModalOpen] = useState(false);
  const { globalSearch } = useSearch();
  const { translationLoading, setTranslationLoading } = useTranslationLoading();
  const displayText = translatedText || post.text;

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleSharePopup = () => {
    setIsSharePopupOpen(!isSharePopupOpen);
  };

  useEffect(() => {
    const checkContentHeight = () => {
      if (textRef.current) {
        setShowReadMore(textRef.current.scrollHeight > 250);
      }
    };
    checkContentHeight();
    window.addEventListener("resize", checkContentHeight);
    return () => {
      window.removeEventListener("resize", checkContentHeight);
    };
  }, [displayText]); // Rerun when text changes

  useEffect(() => {
    const checkBookmark = async () => {
      try {
        const bookmarkedPost = await getPost(post.post_id);
        if (bookmarkedPost) {
          setIsBookmarked(true);
        } else {
          setIsBookmarked(false);
        }
      } catch (error) {
        console.error("Error checking bookmark status:", error);
      }
    };

    // Run the check only when the specific post's ID changes
    if (post?.post_id) {
      checkBookmark();
    }
  }, [post?.post_id]);

  const handleBookmarkAction = async () => {
    // If multiple categories exist, open the modal for selection
    if (
      categories.length > 1 ||
      (categories.length === 1 && categories[0] !== "uncategorized")
    ) {
      setIsCategoryModalOpen(true);
    } else {
      // Directly add to "uncategorized" if that's the only option
      try {
        await addPostToContext(post, "uncategorized");
        setIsBookmarked(true); // Set the bookmark state after successful addition
      } catch (error) {
        console.error("Error adding bookmark:", error);
      }
    }
  };

  const addBookmark = async (category) => {
    try {
      if (bookmarkedPosts?.length > 50) {
        toast.error("You can only bookmark up to 50 posts at a time.");
        return;
      }

      await addPostToContext(post, category); // Add to context with the selected category
      setIsBookmarked(true); // Set the bookmark state after a category is selected and added
      setIsCategoryModalOpen(false); // Close the modal after successful addition

      // Update all instances of the bookmark icon to 'bookmark' using data-post-id
      const iconElements = document.querySelectorAll(
        `[data-post-id='${post.post_id}']`
      );
      iconElements.forEach((element) => {
        element.textContent = "bookmark"; // Change to the bookmarked icon
      });
    } catch (error) {
      console.error("Error adding bookmark with selected category:", error);
      setIsBookmarked(false); // Revert bookmark state if there was an error

      // If there's an error, ensure all icons are set back to 'bookmark_border'
      const iconElements = document.querySelectorAll(
        `[data-post-id='${post.post_id}']`
      );
      iconElements.forEach((element) => {
        element.textContent = "bookmark_border";
      });
    }
  };

  const removeBookmark = async () => {
    try {
      await deleteContextPost(post.post_id); // Remove from context

      // Verify if the post still exists in the context after deletion
      const isStillBookmarked = await getPost(post.id);
      if (!isStillBookmarked) {
        setIsBookmarked(false); // Update state if the post is no longer bookmarked

        // Update all instances of the bookmark icon to 'bookmark_border' using data-post-id
        const iconElements = document.querySelectorAll(
          `[data-post-id='${post.post_id}']`
        );
        iconElements.forEach((element) => {
          element.textContent = "bookmark_border";
        });
      }
    } catch (error) {
      console.error("Error unbookmarking post:", error);
      setIsBookmarked(true); // Revert if an error occurs
    }
  };

  const mediaUrl =
    post.images && post.images.length > 0
      ? post.images[0]
      : post.videos && post.videos.length > 0
      ? post.videos[0]
      : null;

  return (
    <PostContainer id={`post-${post.id}`}>
      {loading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <HeaderIconsContainer>
          <HeaderIcon
            onClick={isBookmarked ? removeBookmark : handleBookmarkAction}
            $isbookmarked={isBookmarked.toString()}
          >
            <span
              className="material-icons"
              data-post-id={post.post_id}
              title={isBookmarked ? "Remove Bookmark" : "Add Bookmark"}
            >
              {isBookmarked ? "bookmark" : "bookmark_border"}
            </span>
            {isCategoryModalOpen && (
              <CategoryPopup
                isOpen={isCategoryModalOpen}
                onClose={() => setIsCategoryModalOpen(false)}
                addBookmark={addBookmark}
              />
            )}
          </HeaderIcon>

          {(post.images.length > 0 || post.videos.length > 0) && (
            <>
              <HeaderIcon
                onClick={() => {
                  downloadMedia([post.id], setLoading);
                }}
              >
                <span className="material-icons" title="Download Media">
                  download
                </span>
              </HeaderIcon>
            </>
          )}
          {displayText?.length > 0 && (
            <HeaderIcon
              onClick={() => {
                setIsTranslateModalOpen(!isTranslateModalOpen);
              }}
            >
              <span className="material-icons" title="Translate Post">
                translate
              </span>
              {isTranslateModalOpen && (
                <TranslatePopup
                  isOpen={isTranslateModalOpen}
                  onClose={() => setIsTranslateModalOpen(false)}
                  postId={post.id}
                  text={post.text}
                  onTranslate={(language, translatedText) =>
                    onTranslate(post.id, translatedText)
                  }
                  setLoading={setTranslationLoading}
                />
              )}
            </HeaderIcon>
          )}
          <HeaderIcon
            onClick={() => {
              setIsSharePopupOpen(!isSharePopupOpen);
            }}
          >
            <span className="material-icons" title="Share Post">
              share
            </span>
          </HeaderIcon>
        </HeaderIconsContainer>
      )}
      <Header
        target="_blank"
        href={
          post.source_name === "Telegram"
            ? "https://t.me/s/" + post.sender_username
            : post.source_name === "Twitter"
            ? extractTwitterProfileUrl(post.post_url)
            : "#"
        }
      >
        <Avatar
          src={
            post.profile_picture_url
              ? post.profile_picture_url
              : post.isApi
              ? post.image
              : `${website}/${post.image}`
          }
          alt="avatar"
          onError={(e) => {
            e.target.onerror = null; // Prevent infinite loop if fallback image also fails
            e.target.src = avatar; // Set fallback image
          }}
        />
        <HeaderText>
          <ChannelName>{post.chat_title}</ChannelName>
        </HeaderText>
      </Header>
      <PostBody
        onClick={() => {
          onOpenPopup(
            post.sender_username,
            post.post_id,
            post.source_name,
            extractTweetId(post.post_url)
          );
        }}
      >
        <ContentDisplay
          showPreview={column.get_preview}
          showImage={column.get_image}
          showVideo={column.get_video}
          preview_title={post.preview_title}
          preview_image={post.preview_image}
          preview_url={post.preview_url}
          preview_description={post.preview_description}
          images={post.images}
          videos={post.videos}
        />
        <TextBody
          ref={textRef}
          $isexpanded={isExpanded.toString()}
          $showgradient={(!isExpanded).toString() && showReadMore.toString()}
          className="post-text"
        >
          {translationLoading[post.id] ? (
            <CenteredSpinnerContainer>
              <Spinner />
            </CenteredSpinnerContainer>
          ) : (
            // post.text || highlightText(text, globalSearch, inputValue)
            highlightText(displayText, globalSearch, inputValue)
          )}
        </TextBody>
      </PostBody>
      {showReadMore && (
        <ReadMoreButton onClick={toggleReadMore}>
          {isExpanded ? (
            <span className="material-symbols-outlined">keyboard_arrow_up</span>
          ) : (
            <span className="material-symbols-outlined">
              keyboard_arrow_down
            </span>
          )}
        </ReadMoreButton>
      )}
      <PostTime>
        <PostUrl target="_blank" href={post.post_url}>
          <img
            src={website + post.source_logo}
            alt="Source Logo"
            style={{ width: "20px", height: "20px", marginRight: "5px" }}
          />
          {post.sender_username}/{post.post_id}
        </PostUrl>

        {post.isApi
          ? formatDateWithAddedHours(post.timestamp)
          : post.isApi
          ? formatDateWithAddedHours(post.timestamp)
          : formatDate(post.timestamp)}
      </PostTime>
      <SharePopup
        isOpen={isSharePopupOpen}
        togglePopup={toggleSharePopup}
        text={displayText}
        mediaUrl={mediaUrl}
      />
    </PostContainer>
  );
}

export default Post;
