import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import "../../assets/css/pricing.css";
import { fetchBundles } from "./utils";

const Pricing = () => {
  const [billPlan, setBillPlan] = useState("monthly");
  const [bundles, setBundles] = useState([]);

  useEffect(() => {
    fetchBundles(setBundles);
  }, []);

  return (
    <div className="afj-container">
      {/* Top Right Buttons */}
      <div className="afj-auth-buttons">
        <a
          href="https://teledeck.news/contact"
          target="_blank"
          rel="noopener noreferrer"
          className="afj-login"
        >
          Contact Us
        </a>
        <Link to="/" className="afj-login">
          Go Back
        </Link>
      </div>

      <main className="afj-main">
        {/* Intro Section */}
        <motion.div
          className="afj-intro"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1 className="afj-header-text">
            Our <span className="afj-highlight">plans</span> for your{" "}
            <span className="afj-highlight">strategies</span>
          </h1>
          <p>
            See below our main plans for your business, for your agency, or for
            your personal use.
          </p>
        </motion.div>

        {/* Plan Switch */}
        <div className="afj-plan-switch">
          <span>Bill Monthly</span>
          <motion.button
            className="afj-toggle-button"
            onClick={() =>
              setBillPlan(billPlan === "monthly" ? "annually" : "monthly")
            }
            whileTap={{ scale: 0.9 }}
          >
            <div className="afj-toggle-track"></div>
            <motion.div
              className={`afj-toggle-thumb ${
                billPlan === "annually" ? "afj-toggle-thumb-active" : ""
              }`}
              layout
              transition={{ type: "spring", stiffness: 300 }}
            ></motion.div>
          </motion.button>
          <span>Bill Annually</span>
        </div>

        {/* Plans Section */}
        <motion.div
          className="afj-plans"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          {bundles.map((bundle, index) => (
            <motion.section
              key={index}
              className="afj-plan"
              whileHover={{
                scale: 1.05,
                boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.15)",
              }}
              transition={{ duration: 0.3 }}
            >
              <div className="afj-price">
                {bundle.price_per_user_monthly &&
                bundle.price_per_user_yearly ? (
                  <>
                    <span className="afj-price-value">
                      $
                      {billPlan === "monthly"
                        ? bundle.price_per_user_monthly
                        : bundle.price_per_user_yearly}
                    </span>
                    <span className="afj-price-period">
                      {billPlan === "monthly"
                        ? "/month per user"
                        : "/year per user"}
                    </span>
                  </>
                ) : (
                  <span className="afj-price-value">Get a Quote</span>
                )}
                <h2>{bundle.display_name}</h2>
                <p>
                  {bundle.max_users
                    ? bundle.max_users === 1
                      ? "1 user"
                      : `Up to ${bundle.max_users} users`
                    : "More than 10 users"}
                </p>
              </div>

              <div className="afj-plan-details">
                <ul className="afj-plan-features">
                  {bundle.features.map((feature, index) => (
                    <li className="afj-feature" key={index}>
                      <svg
                        className="afj-check-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                      {feature.description}
                    </li>
                  ))}
                </ul>
              </div>

              <motion.a
                href={"https://teledeck.news/contact"}
                target="_blank"
                rel="noopener noreferrer"
                className={`afj-cta-button ${
                  bundle.price_per_user_monthly && bundle.price_per_user_yearly
                    ? "afj-cta-button-primary"
                    : "afj-cta-button-secondary"
                }`}
                whileHover={{ scale: 1.1 }}
              >
                {bundle.price_per_user_monthly && bundle.price_per_user_yearly
                  ? `Get Started`
                  : "Contact Us"}
              </motion.a>
            </motion.section>
          ))}
        </motion.div>
      </main>
    </div>
  );
};

export default Pricing;
