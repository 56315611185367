import React, { useState, useEffect } from "react";
import StyledButton from "./tag-components/Button";
import {
  Button as MuiButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import styled from "styled-components";
import {
  fetchData,
  deleteTag,
  createTag,
  updateTag,
  downloadCSV,
  handleFileChange,
} from "./utils";
import { formatToDatetimeLocal } from "../Payments/utils";

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center; // Center horizontally
  align-items: center; // Center vertically if needed
  max-width: 100%; // Ensures it fits within the table cell
`;

const TableStyle = styled.table`
  width: 100%; // Ensures the table takes full width
  th,
  td {
    text-align: center; // Centers text in all headers and cells
    vertical-align: middle; // Centers content vertically in cells
  }
`;

const PaginationControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  & > button {
    margin: 0 5px;
  }
  .page-info {
    margin: 0 10px;
    font-weight: bold;
  }
`;

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px 10px 10px;
  background-color: #f5f5f5;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: 10px;
  width: 100%;
`;

const SearchInput = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex: 1;
  margin-right: 20px;
  outline: none;
  max-width: 400px;

  &:focus {
    border-color: #007bff;
  }
`;

const TagTable = () => {
  const [tags, setTags] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [itemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editTag, setEditTag] = useState({
    id: 0,
    name: "",
    channels: [],
    created_at: null,
  });

  const handleEditChange = (e) => {
    setEditTag({
      ...editTag,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditSubmit = async () => {
    if (isEditMode) {
      await updateTag(editTag.id, editTag);
    } else {
      await createTag(editTag);
    }
    setEditModalOpen(false);
    fetchData(
      currentPage,
      itemsPerPage,
      setLoading,
      setTags,
      setCount,
      searchTerm
    );
  };

  const handleCreateClick = () => {
    setEditTag({
      id: 0,
      name: "",
      channels: [],
      created_at: null,
    });
    setIsEditMode(false);
    setEditModalOpen(true);
  };

  const handleEditClick = (item) => {
    setEditTag({
      id: item.id,
      name: item.name,
      channels: item.channels,
      created_at: formatToDatetimeLocal(item.created_at),
    });
    setIsEditMode(true);
    setEditModalOpen(true);
  };

  useEffect(() => {
    fetchData(
      currentPage,
      itemsPerPage,
      setLoading,
      setTags,
      setCount,
      searchTerm
    );
  }, [currentPage, itemsPerPage, searchTerm]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(count / itemsPerPage);

  return (
    <section className="content-area-table">
      <Dialog
        open={editModalOpen}
        fullWidth={true}
        maxWidth="sm"
        onClose={() => setEditModalOpen(false)}
      >
        <DialogTitle>{isEditMode ? "Edit Tag" : "Create Tag"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Tag Name"
            type="text"
            fullWidth
            value={editTag.name}
            onChange={handleEditChange}
            name="name"
          />
          {isEditMode ? (
            <>
              <Button
                onClick={() => downloadCSV(editTag.channels, editTag)}
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
              >
                Download Current Channels CSV
              </Button>
              <InputLabel sx={{ mt: 2 }}>
                Upload the new version of the CSV below
              </InputLabel>
            </>
          ) : (
            <>
              <InputLabel sx={{ mt: 2 }}>
                Upload a CSV with the channel usernames to be associated with
                this tag
              </InputLabel>
            </>
          )}
          <input
            type="file"
            accept=".csv"
            onChange={(event) => handleFileChange(event, editTag, setEditTag)}
            style={{ marginTop: "20px" }}
          />
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={() => setEditModalOpen(false)} color="primary">
            Cancel
          </MuiButton>
          <MuiButton onClick={handleEditSubmit} color="primary">
            {isEditMode ? "Save Changes" : "Create Tag"}
          </MuiButton>
        </DialogActions>
      </Dialog>

      <SearchBarContainer>
        <h1>Tags Table</h1>
        <SearchInput
          type="text"
          placeholder="Search Tags By Name..."
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          title="Click Enter to search"
        />
        <MuiButton
          onClick={handleCreateClick}
          variant="contained"
          color="primary"
          sx={{ marginBottom: "10px" }}
        >
          Create New Tag
        </MuiButton>
      </SearchBarContainer>

      <div className="data-table-diagram">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <TableStyle>
              <thead>
                <tr>
                  <th>Tag Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {tags.map((item) => (
                  <tr key={item.id}>
                    <td>{item.name || "N/A"}</td>
                    <td>
                      <ButtonContainer>
                        <StyledButton
                          onClick={() => handleEditClick(item)}
                          variant="contained"
                          bgcolor="#ffc107"
                          hovercolor="#e0a800"
                          textcolor="#000"
                          startIcon={<EditIcon />}
                        >
                          Edit
                        </StyledButton>
                        <StyledButton
                          onClick={() =>
                            window.confirm(
                              "Are you sure you want to delete this tag?"
                            ) && deleteTag(item.id)
                          }
                          variant="contained"
                          bgcolor="red"
                          hovercolor="#cc0000"
                          startIcon={<DeleteIcon />}
                        >
                          Delete
                        </StyledButton>
                      </ButtonContainer>
                    </td>
                  </tr>
                ))}
              </tbody>
            </TableStyle>
            <PaginationControls>
              <StyledButton
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
                variant="contained"
              >
                First
              </StyledButton>
              <StyledButton
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                variant="contained"
              >
                Previous
              </StyledButton>
              <span className="page-info">
                Page {currentPage} of {totalPages}
              </span>
              <StyledButton
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                variant="contained"
              >
                Next
              </StyledButton>
              <StyledButton
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
                variant="contained"
              >
                Last
              </StyledButton>
              <span>
                {count} {count === 1 ? "tag" : "tags"} in total
              </span>
            </PaginationControls>
          </>
        )}
      </div>
    </section>
  );
};

export default TagTable;
