import React, { useState } from "react";
import { ContentRow } from "../components/ContentRow";
import { updateMyProfile } from "./Profile_utils";
import "../../../../assets/css/edtitableContentRow.css";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { toast } from "sonner";

export const EditableContentRow = ({ title, value, attributeKey }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const [originalValue, setOriginalValue] = useState(value);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleClose = () => {
    setIsEditing(false);
    setCurrentValue(originalValue);
  };

  const handleChange = (event) => {
    const newValue = event.target ? event.target.value : event;
    setCurrentValue(newValue);
  };

  const handleUpdate = async () => {
    if (currentValue === originalValue) {
      setIsEditing(false);
      return;
    }

    try {
      const response = await updateMyProfile({ [attributeKey]: currentValue });
      if (response.status === "success") {
        setOriginalValue(currentValue);
      }
    } catch (error) {
      toast.error("Failed to update profile");
    } finally {
      setIsEditing(false);
    }
  };

  return (
    <ContentRow title={title}>
      {isEditing ? (
        <div className="input-wrapper">
          {title === "Phone Number" ? (
            <PhoneInput
              required
              fullWidth
              name="phoneNumber"
              label="Phone Number"
              type="number"
              id="number"
              autoComplete="number"
              value={currentValue}
              onChange={handleChange}
              inputClassName="number-control"
              className="phone-input-2"
            />
          ) : (
            <input
              className="editable-input"
              value={currentValue}
              onChange={handleChange}
            />
          )}
          <span onClick={handleUpdate} className="material-icons check-button">
            check
          </span>
          <span onClick={handleClose} className="material-icons close-button">
            close
          </span>
        </div>
      ) : (
        <>
          {currentValue || "Not Provided"}{" "}
          <span onClick={handleEdit} className="material-icons edit-button">
            edit
          </span>
        </>
      )}
    </ContentRow>
  );
};
