import {
  getAuth,
  deleteAuth,
  postAuth,
  putAuth,
} from "../../../../global/globalUtils";
import { toast } from "sonner";
import Papa from "papaparse";

export const fetchData = async (
  currentPage,
  itemsPerPage,
  setLoading,
  setTags,
  setCount,
  searchTerm
) => {
  setLoading(true);
  try {
    const response = await getAuth(
      `/tag/?page=${currentPage}&limit=${itemsPerPage}&search=${searchTerm}`
    );
    const responseData = await response.json();
    setTags(responseData.data);
    setCount(responseData.total);
  } catch (error) {
    console.error("Failed to fetch tags:", error);
    setTags([]);
  } finally {
    setLoading(false);
  }
};

export const createTag = async (tag) => {
  try {
    const response = await postAuth("/tag/create", {
      name: tag.name,
      channels: tag.channels,
    });
    await response.json();
    if (response.ok) {
      toast.success("Tag created successfully");
    } else {
      toast.error("Failed to create tag");
    }
    return response.ok;
  } catch (error) {
    console.error("Failed to create tag:", error);
    toast.error("An Error occurred. Please try again later.");
    return false;
  }
};

export const updateTag = async (id, tag) => {
  try {
    const response = await putAuth(`/tag/${id}`, tag);
    await response.json();
    if (response.ok) {
      toast.success("Tag updated successfully");
    } else {
      toast.error("Failed to update tag");
    }
    return response.ok;
  } catch (error) {
    console.error("Failed to update tag:", error);
    toast.error("An Error occurred. Please try again later.");
    return false;
  }
};

export const deleteTag = async (id, tags, setTags) => {
  try {
    const response = await deleteAuth(`/tag/${id}`);
    await response.json();
    if (response.ok) {
      alert("Tag deleted successfully");
      const updatedTags = tags.filter((tag) => tag.id !== id);
      setTags(updatedTags);
    }
  } catch (error) {
    console.error("Failed to delete tag:", error);
  }
};

export const downloadCSV = (channels, editTag) => {
  const csvContent = "data:text/csv;charset=utf-8," + channels.join("\n");
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${editTag.name}_channels.csv`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const handleFileChange = (event, editTag, setEditTag) => {
  const file = event.target.files[0];
  if (file) {
    Papa.parse(file, {
      complete: (results) => {
        // Assuming CSV contains one column with channel usernames
        const channels = results.data
          .map((row) => row[0]) // Extract the first element of each row
          .filter((channel) => channel && channel.trim() !== ""); // Filter out empty strings
        setEditTag({ ...editTag, channels });
      },
      header: false,
    });
  }
};
