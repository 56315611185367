import { getAuth, postAuth } from "../../../global/globalUtils";
import Color from "color";

// getting map posts
export const getPosts = async (
  hasImages,
  hasVideos,
  hasText,
  search,
  categories,
  countries,
  currentCenter,
  currentRadius,
  startDate,
  endDate,
  isFreeView,
  setMapDataLoading
) => {
  try {
    setMapDataLoading(true);

    const params = {
      hasImages,
      hasVideos,
      hasText,
      search,
      categories,
      startDate,
      endDate,
    };

    if (isFreeView) {
      // If isFreeView is true, add center and radius
      params.center = currentCenter;
      params.radius = currentRadius;
    } else {
      // If isFreeView is false, add countries
      params.countries = countries;
    }

    // Make an HTTP POST request to the server
    const response = await postAuth("/location/map-data", params);

    // If the request is successful, parse the JSON response
    if (response.ok) {
      const data = await response.json();
      // Update the state with the fetched data
      return data.data;
    } else {
      // Handle errors, e.g., when response is not ok
      const errorData = await response.json();
      console.error("Failed to fetch posts:", errorData);
      throw new Error("Failed to fetch posts: " + errorData.message);
    }
  } catch (error) {
    // Log and potentially handle errors e.g., showing an error message to the user
    console.error("Error fetching posts:", error);
  } finally {
    setMapDataLoading(false);
  }
};

// get full sidebar posts
export const getFullPosts = async (
  hasImages,
  hasVideos,
  hasText,
  limit,
  page,
  selectedCategories,
  countries,
  startDate,
  endDate,
  search,
  setFullPostLoading
) => {
  try {
    setFullPostLoading(true);
    const response = await postAuth(`/location/full-posts`, {
      limit,
      page,
      selectedCategories,
      countries,
      startDate,
      endDate,
      search,
      hasImages,
      hasVideos,
      hasText,
    });

    // Handle the response
    if (response.ok) {
      const data = await response.json();
      return data.data;
    } else {
      const errorData = await response.json();
      console.error("Failed to fetch posts:", errorData);
    }
  } catch (error) {
    console.error("Error fetching posts:", error);
  } finally {
    setFullPostLoading(false);
  }
};

// fetch categories
export const getCategories = async () => {
  try {
    const response = await getAuth("/post/categories");

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error fetching categories:", error);
  }
};

// process them to be used in the dropdown
export const fetchCategories = async (setCategories) => {
  try {
    const fetchedCategories = await getCategories();
    setCategories(fetchedCategories);
  } catch (error) {
    console.error("Failed to fetch categories:", error);
  }
};

// extract category object by id
export const getCategoryByCid = (categories, cid) => {
  const category = categories.find((option) => option.id === cid);
  if (category) {
    return category;
  }

  return { title: "conflict", icon: "/icon/conflict.jpg" };
};

let currentHighlightedPostId = null; // Variable to track the currently highlighted post

export const scrollToAndHighlightPost = (postId) => {
  const elementId = `post-${postId}`;
  const element = document.getElementById(elementId);

  if (element) {
    // Check if the element is already in view
    const rect = element.getBoundingClientRect();
    const isInView =
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth);

    if (!isInView) {
      // Smoothly scroll to the post element
      element.scrollIntoView({
        behavior: "smooth",
        block: "center", // Adjusted to "center" for better visibility
        inline: "center", // Adjusted to "center" for better visibility
      });
    }

    // Remove the border from the previously highlighted post
    if (currentHighlightedPostId) {
      const previousElement = document.getElementById(
        `post-${currentHighlightedPostId}`
      );
      if (previousElement) {
        previousElement.style.borderLeft = "none"; // Reset border
      }
    }

    // Apply a temporary styling effect to highlight the post
    element.style.transition = "transform 0.5s, background-color 0.5s";
    element.style.backgroundColor = "rgba(255, 215, 0, 0.1)"; // Gold color overlay
    element.style.transform = "scale(1.05)"; // Slightly increase size
    element.style.borderLeft = "3px solid rgb(30, 40, 54)"; // Permanent border for current post

    // Update the current highlighted post ID
    currentHighlightedPostId = postId;

    setTimeout(() => {
      element.style.backgroundColor = "transparent"; // Reset background color
      element.style.transform = "scale(1)"; // Reset scale
    }, 1500); // Duration for highlight effect
  }
};

export const formatDateOrTime = (isoDateString) => {
  const date = new Date(isoDateString);
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const isToday = date >= today;
  const isYesterday = date >= yesterday && date < today;

  // Use toLocaleString with UTC to keep the time the same as given
  const timeString = date.toLocaleString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: "UTC",
  });
  const dateString = date.toLocaleDateString("en-US", {
    year: "2-digit", // Show last two digits of the year
    month: "numeric",
    day: "numeric",
    timeZone: "UTC",
  });

  if (isToday) {
    return `${timeString}`;
  } else if (isYesterday) {
    return `Yesterday at ${timeString}`;
  } else {
    return `${dateString}-${timeString}`;
  }
};

export const createCustomIcon = (L, website, category) => {
  if (!category) {
    return L.icon({
      iconUrl: `${website}/icon/conflict.jpg`,
      iconSize: [45, 45], // Slightly increased size
      iconAnchor: [22.5, 45], // Adjusted anchor to match new size
      popupAnchor: [1, -34],
    });
  }

  return L.icon({
    iconUrl: `${website}${category?.icon}`,
    iconSize: [45, 45], // Slightly increased size
    iconAnchor: [22.5, 45], // Adjusted anchor to match new size
    popupAnchor: [1, -34],
  });
};

export const getAddress = async (lat, lon, setCountryCode, setLoading) => {
  const url = "https://python.data.teledeck.news/get_cc";

  const payload = {
    lng: lon.toString(),
    lat: lat.toString(),
  };

  try {
    setLoading(true);
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    setCountryCode(data.country_code);
  } catch (error) {
    console.error("Error fetching address:", error);
  } finally {
    setLoading(false);
  }
};

const postCache = new Map();

export const handleMarkerClick = async (postId, setNews) => {
  try {
    let post;
    if (postCache.has(postId)) {
      post = postCache.get(postId);
    } else {
      post = await getPostById(postId);
      postCache.set(postId, post);
    }

    const updatePosts = (posts) => {
      const postExists = posts.some((p) => p.id === postId);
      return postExists ? posts : [post, ...posts];
    };

    setNews((prevPosts) => updatePosts(prevPosts));

    setTimeout(() => {
      scrollToAndHighlightPost(postId);
    }, 300);
  } catch (error) {
    console.error("Error during marker click handling:", error);
  }
};

// get a single post by id
export const getPostById = async (id) => {
  try {
    const response = await getAuth(`/location/post-info/${id}`);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error fetching categories:", error);
  }
};

// threshold for when to call the API
const calculateThresholds = (zoomLevel) => {
  let distanceThreshold, radiusPercentageThreshold;

  if (zoomLevel <= 5) {
    // Global view
    distanceThreshold = 800000; // 100 kilometers
    radiusPercentageThreshold = 0.5; // 50%
  } else if (zoomLevel <= 10) {
    // Country view
    distanceThreshold = 50000; // 300 kilometers
    radiusPercentageThreshold = 0.3; // 30%
  } else if (zoomLevel <= 15) {
    // City view
    distanceThreshold = 10000; // 10 kilometer
    radiusPercentageThreshold = 0.2; // 20%
  } else {
    // Street view
    distanceThreshold = 1000; // 1 kilometer
    radiusPercentageThreshold = 0.1; // 10%
  }

  return { distanceThreshold, radiusPercentageThreshold };
};

export const shouldFetch = (
  oldCenter,
  oldRadius,
  newCenter,
  newRadius,
  currentZoom
) => {
  const { distanceThreshold, radiusPercentageThreshold } =
    calculateThresholds(currentZoom);
  const centerChanged = oldCenter.distanceTo(newCenter) > distanceThreshold;
  const radiusChanged =
    Math.abs(oldRadius - newRadius) / oldRadius > radiusPercentageThreshold;
  return centerChanged || radiusChanged;
};

export const adjustColorForMap = (hexColor) => {
  try {
    const color = Color(hexColor);
    const brightness = color.luminosity();

    // If brightness is too high, darken the color and reduce saturation
    if (brightness > 0.7) {
      return color.darken(0.5).desaturate(0.3).hex();
    }

    // If not too bright, return the color as is
    return hexColor;
  } catch (e) {
    console.error("Error adjusting color:", e);
    return hexColor; // Fallback to original color
  }
};
