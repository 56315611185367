import { toast } from "sonner";
import {
  postAuth,
  getAuth,
  deleteAuth,
  putAuth,
} from "../../../../global/globalUtils";
import Swal from "sweetalert2";

export function formatDate(apiDate) {
  const date = new Date(apiDate);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return date.toLocaleDateString(undefined, options);
}

export const deleteUser = async (id, filteredData, setFilteredData) => {
  try {
    const response = await deleteAuth(`/dashboard/user/${id}`);
    await response.json();
    if (response.ok) {
      Swal.fire("Success", "User deleted successfully", "success");
      const updatedUsers = filteredData.filter((user) => user.id !== id);
      setFilteredData(updatedUsers);
    }
  } catch (error) {
    console.error("Failed to delete user:", error);
  }
};

const filterQueryParams = (params) => {
  return Object.fromEntries(
    Object.entries(params).filter(
      ([key, value]) => value !== null && value !== undefined && value !== ""
    )
  );
};

export const fetchUsers = async (
  filters,
  setUsers,
  setLoading,
  setCount,
  page,
  limit
) => {
  setLoading(true);
  try {
    const filteredFilters = filterQueryParams(filters);
    const response = await postAuth(`/dashboard/user`, {
      filters: filteredFilters,
      page,
      limit,
    });
    const responseData = await response.json();
    setUsers(responseData.data);
    setCount(responseData.total);
  } catch (error) {
    console.error("Failed to fetch users:", error);
    setUsers([]);
    setCount(0);
  } finally {
    setLoading(false);
  }
};

export const editUserAPI = async (data) => {
  try {
    const response = await putAuth(`/dashboard/user/${data.id}`, data);
    await response.json();
    if (response.ok) {
      toast.success("User updated successfully", {
        position: "top-center",
      });
    } else {
      toast.error("Failed to update user", { position: "top-center" });
    }
  } catch (error) {
    console.error("Failed to update user:", error);
  }
};
