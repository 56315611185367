import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MuiCard from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import ForgotPassword from "./ForgotPassword";
// import { GoogleIcon, FacebookIcon, SitemarkIcon } from "./CustomIcons";
import logo from "../../../../assets/images/teledeck.png";
import whiteLogo from "../../../../assets/images/Teledeck.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth-context/AuthContext";
import { toast } from "sonner";
import { loginUser } from "../utils";
import { CircularProgress } from "@mui/material";
import { useTheme } from "../../../themes/ThemeContext";

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  [theme.breakpoints.up("sm")]: {
    width: "450px",
  },
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

export default function SignInCard() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const { theme } = useTheme();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    try {
      setLoading(true);
      if (emailError || passwordError) {
        event.preventDefault();
        return;
      }
      event.preventDefault();
      if (!validateInputs()) return;

      const formData = {
        email: email,
        password: password,
      };

      const response = await loginUser(formData);
      const data = response.data;
      if (response.success) {
        setAuth({
          isAuthenticated: true,
          email_verified: data.email_verified === 1 ? true : false,
          role: data.role,
          isLoading: false,
          userId: data.userId,
          bundle: data.bundle,
        });
        if (data.email_verified === 1) {
          toast.success("Login Successful");
          navigate("/", { replace: true });
        } else if (data.email_verified === 0) {
          toast.info("Please verify your email address to continue.");
          navigate("/verify-email", { replace: true });
        } else {
          toast.error(
            "Your account is not active. Please check with your admin or contact support."
          );
        }
      } else {
        if (response.message === "Failed to fetch") {
          toast.error(
            "We're currently performing maintenance. Please try again later."
          );
        } else toast.error(`${response.message}`);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const validateInputs = () => {
    const email = document.getElementById("email");
    const password = document.getElementById("password");

    let isValid = true;

    if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
      setEmailError(true);
      setEmailErrorMessage("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage("");
    }

    if (!password.value || password.value.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage("Password must be at least 6 characters long.");
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage("");
    }

    return isValid;
  };

  return (
    <Card variant="outlined">
      <Box
        sx={{
          display: { xs: "flex", justifyContent: "center", mb: 3 },
        }}
      >
        <img
          src={theme === "light" ? logo : whiteLogo}
          alt="Teledeck Logo"
          style={{
            width: "250px",
            marginBottom: "10px",
          }}
        />
      </Box>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 2 }}
      >
        <FormControl>
          <FormLabel htmlFor="email">Email</FormLabel>
          <TextField
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError(false);
              setEmailErrorMessage("");
            }}
            error={emailError}
            helperText={emailErrorMessage}
            id="email"
            type="email"
            name="email"
            placeholder="your@email.com"
            autoComplete="email"
            autoFocus
            required
            fullWidth
            variant="outlined"
            color={emailError ? "error" : "primary"}
          />
        </FormControl>
        <FormControl>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <FormLabel htmlFor="password">Password</FormLabel>
            <Link
              component="button"
              type="button"
              onClick={handleClickOpen}
              variant="body2"
              sx={{ alignSelf: "baseline" }}
            >
              Forgot your password?
            </Link>
          </Box>
          <TextField
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setPasswordError(false);
              setPasswordErrorMessage("");
            }}
            error={passwordError}
            helperText={passwordErrorMessage}
            name="password"
            placeholder="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            autoFocus
            required
            fullWidth
            variant="outlined"
            color={passwordError ? "error" : "primary"}
          />
        </FormControl>
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember me"
        />
        <ForgotPassword open={open} handleClose={handleClose} />
        <Button
          type="submit"
          disabled={loading}
          fullWidth
          variant="contained"
          startIcon={loading && <CircularProgress size={20} />}
          style={{ backgroundColor: "rgb(30, 40, 54)" }}
        >
          {loading ? "" : "Sign In"}
        </Button>
        <Typography sx={{ textAlign: "center" }}>
          Don&apos;t have an account?{" "}
          <span>
            <RouterLink to="/register">Sign up</RouterLink>
          </span>
        </Typography>
      </Box>
      <Divider>or</Divider>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Button fullWidth variant="outlined" onClick={() => navigate("/trial")}>
          Start Your 7 Days Free Trial
        </Button>
      </Box>
    </Card>
  );
}
