import React from "react";
import "../AreaTable.scss";
import Table from "./Table";
import CreateBundleForm from "../add-bundle/AreaTable";

const AreaTable = () => {
  return (
    <>
      <section className="content-area-table">
        <CreateBundleForm />
        <Table />
      </section>
    </>
  );
};

export default AreaTable;
