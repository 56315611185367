import React from "react";
import { ContentRow } from "../components/ContentRow";
import styled from "styled-components";
import { useTheme } from "../../../themes/ThemeContext";

const StyledLink = styled.a`
  color: ${({ $theme }) =>
    $theme === "dark" ? "#90caf9" : "#0056b3"}; /* Color based on theme */
  text-decoration: none;
  border-radius: 4px; /* Rounded corners */
  transition: color 0.3s, background-color 0.3s; /* Transition effects */

  &:hover {
    color: ${({ $theme }) =>
      $theme === "dark"
        ? "#bbdefb"
        : "#003366"}; /* Hover color based on theme */
    text-decoration: underline; /* Underline on hover */
  }
`;

const Contact = () => {
  const { theme } = useTheme();
  return (
    <>
      <ContentRow title={"Phone"}>
        <StyledLink $theme={theme} href="tel:+96170008275">
          +961-70-008-275
        </StyledLink>
      </ContentRow>
      <ContentRow title={"For Inquiries"}>
        <StyledLink $theme={theme} href="mailto:info@teledeck.news">
          info@teledeck.news
        </StyledLink>
      </ContentRow>
      <ContentRow title={"For Support"}>
        <StyledLink $theme={theme} href="mailto:support@teledeck.news">
          support@teledeck.news
        </StyledLink>
      </ContentRow>
    </>
  );
};

export default Contact;
