import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import ContentDisplay from "../../post/post-components/ContentDisplay";
import { useBookmarks } from "../../side-panel/tabs/bookmarks/BookmarkContext";
import { website } from "../../../global/globalUtils";
import { useTheme } from "../../themes/ThemeContext";
import { formatDate, formatDateWithAddedHours } from "../../post/utils";
import avatar from "../../../assets/images/default-avatar.avif";

const PostContainer = styled.div`
  background-color: ${({ theme }) => (theme === "light" ? "#fff" : "#222f3e")};
  border-radius: 0px;
  margin: 0px;
  border-bottom: 1px solid #d3d3d3;
  position: relative;
  &:hover > div:first-child {
    opacity: 1;
  }
`;

const Header = styled.a`
  display: flex;
  padding: 10px 5px 10px 5px;
  font-size: 16px;
  width: 100%;
  align-items: center;
  color: ${({ theme }) => theme.column_text || "black"};
  min-height: 40px;
  position: relative;
  box-sizing: border-box;
  transition: background-color 0.1s linear;
  text-decoration: none;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
`;

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
`;

const ChannelName = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.column_text || "grey"};
  font-weight: 600;
  white-space: nowrap;
`;

const Username = styled.span`
  font-size: 14px;
  margin-right: 10px;
  color: ${({ theme }) => theme.blue || "grey"};
`;

const TextBody = styled.div`
  overflow-wrap: break-word;
  position: relative;
  max-height: ${(props) => (props.$isexpanded === "true" ? "none" : "250px")};
  overflow: hidden;
  direction: rtl;

  &:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 10px; // Height of the gradient effect

    color: ${({ theme }) => theme.column_text || "grey"};

    display: ${(props) =>
      props.$isexpanded === "true"
        ? "none"
        : "block"}; // Only show when not expanded
  }
`;

const ReadMoreButton = styled.button`
  background-color: transparent;
  font-size: 14px;
  color: ${({ theme }) => theme.column_text};
  border: none;
  width: 100%;
  padding-top: 5px;
  cursor: pointer;
  display: block;
  text-align: center;
  &:hover {
    background-color: ${({ theme }) => theme.post_hover_background};
  }
`;

const PostTime = styled.div`
  width: 100%;
  display: flex;
  padding: 5px 5px;
  box-sizing: border-box;
  justify-content: space-between;
  font-size: 13px;
`;

const PostBody = styled.div`
  box-sizing: border-box;
  color: ${({ theme }) => theme.column_text || "grey"};
  padding: 5px 5px;
  padding-bottom: 0px;
  transition: background-color 0.1s linear;
`;

const PostUrl = styled.a`
  color: ${({ theme }) => theme.blue};
  display: flex;
  align-items: center;
`;

const ButtonContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Ensure buttons are centered */
  top: 50%;
  right: 0;
  transform: translate(0px, -50%);
`;

const BaseButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.button_text};
  font-size: 15px;
  width: 40px;
  height: 40px;
  overflow: hidden;
  padding: 0; /* Remove padding for consistent size */
`;

const CopyButton = styled(BaseButton)`
  .icon {
    color: ${({ isSelected }) => (isSelected ? "blue" : "inherit")};
    transition: color 0.3s;
  }
`;

const DeleteButton = styled(BaseButton)``;

const InnerContainer = styled.div`
  padding: 5px;
  max-width: 85%;
`;

function CopyPost({
  post,
  column,
  onOpenPopup,
  is_a_bookmark = false,
  setTextInput,
  setMedia,
  chatContext,
  setChatContext,
  isAllSelected,
}) {
  const { deleteContextPost } = useBookmarks();
  const [isExpanded, setIsExpanded] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const textRef = useRef(null);
  const { theme } = useTheme();

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const checkContentHeight = () => {
      if (textRef.current) {
        setShowReadMore(textRef.current.scrollHeight > 250);
      }
    };
    checkContentHeight();
    window.addEventListener("resize", checkContentHeight);
    return () => {
      window.removeEventListener("resize", checkContentHeight);
    };
  }, [post.text]);

  const handleMediaInsert = () => {
    const mediaUrl = post.images[0] || post.videos[0][0];
    setMedia(mediaUrl);
  };

  const handleButtonClick = () => {
    setChatContext((prevContext) => {
      const isDuplicate = prevContext.some((context) => context.id === post.id);

      if (isDuplicate) {
        // Remove the post from the context
        return prevContext.filter((context) => context.id !== post.id);
      } else {
        // Add the post to the context
        return [...prevContext, { id: post.id, text: post.text }];
      }
    });
  };

  const isSelected = chatContext.some((context) => context.id === post.id);

  return (
    <PostContainer theme={theme}>
      <InnerContainer>
        <Header>
          <Avatar
            src={
              post.profile_picture_url
                ? post.profile_picture_url
                : post.isApi
                ? post.image
                : `${website}/${post.image}`
            }
            alt="avatar"
            onClick={() => {
              window.open("https://t.me/s/" + post.sender_username, "_blank");
            }}
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop if fallback image also fails
              e.target.src = avatar; // Set fallback image
            }}
          />
          <HeaderText>
            <ChannelName>{post.chat_title}</ChannelName>
            <Username>@{post.sender_username}</Username>
          </HeaderText>
        </Header>
        <PostBody
          onClick={() => {
            if (!is_a_bookmark) onOpenPopup(post.sender_username, post.post_id);
          }}
        >
          <ContentDisplay
            showPreview={column.get_preview}
            showImage={column.get_image}
            showVideo={column.get_video}
            preview_title={post.preview_title}
            preview_image={post.preview_image}
            preview_url={post.preview_url}
            preview_description={post.preview_description}
            images={post.images}
            videos={post.videos}
          />
          <TextBody
            ref={textRef}
            $isexpanded={isExpanded.toString()}
            $showgradient={(!isExpanded).toString() && showReadMore.toString()}
          >
            {post.text}
          </TextBody>
        </PostBody>
        {showReadMore && (
          <ReadMoreButton onClick={toggleReadMore}>
            {isExpanded ? (
              <span className="material-symbols-outlined">
                keyboard_arrow_up
              </span>
            ) : (
              <span className="material-symbols-outlined">
                keyboard_arrow_down
              </span>
            )}
          </ReadMoreButton>
        )}
        <PostTime>
          <PostUrl
            target="_blank"
            href={"https://t.me/s/" + post.sender_username + "/" + post.post_id}
          >
            <img
              src={website + post.source_logo}
              alt="Source Logo"
              style={{ width: "20px", height: "20px", marginRight: "5px" }}
            />
            {post.sender_username + "/" + post.post_id}
          </PostUrl>
          {post.isApi
            ? formatDateWithAddedHours(post.timestamp)
            : post.isApi
            ? formatDateWithAddedHours(post.timestamp)
            : formatDate(post.timestamp)}
        </PostTime>
      </InnerContainer>
      <ButtonContainer>
        {post.text && (
          <CopyButton
            title="insert post text as context for the chat"
            onClick={handleButtonClick}
            isSelected={isSelected || isAllSelected}
          >
            <span className="icon material-icons">maps_ugc</span>
          </CopyButton>
        )}
        {(post.images.length > 0 || post.videos.length > 0) && (
          <CopyButton
            title="insert post media url into editor"
            onClick={() => {
              handleMediaInsert();
            }}
          >
            <span className="material-icons">add_photo_alternate</span>
          </CopyButton>
        )}

        {post.text && (
          <CopyButton
            title="insert post text into editor"
            onClick={() => {
              setTextInput((prevText) => `${prevText}\n${post.text}`);
            }}
          >
            <span className="material-icons">input</span>
          </CopyButton>
        )}
        <DeleteButton
          title="remove post from bookmarks"
          onClick={() => {
            deleteContextPost(post.post_id);
          }}
        >
          <span className="material-icons">delete</span>
        </DeleteButton>
      </ButtonContainer>
    </PostContainer>
  );
}

export default CopyPost;
