import React, { useState } from "react";
import styled, { css, keyframes } from "styled-components";
import Appearance from "./tabs/Appearance";
import { handleLogout } from "../authentication/logout/Logout";
import Profile from "./tabs/profile/Profile";
import Contact from "./tabs/contact/Contact";
import General from "./tabs/General";

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const slideIn = keyframes`
  from {
    transform: translateY(20px);
  }
  to {
    transform: translateY(0);
  }
`;

const DialogBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  animation: ${fadeIn} 0.3s ease-out;
`;

const DialogBox = styled.div`
  width: 800px;
  display: flex;
  background: ${({ theme }) => theme.popup_background};
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  animation: ${slideIn} 0.3s ease-out;
`;

const Header = styled.div`
  padding: 20px;
  background: #1e2836;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const Title = styled.h1`
  font-size: 18px;
  margin: 0;
`;

const TabBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
  border-right: 1px solid #ddd;
`;

const Tab = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  border: none;
  background: none;
  cursor: pointer;
  transition: background-color 0.2s;
  color: ${({ theme }) => theme.text};

  &:hover {
    background-color: ${({ theme }) => theme.hoverBackground || "#e0e0e0"};
    color: ${({ theme }) => theme.hoverText || "#333"};
  }

  ${({ active, theme }) =>
    active &&
    css`
      font-weight: 700;
    `}

  ${({ isLogout, theme }) =>
    isLogout &&
    css`
      color: rgb(204, 92, 92);
      .material-icons {
        color: rgb(204, 92, 92);
      }
      &:hover {
        background-color: transparent;
        color: #b71c1c;
        .material-icons {
          color: #990000;
        }
      }
    `}

  .material-icons {
    margin-right: 10px;
  }
`;

const Content = styled.div`
  padding: 15px;
  overflow-y: auto;
  width: 100%;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 20px;

  &:hover {
    color: #bbb;
  }

  &:focus {
    outline: none;
  }
`;

const tabs = [
  { label: "General", icon: "settings", content: <General /> },
  { label: "Appearance", icon: "palette", content: <Appearance /> },
  { label: "Profile", icon: "manage_accounts", content: <Profile /> },
  // {
  //   label: "Subscription",
  //   icon: "subscriptions",
  //   content: "Subscription Details",
  // },
  { label: "Contact", icon: "perm_phone_msg", content: <Contact /> },
  {
    label: "Logout",
    icon: "logout",
    content: null,
    isLogout: true,
  },
];

function SettingsDialog({ open, onClose }) {
  const [activeTab, setActiveTab] = useState(0);

  if (!open) return null;

  return (
    <DialogBackdrop onClick={onClose}>
      <DialogBox onClick={(e) => e.stopPropagation()}>
        <Header>
          <Title>Settings</Title>
          <CloseButton onClick={onClose}>✕</CloseButton>
        </Header>
        <ContentContainer>
          <TabBar>
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                active={activeTab === index}
                isLogout={tab.isLogout}
                onClick={() =>
                  tab.label === "Logout" ? handleLogout() : setActiveTab(index)
                }
              >
                <span className="material-icons">{tab.icon}</span>
                {tab.label}
              </Tab>
            ))}
          </TabBar>
          <Content>{tabs[activeTab].content}</Content>
        </ContentContainer>
      </DialogBox>
    </DialogBackdrop>
  );
}

export default SettingsDialog;
