import { post, postAuth } from "../../../global/globalUtils";

export const registerUser = async (formData) => {
  try {
    const url = `/auth/register`;

    const response = await post(url, formData);

    const result = await response.json();

    if (response.ok) {
      return {
        success: true,
        data: result,
      };
    } else {
      return {
        success: false,
        message: result.message || "Unknown error occurred",
      };
    }
  } catch (error) {
    return {
      success: false,
      message: "An error occurred. Please try again later.",
    };
  }
};

export const getOrgs = async () => {
  try {
    const url = `/organizations/names`;

    const response = await postAuth(url);

    const result = await response.json();

    if (response.ok) {
      return {
        success: true,
        data: result.data,
      };
    } else {
      console.error("Failed to get organizations:", result.message);
      return {
        success: false,
        message: result.message || "Unknown error occurred",
      };
    }
  } catch (error) {
    console.error("Network Error:", error);

    return {
      success: false,
      message: "Please check your connection and try again.",
    };
  }
};
