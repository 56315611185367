const DB_NAME = "TelegramDeckDB";
const DB_VERSION = 9;
let dbInstance = null;

function openDb() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onerror = (event) => {
      console.error("Database error: " + event.target.errorCode);
      reject("Database error: " + event.target.errorCode);
    };

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      let bookmarkedStore;

      // Create object stores if they don't exist
      if (!db.objectStoreNames.contains("columns")) {
        db.createObjectStore("columns", { keyPath: "id" });
      }
      if (!db.objectStoreNames.contains("channels")) {
        db.createObjectStore("channels", { keyPath: "id" });
      }

      // Handle "bookmarked_posts" store creation or migration
      if (!db.objectStoreNames.contains("bookmarked_posts")) {
        // Create new structure if it doesn't exist
        bookmarkedStore = db.createObjectStore("bookmarked_posts", {
          keyPath: "category",
        });
      } else {
        // If store exists, check if it's the old structure by checking its keyPath
        bookmarkedStore =
          event.target.transaction.objectStore("bookmarked_posts");

        // Check if the existing store uses 'post_id' as the keyPath (old structure)
        if (bookmarkedStore.keyPath === "post_id") {
          const oldStoreRequest = bookmarkedStore.getAll();
          oldStoreRequest.onsuccess = (event) => {
            const allPosts = event.target.result;

            // Migrate all posts into a single "uncategorized" category
            const uncategorizedPosts = allPosts.map((post) => ({
              ...post,
              bookmark_category: "uncategorized",
            }));

            // Delete the old store and create a new one with the correct structure
            db.deleteObjectStore("bookmarked_posts");

            // Create the new structure with 'category' as the keyPath
            bookmarkedStore = db.createObjectStore("bookmarked_posts", {
              keyPath: "category",
            });

            // Store the migrated posts under the "uncategorized" category
            bookmarkedStore.transaction.oncomplete = () => {
              const newTransaction = db.transaction(
                "bookmarked_posts",
                "readwrite"
              );
              const newStore = newTransaction.objectStore("bookmarked_posts");

              newStore.put({
                category: "uncategorized",
                bookmarked_posts: uncategorizedPosts,
              });

              newTransaction.onerror = (event) => {
                console.error(
                  "Error during migration transaction:",
                  event.target.error
                );
              };
            };
          };

          oldStoreRequest.onerror = (event) => {
            console.error(
              "Error accessing old structure data:",
              event.target.error
            );
          };
        }
      }
    };

    request.onsuccess = (event) => {
      dbInstance = event.target.result;
      resolve(dbInstance);
    };
  });
}

function withStore(mode, callback) {
  return new Promise((resolve, reject) => {
    openDb()
      .then((db) => {
        const transaction = db.transaction(
          ["columns", "channels", "bookmarked_posts"],
          mode
        );
        transaction.oncomplete = () => resolve();
        transaction.onerror = (event) =>
          reject("Transaction failed: " + event.target.error);

        callback({ db: db, transaction: transaction });
      })
      .catch((error) => {
        console.error("Failed to open DB: ", error);
        reject(error);
      });
  });
}

function addPost(post, category = "uncategorized") {
  // Check if id is valid
  if (!post.post_id) {
    console.error("Invalid post.post_id, cannot add post:", post);
    return Promise.resolve(false); // Return false if id is invalid
  }

  return new Promise((resolve, reject) => {
    withStore("readwrite", ({ transaction }) => {
      const store = transaction.objectStore("bookmarked_posts");

      // Retrieve the category object or initialize it if it doesn't exist
      const checkRequest = store.get(category);

      checkRequest.onsuccess = () => {
        // If the category doesn't exist, initialize an empty array
        let categoryData = checkRequest.result || {
          category,
          bookmarked_posts: [],
        };

        // Check if the post already exists in the category's posts
        const postExists = categoryData.bookmarked_posts.some(
          (existingPost) => existingPost.post_id === post.post_id
        );

        if (!postExists) {
          // Add the new post to the array of bookmarked posts
          categoryData.bookmarked_posts.push({
            ...post,
            bookmark_category: category, // Add the category to the post
          });

          // Store the updated category data (no separate key provided)
          const putRequest = store.put(categoryData); // Store the full object including 'category' field

          putRequest.onsuccess = () => {
            resolve(true); // Post added successfully
          };

          putRequest.onerror = (e) => {
            console.error(
              "Error storing updated bookmarked posts:",
              e.target.error
            );
            reject(e.target.error);
          };
        } else {
          resolve(false); // Post already exists, return false
        }
      };

      checkRequest.onerror = (e) => {
        console.error("Error checking for category existence:", e.target.error);
        reject(e.target.error);
      };
    }).catch((error) => {
      console.error("Transaction failed:", error);
      reject(error); // Reject the promise on transaction error
    });
  });
}

// Function to get a post by postId from any category
function getPost(postId) {
  return new Promise((resolve, reject) => {
    withStore("readonly", ({ transaction }) => {
      const store = transaction.objectStore("bookmarked_posts");
      const request = store.getAll();
      request.onsuccess = () => {
        const results = request.result;
        for (const category of results) {
          if (Array.isArray(category.bookmarked_posts)) {
            const foundPost = category.bookmarked_posts.find(
              (post) => post.post_id === postId
            );
            if (foundPost) {
              resolve(foundPost);
              return;
            }
          }
        }
        resolve(null); // Post not found in categories, resolve with null
      };
      request.onerror = () => reject("Failed to get post: " + request.error);
    });
  });
}

function createCategory(category) {
  return new Promise((resolve, reject) => {
    withStore("readwrite", ({ transaction }) => {
      const store = transaction.objectStore("bookmarked_posts");

      // Check if category already exists
      const checkRequest = store.get(category);
      checkRequest.onsuccess = () => {
        if (checkRequest.result) {
          reject(`Category "${category}" already exists.`);
        } else {
          // Create a new category with an empty array of posts
          const categoryData = {
            category, // Category key
            bookmarked_posts: [], // Start with an empty array
          };
          store.put(categoryData); // Add the new category to the store
          resolve(true); // Resolve the promise when done
        }
      };

      checkRequest.onerror = (e) => {
        reject(`Error checking category existence: ${e.target.error}`);
      };
    }).catch((error) => {
      reject(`Transaction failed: ${error}`);
    });
  });
}

// Function to get all posts from a specific category, defaults to "uncategorized"
function getPostsByCategory(category = "uncategorized") {
  return new Promise((resolve, reject) => {
    withStore("readonly", ({ transaction }) => {
      const store = transaction.objectStore("bookmarked_posts");
      const request = store.get(category);

      request.onsuccess = () => {
        const categoryData = request.result;
        if (categoryData && categoryData.bookmarked_posts) {
          resolve(categoryData.bookmarked_posts);
        } else {
          resolve([]); // Return an empty array if category doesn't exist
        }
      };

      request.onerror = () =>
        reject("Failed to get posts from category: " + request.error);
    });
  });
}

// Function to get all posts across all categories or directly if no categories exist
function getAllPosts() {
  return new Promise((resolve, reject) => {
    withStore("readonly", ({ transaction }) => {
      const store = transaction.objectStore("bookmarked_posts");
      const request = store.getAll();
      request.onsuccess = () => {
        const results = request.result;
        let allPosts = [];
        results.forEach((category) => {
          if (Array.isArray(category.bookmarked_posts)) {
            allPosts = [...allPosts, ...category.bookmarked_posts];
          }
        });
        resolve(allPosts);
      };
      request.onerror = () =>
        reject("Failed to get all posts: " + request.error);
    });
  });
}

function getAllCategories() {
  return new Promise((resolve, reject) => {
    withStore("readonly", ({ transaction }) => {
      const store = transaction.objectStore("bookmarked_posts");

      const request = store.getAllKeys(); // Get all keys (categories)

      request.onsuccess = () => {
        const categories = request.result;
        resolve(categories); // Return the list of categories
      };

      request.onerror = () =>
        reject("Failed to get categories: " + request.error);
    });
  });
}

// Function to delete a post by postId, searching across all categories
function deletePost(postId) {
  withStore("readwrite", ({ transaction }) => {
    const store = transaction.objectStore("bookmarked_posts");

    // Retrieve all categories and their posts
    const request = store.getAll();

    request.onsuccess = () => {
      const categories = request.result;

      // Iterate over categories to find and remove the post
      for (const categoryData of categories) {
        if (Array.isArray(categoryData.bookmarked_posts)) {
          const postIndex = categoryData.bookmarked_posts.findIndex(
            (post) => post.post_id === postId
          );

          // If the post is found, remove it
          if (postIndex !== -1) {
            categoryData.bookmarked_posts.splice(postIndex, 1);

            // Update the store with the modified category
            store.put(categoryData);

            // Exit after finding and deleting the post
            break;
          }
        }
      }
    };

    request.onerror = () => {
      console.error("Failed to delete post: " + request.error);
    };
  }).catch((error) => {
    console.error("Failed to delete post: ", error);
  });
}

function deleteSelectedCategories(selectedCategories) {
  return new Promise((resolve, reject) => {
    withStore("readwrite", ({ transaction }) => {
      const store = transaction.objectStore("bookmarked_posts");

      // Iterate over the selected categories and delete each one
      selectedCategories.forEach((category) => {
        const deleteRequest = store.delete(category);

        deleteRequest.onerror = (event) => {
          console.error(
            `Error deleting category "${category}":`,
            event.target.errorCode
          );
        };
      });

      // Resolve once the transaction completes
      transaction.oncomplete = () => {
        resolve();
      };

      transaction.onerror = (event) => {
        console.error(
          "Transaction failed while deleting selected categories:",
          event.target.error
        );
      };
    }).catch((error) => {
      console.error(
        "Failed to open transaction for deleting selected categories:",
        error
      );
      reject(error);
    });
  });
}

export {
  openDb,
  withStore,
  createCategory,
  addPost,
  getPost,
  getPostsByCategory,
  getAllPosts,
  getAllCategories,
  deletePost,
  deleteSelectedCategories,
};
