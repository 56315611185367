import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  Paper,
  TextField,
  IconButton,
} from "@mui/material";
import { fetchFeatures, formatDate, updateBundleFeatures } from "./utils";
import { Delete, Edit, Save, Add } from "@mui/icons-material";

const ViewFeaturesPopup = ({ open, onClose, features, setFeatures }) => {
  const [editingId, setEditingId] = useState(null); // ID of the feature being edited
  const [newFeature, setNewFeature] = useState({
    name: "",
    description: "",
  });
  const [count, setCount] = useState(0); // Total count for pagination
  const [currentPage, setCurrentPage] = useState(0); // Current page (zero-indexed)
  const [itemsPerPage, setItemsPerPage] = useState(5); // Items per page
  const [loading, setLoading] = useState(false);

  // Fetch features on load and page/item change
  useEffect(() => {
    fetchFeatures(
      setFeatures,
      setCount,
      currentPage + 1,
      itemsPerPage,
      setLoading
    );
  }, [currentPage, itemsPerPage]);

  // Handle editing a feature
  const handleEdit = (id) => {
    setEditingId(id);
  };

  const handleChange = (id, field, value) => {
    const updatedFeatures = features.map((feature) =>
      feature.id === id ? { ...feature, [field]: value } : feature
    );
    setFeatures(updatedFeatures);
  };

  // Add a new feature
  const handleAddFeature = () => {
    if (newFeature.name.trim() && newFeature.description.trim()) {
      const newFeatureObj = {
        id: null,
        name: newFeature.name,
        description: newFeature.description,
        created_at: new Date().toISOString(),
      };
      setFeatures([...features, newFeatureObj]);
      setNewFeature({ name: "", description: "" });
    }
  };

  // Delete a feature
  const handleDelete = (id) => {
    setFeatures(features.filter((feature) => feature.id !== id));
  };

  // Save changes
  const handleSaveChanges = async () => {
    await updateBundleFeatures(features);
    fetchFeatures(
      setFeatures,
      setCount,
      currentPage + 1,
      itemsPerPage,
      setLoading
    );
    setEditingId(null);
  };

  // Handle pagination change
  const handlePageChange = (_, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0); // Reset to first page when changing items per page
  };

  // Paginate displayed features
  const paginatedFeatures = features.slice(
    currentPage * itemsPerPage,
    currentPage * itemsPerPage + itemsPerPage
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Bundle Features</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedFeatures.length > 0 ? (
                paginatedFeatures.map((feature) => (
                  <TableRow key={feature.id}>
                    <TableCell>
                      {editingId === feature.id ? (
                        <TextField
                          value={feature.name}
                          onChange={(e) =>
                            handleChange(feature.id, "name", e.target.value)
                          }
                          fullWidth
                        />
                      ) : (
                        feature.name || "N/A"
                      )}
                    </TableCell>
                    <TableCell>
                      {editingId === feature.id ? (
                        <TextField
                          value={feature.description}
                          onChange={(e) =>
                            handleChange(
                              feature.id,
                              "description",
                              e.target.value
                            )
                          }
                          fullWidth
                        />
                      ) : (
                        feature.description || "N/A"
                      )}
                    </TableCell>
                    <TableCell>
                      {formatDate(feature.created_at) || "N/A"}
                    </TableCell>
                    <TableCell>
                      {editingId === feature.id ? (
                        <IconButton
                          color="primary"
                          onClick={() => setEditingId(null)}
                        >
                          <Save />
                        </IconButton>
                      ) : (
                        <IconButton
                          color="primary"
                          onClick={() => handleEdit(feature.id)}
                        >
                          <Edit />
                        </IconButton>
                      )}
                      <IconButton
                        color="secondary"
                        onClick={() => handleDelete(feature.id)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No features available.
                  </TableCell>
                </TableRow>
              )}

              {/* Row to add a new feature */}
              <TableRow>
                <TableCell>
                  <TextField
                    value={newFeature.name}
                    onChange={(e) =>
                      setNewFeature({ ...newFeature, name: e.target.value })
                    }
                    placeholder="Feature Name"
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    value={newFeature.description}
                    onChange={(e) =>
                      setNewFeature({
                        ...newFeature,
                        description: e.target.value,
                      })
                    }
                    placeholder="Feature Description"
                    fullWidth
                  />
                </TableCell>
                <TableCell>N/A</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={handleAddFeature}>
                    <Add />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {/* Pagination */}
        <TablePagination
          component="div"
          count={count}
          rowsPerPage={itemsPerPage}
          page={currentPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={[5, 10, 20]}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSaveChanges} color="primary" variant="contained">
          Save Changes
        </Button>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewFeaturesPopup;
