import React, { useState } from "react";
import styled from "styled-components";
import Select from "react-select";
import { playSound, audioFiles } from "../utils/playSound";
import { useCustomStyles } from "../utils/CustomStyles"; // Import customStyles

const NotificationSelect = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
`;

const RadioButtonLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

const RadioButton = styled.input`
  cursor: pointer;
`;

// dont change == 1 to === 1 it will break the code
const Notifications = ({
  onNotificationChange,
  onNotificationSoundChange,
  column,
}) => {
  const [notificationEnabled, setNotificationEnabled] = useState(
    column && column.notifications == 1
  );
  const [notificationSound, setNotificationSound] = useState(
    column ? column.isLoudSound : "Off"
  );

  const customStyles = useCustomStyles();

  const handleNotificationChange = (event) => {
    onNotificationChange(event);
    setNotificationEnabled(event.target.value === "On");
  };

  const handleNotificationSoundChange = (selectedOption) => {
    const event = { target: { value: selectedOption.value } };
    onNotificationSoundChange(event);
    setNotificationSound(selectedOption.value);
    playSound(selectedOption.value);
  };

  const soundOptions = [
    { value: "Off", label: "Off" },
    ...Object.keys(audioFiles).map((key) => ({
      value: key,
      label: key.charAt(0).toUpperCase() + key.slice(1),
    })),
  ];

  return (
    <div className="afj-notification-container">
      <h3>Notifications</h3>
      <NotificationSelect>
        <RadioButtonLabel>
          <RadioButton
            type="radio"
            name="notification"
            value="On"
            checked={notificationEnabled === true}
            onChange={handleNotificationChange}
          />
          On
        </RadioButtonLabel>
        <RadioButtonLabel>
          <RadioButton
            type="radio"
            name="notification"
            value="Off"
            checked={notificationEnabled === false}
            onChange={handleNotificationChange}
          />
          Off
        </RadioButtonLabel>
      </NotificationSelect>
      {notificationEnabled && (
        <>
          <h3>Notification Sound Alert</h3>
          <NotificationSelect>
            <Select
              styles={customStyles}
              value={soundOptions.find(
                (option) => option.value === notificationSound
              )}
              onChange={handleNotificationSoundChange}
              options={soundOptions}
              isSearchable
            />
          </NotificationSelect>
        </>
      )}
    </div>
  );
};

export default Notifications;
