import React, { createContext, useContext, useState, useEffect } from "react";
import { postAuth } from "../../../global/globalUtils";

const defaultAuthState = {
  isAuthenticated: false,
  email_verified: false,
  isLoading: true,
  role: "user",
  bundle: "",
  userId: "",
  tokenAttemptedRefresh: false,
};

const AuthContext = createContext({
  ...defaultAuthState,
  setAuth: () => {},
});
export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(defaultAuthState);

  const updateAuth = (newAuth) => {
    setAuth((prev) => ({ ...prev, ...newAuth }));
  };

  const handleAuthResponse = (response, data) => {
    if (response.ok) {
      switch (data.status) {
        case "unverified":
          updateAuth({
            isAuthenticated: false,
            email_verified: false,
            isLoading: false,
          });
          break;
        case "success":
          updateAuth({
            isAuthenticated: true,
            isLoading: false,
            email_verified: data.email_verified,
            role: data.role,
            userId: data.userId,
            bundle: data.bundle,
          });
          break;
        case "completed":
          updateAuth({
            isAuthenticated: true,
            isLoading: false,
            email_verified: data.email_verified,
            tokenAttemptedRefresh: false, // Reset on successful refresh
            role: data.role,
            userId: data.userId,
            bundle: data.bundle,
          });
          break;
        default:
          return false;
      }
      return true;
    } else {
      if (!auth.tokenAttemptedRefresh) {
        updateAuth({ tokenAttemptedRefresh: true }); // Prevent infinite loop
      }
      return false;
    }
  };

  const verifyToken = async () => {
    try {
      const response = await postAuth("/auth/validate-token", {});
      const data = await response.json();
      if (!handleAuthResponse(response, data)) {
        updateAuth({ isAuthenticated: false, isLoading: false });
      }
    } catch (error) {
      console.error("Request failed, Error during token validation:", error);
      updateAuth({ isAuthenticated: false, isLoading: false });
    }
  };

  useEffect(() => {
    verifyToken();
  }, []);

  return (
    <AuthContext.Provider value={{ ...auth, setAuth: updateAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
