import { post } from "../../../global/globalUtils";

export const startTrial = async (formData) => {
  const { name, email, phone, organizationCode, password } = formData;

  const requestBody = {
    name,
    email,
    phone,
    organizationCode,
    password,
  };

  try {
    const url = `/auth/start-trial`;

    const response = await post(url, requestBody);

    const result = await response.json();

    if (response.ok) {
      return {
        success: true,
        data: result,
      };
    } else {
      return {
        success: false,
        message: result.message || "Unknown error occurred",
      };
    }
  } catch (error) {
    return {
      success: false,
      message: "An error occurred. Please try again later.",
    };
  }
};
