import { toast } from "sonner";
import { getAuth, deleteAuth, putAuth } from "../../../../global/globalUtils";
import Swal from "sweetalert2";

export const formatDateForInput = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

const filterQueryParams = (params) => {
  return Object.fromEntries(
    Object.entries(params).filter(
      ([key, value]) => value !== null && value !== undefined && value !== ""
    )
  );
};

// Function to get subscriptions
export const fetchSubscriptions = async (
  filters,
  setSubscriptions,
  setLoading,
  setCount,
  page,
  limit
) => {
  setLoading(true);
  try {
    const filteredFilters = filterQueryParams(filters);
    const queryParams = new URLSearchParams({
      page,
      limit,
      ...filteredFilters,
    }).toString();
    const response = await getAuth(`/dashboard/subscription?${queryParams}`);
    const responseData = await response.json();
    setSubscriptions(responseData.data);
    setCount(responseData.count);
  } catch (error) {
    console.error("Failed to fetch subscriptions:", error);
    setSubscriptions([]);
    setCount(0);
  } finally {
    setLoading(false);
  }
};

// Function to update a subscription
export const updateSubscription = async (data) => {
  try {
    const response = await putAuth(`/dashboard/subscription`, data);
    await response.json();
    if (response.ok) {
      toast.success("Subscription updated successfully", {
        position: "top-center",
      });
    } else {
      toast.error("Failed to update subscription", { position: "top-center" });
    }
  } catch (error) {
    console.error("Failed to update subscription:", error);
  }
};

// Function to deactivate a subscription
export const deactivateSubscription = async (
  id,
  filteredData,
  setFilteredData
) => {
  try {
    const response = await deleteAuth(
      `/dashboard/subscription/deactivate/${id}`
    );
    await response.json();
    if (response.ok) {
      Swal.fire("Success", "Subscription deactivated successfully", "success");
      const updatedSubscriptions = filteredData.filter(
        (subscription) => subscription.id !== id
      );
      setFilteredData(updatedSubscriptions);
    }
  } catch (error) {
    console.error("Failed to deactivate subscription:", error);
  }
};
