import React from "react";
import styled from "styled-components";
import { useTheme } from "../../../themes/ThemeContext";

const CustomButton = styled.button`
  min-width: 90px;
  border-radius: 20px; /* Rounded corners */
  text-transform: none; /* No uppercase text */
  border: 1px solid
    ${({ $borderColor, $theme }) =>
      $borderColor ||
      ($theme === "dark"
        ? "#ffffff"
        : "#1e2836")}; /* Outline color based on prop or theme */
  color: ${({ $theme, $color }) =>
    $color ||
    ($theme === "dark"
      ? "#ffffff"
      : "#1e2836")}; /* Text color based on theme or prop */
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor ||
    "transparent"}; /* Background color from prop or transparent */
  padding: 8px 16px; /* Padding */
  font-size: 0.875rem; /* Font size */
  font-weight: 500; /* Font weight */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Transition effects */
  display:flex;
  justify-content:center;
  align-items:center;

  &:hover {
    border-color: ${({ $borderColor, $theme }) =>
      $borderColor ||
      ($theme === "dark" ? "#ffffff" : "#1e2836")}; /* Outline color on hover */
    background-color: ${({ $hoverBackgroundColor, $theme }) =>
      $hoverBackgroundColor ||
      ($theme === "dark"
        ? "#ffffff20"
        : "#1e283620")}; /* Background color on hover from prop or default */
    color: ${({ $theme, $hoverColor }) =>
      $hoverColor ||
      ($theme === "dark"
        ? "#ffffff"
        : "#1e2836")}; /* Text color on hover from prop or default */
  }

  &:focus {
    outline: none; /* Remove default focus outline */
    box-shadow: 0 0 0 3px
      ${({ $theme }) => ($theme === "dark" ? "#ffffff50" : "#1e283650")}; /* Custom focus outline */
  }
`;

const SettingsButton = ({
  children,
  backgroundColor,
  hoverBackgroundColor,
  color,
  hoverColor,
  borderColor,
  ...props
}) => {
  const { theme } = useTheme();
  return (
    <CustomButton
      $theme={theme}
      $backgroundColor={backgroundColor}
      $hoverBackgroundColor={hoverBackgroundColor}
      $color={color}
      $hoverColor={hoverColor}
      $borderColor={borderColor}
      {...props}
    >
      {children}
    </CustomButton>
  );
};

export default SettingsButton;
