import React, { useEffect, useState } from "react";
import { Button as MuiButton } from "@mui/material";
import { deleteAuth, getAuth } from "../../../../global/globalUtils";
import styled from "styled-components";
import { toast } from "sonner";

const PaginationControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; /* Center align items vertically */
  margin-top: 20px;

  & > button {
    margin: 0 5px;
  }

  .page-info {
    margin: 0 10px;
    font-weight: bold;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  gap: 10px; /* Space between buttons */
`;

const Table = () => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Adjust this value for the number of items per page
  const [filteredData, setFilteredData] = useState([]); // To store filtered data
  const [searchTerm, setSearchTerm] = useState(""); // State to hold search term

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getAuth(
        `/dashboard/channel_requests/?page=${currentPage}&limit=${itemsPerPage}&search=${searchTerm}`
      );
      const responseData = await response.json();
      setFilteredData(responseData.data); // Initially set filtered data to be the same as fetched data
      setCount(responseData.total);
    } catch (error) {
      console.error("Failed to fetch users:", error);
      setFilteredData([]); // Clear filtered data if fetch fails
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, searchTerm]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(count / itemsPerPage);

  const deleteChannelRequest = async (id) => {
    try {
      const response = await deleteAuth(`/dashboard/channel_requests/${id}`);
      await response.json();
      if (response.ok) {
        toast.success("Channel request deleted successfully");
        fetchData();
      } else {
        toast.error("Failed to delete channel request");
      }
    } catch (error) {
      console.error("Failed to resolve channel request", error);
    }
  };

  return (
    <>
      <p style={{ marginBottom: "20px" }}>
        Number Of Requested Channels: {count}
      </p>
      <div className="data-table-diagram">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th>Requested Channel</th>
                  <th>Platform</th>
                  <th>Requested By</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 ? (
                  filteredData.map((item) => (
                    <tr key={item.id}>
                      <td>{item.channel || "N/A"}</td>
                      <td>{item.Source?.name || "N/A"}</td>
                      <td>{item.User?.email || "N/A"}</td>
                      <td>
                        <ButtonContainer>
                          <MuiButton
                            onClick={() => {
                              deleteChannelRequest(item.id);
                            }}
                            variant="contained"
                            sx={{
                              backgroundColor: "#007bff", // Blue color for "View Team"
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#0056b3", // Darker blue on hover
                              },
                            }}
                          >
                            Resolve
                          </MuiButton>
                        </ButtonContainer>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8">No Channel Requests found</td>
                  </tr>
                )}
              </tbody>
            </table>
            <PaginationControls>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              >
                First
              </MuiButton>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </MuiButton>
              <span className="page-info">
                Page {currentPage} of {totalPages}
              </span>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </MuiButton>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                Last
              </MuiButton>
            </PaginationControls>
          </>
        )}
      </div>
    </>
  );
};

export default Table;
