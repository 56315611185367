import React, { useState } from "react";
import styled from "styled-components";
import { postAuth } from "../../../../global/globalUtils";
import {
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { toast } from "sonner";

const Form = styled.form`
  display: flex;
  flex-direction: row;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
  max-width: 400px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  outline: none;

  &:focus {
    border-color: #007bff;
  }
`;

const Button = styled.button`
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #0056b3;
  }
`;

const LeftColumn = styled.div`
  flex: 1;
`;

const RightColumn = styled.div`
  flex: 1;
`;

const CreateBundleForm = () => {
  const [slug, setSlug] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [maxUsers, setMaxUsers] = useState("");
  const [priceMonthly, setPriceMonthly] = useState("");
  const [priceYearly, setPriceYearly] = useState("");
  const [status, setStatus] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await postAuth(`/bundle`, {
        slug,
        display_name: displayName,
        max_users: maxUsers,
        price_per_user_monthly: priceMonthly,
        price_per_user_yearly: priceYearly,
        status: status ? 1 : 0,
      });
      const responseData = await response.json();
      if (responseData.status === "success") {
        toast.success("Bundle created successfully");
      } else {
        toast.error("Failed to create bundle");
      }
    } catch (error) {
      console.error("Failed to create organization:", error);
      toast.error("Unexpected error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" component="div">
          Create Bundle
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Form onSubmit={handleSubmit}>
          <LeftColumn>
            <FormGroup>
              <Label htmlFor="slug">Slug:</Label>
              <Input
                type="text"
                id="slug"
                value={slug}
                onChange={(e) => setSlug(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="display_name">Display Name:</Label>
              <Input
                type="text"
                id="display_name"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="max_users">Maximum Users:</Label>
              <Input
                type="number"
                id="max_users"
                value={maxUsers}
                onChange={(e) => setMaxUsers(e.target.value)}
                required
              />
            </FormGroup>
          </LeftColumn>

          <RightColumn>
            <FormGroup>
              <Label htmlFor="price_monthly">Price Per User (Monthly):</Label>
              <Input
                type="number"
                step="0.01"
                id="price_monthly"
                value={priceMonthly}
                onChange={(e) => setPriceMonthly(e.target.value)}
                required
                min="0"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="price_yearly">Price Per User (Yearly):</Label>
              <Input
                type="number"
                step="0.01"
                id="price_yearly"
                value={priceYearly}
                onChange={(e) => setPriceYearly(e.target.value)}
                required
                min="0"
              />
            </FormGroup>

            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={status}
                    onChange={(e) => setStatus(e.target.checked)}
                  />
                }
                label="Active Status"
              />
            </FormGroup>
          </RightColumn>
        </Form>

        <Button onClick={handleSubmit} disabled={loading}>
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Create Bundle"
          )}
        </Button>
      </AccordionDetails>
    </Accordion>
  );
};

export default CreateBundleForm;
