import { getAuth } from "../../global/globalUtils";

export const getAllBundles = async () => {
  try {
    const response = await getAuth("/bundle");

    const data = await response.json();

    if (response.ok) {
      return { success: true, data: data.data };
    } else {
      return {
        success: false,
        message: data.message || "Failed to fetch bundles",
      };
    }
  } catch (error) {
    console.error("Network Error:", error);
    return { success: false, message: error.message || "Network Error" };
  }
};

export const fetchBundles = async (setBundles) => {
  const data = await getAllBundles();
  if (data.success) {
    const filteredBundles = data.data.filter(
      (bundle) => bundle.slug.toLowerCase() !== "free_trial"
    );
    setBundles(filteredBundles);
  } else {
    return [];
  }
};

export const statisPlans = [
  {
    name: "Easy",
    discretion: "All the basics for businesses that are just getting started.",
    price: {
      monthly: 29,
      annually: 29 * 12 - 49,
    },
    features: ["One project", "Your dashboard"],
  },
  {
    name: "Basic",
    discretion: "Better for growing businesses that want more customers.",
    price: {
      monthly: 59,
      annually: 59 * 12 - 109,
    },
    features: [
      "Two projects",
      "Your dashboard",
      "Components included",
      "Advanced charts",
    ],
  },
  {
    name: "Custom",
    discretion: "Advanced features for pros who need more customization.",
    price: {
      monthly: 139,
      annually: 139 * 12 - 169,
    },
    features: [
      "Unlimited projects",
      "Your dashboard",
      "+300 Components",
      "Chat support",
    ],
  },
];
