import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded"; // Import the Article icon
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import logo from "../../../assets/images/teledeck.png";
import whiteLogo from "../../../assets/images/Teledeck.svg";
import { useTheme } from "../../themes/ThemeContext";

const items = [
  {
    icon: <ArticleRoundedIcon sx={{ color: "text.secondary" }} />, // Use the Article icon
    title: "The Ultimate News Monitoring Platform",
    description:
      "Discover Teledeck, an exciting news monitoring platform complete with a live map and a news auditor.",
  },
  {
    icon: <LocalOfferRoundedIcon sx={{ color: "text.secondary" }} />,
    title: "Explore Our Bundles",
    description: (
      <>
        Whether you're an individual or part of an organization, we have the
        perfect plan for you.{" "}
        <a
          href="https://teledeck.news/pricing"
          target="_blank"
          rel="noopener noreferrer"
        >
          View Bundles
        </a>
      </>
    ),
  },
  {
    icon: <PersonAddRoundedIcon sx={{ color: "text.secondary" }} />,
    title: "Don't have an account?",
    description:
      "Subscribe as a solo user or as part of an organization, use the signup code provided by teledeck or your organization admin.",
  },
  {
    icon: <ThumbUpAltRoundedIcon sx={{ color: "text.secondary" }} />,
    title: "Great User Experience",
    description:
      "Integrate our product into your routine with an intuitive and easy-to-use interface.",
  },
];

export default function Content() {
  const { theme } = useTheme();
  return (
    <Stack
      sx={{
        flexDirection: "column",
        alignSelf: "center",
        gap: 4,
        maxWidth: 450,
      }}
    >
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <img
          src={theme === "light" ? logo : whiteLogo}
          alt="Teledeck"
          style={{ width: "250px" }}
        />
      </Box>
      {items.map((item, index) => (
        <Stack key={index} direction="row" sx={{ gap: 2 }}>
          {item.icon}
          <div>
            <Typography gutterBottom sx={{ fontWeight: "medium" }}>
              {item.title}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {item.description}
            </Typography>
          </div>
        </Stack>
      ))}
    </Stack>
  );
}
