import { useEffect, useRef, useCallback } from "react";
import { useAuth } from "../components/authentication/auth-context/AuthContext";
import { postAuth } from "./globalUtils";

const useUserTimeTracker = () => {
  const { userId } = useAuth();
  const startTimeRef = useRef(Date.now());
  const hasSentTime = useRef(false);

  const sendTimeToServer = useCallback(async () => {
    if (!startTimeRef.current || hasSentTime.current) return;

    const endTime = Date.now();
    const timeSpent = endTime - startTimeRef.current;

    try {
      const response = await postAuth("/auth/record-time", {
        timeSpent,
        userId,
      });
      const data = await response.json();
      hasSentTime.current = true;
    } catch (error) {
      console.error("Failed to send time to server: ", error);
    }
  }, [userId]);

  const handleBeforeUnload = useCallback(
    (event) => {
      sendTimeToServer();
    },
    [sendTimeToServer]
  );

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === "hidden") {
      sendTimeToServer();
    } else if (document.visibilityState === "visible") {
      // Reset startTime and hasSentTime when the user returns to the tab
      startTimeRef.current = Date.now();
      hasSentTime.current = false;
    }
  }, [sendTimeToServer]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [handleBeforeUnload, handleVisibilityChange]);

  return null;
};

export default useUserTimeTracker;
