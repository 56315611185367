import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

const HeaderContainer = styled.div`
  position: relative;
  cursor: grab;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  background-color: #1e2836;
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 16px;
`;

const DateButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const DropdownContainer = styled.div`
  position: absolute;
  right: 16px;
  top: calc(100% + 8px);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 16px;
  min-width: 300px;
  z-index: 1000;
  color: #333;
`;

const DateInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 12px;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: #666;
`;

const StyledDateInput = styled.input`
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #ddd;
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: #2196f3;
  }
`;
const Button = styled.button`
  flex: 1;
  padding: 8px;
  border-radius: 6px;
  border: none;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
`;

const ClearButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #f5f5f5;
  color: #666;

  &:hover {
    background-color: #e0e0e0;
  }

  span {
    font-size: 18px;
    &:hover {
      color: red;
    }
  }
`;

const DateFilter = ({ dateRange, setDateRange, onMouseDown }) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef(null);

  const handleDateChange = (type) => (event) => {
    setDateRange((prev) => ({
      ...prev,
      [type]: event.target.value,
    }));
  };

  const formatDateRange = () => {
    if (!dateRange.from && !dateRange.to) return "Select Date Range";

    const formatDate = (dateStr) => {
      if (!dateStr) return "";
      return new Date(dateStr).toLocaleDateString();
    };

    return `${formatDate(dateRange.from)} - ${formatDate(dateRange.to)}`;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleClear = () => {
    const clearedRange = { from: null, to: null };
    setDateRange(clearedRange);
    setIsOpen(false);
  };

  return (
    <HeaderContainer>
      <HeaderContent onMouseDown={onMouseDown}>
        <Title>Teledeck AI</Title>
        <DateButton onClick={() => setIsOpen(!isOpen)}>
          <span className="material-icons">date_range</span>
          {formatDateRange()}
        </DateButton>
      </HeaderContent>

      {isOpen && (
        <DropdownContainer ref={dropdownRef}>
          <DateInputGroup>
            <Label htmlFor="date-from">From</Label>
            <StyledDateInput
              id="date-from"
              type="date"
              value={dateRange.from || ""}
              onChange={handleDateChange("from")}
              max={dateRange.to || undefined}
            />
          </DateInputGroup>

          <DateInputGroup>
            <Label htmlFor="date-to">To</Label>
            <StyledDateInput
              id="date-to"
              type="date"
              value={dateRange.to || ""}
              onChange={handleDateChange("to")}
              min={dateRange.from || undefined}
            />
          </DateInputGroup>
          {(dateRange.to || dateRange.from) && (
            <ClearButton onClick={handleClear}>
              Clear
              <span className="material-icons">clear</span>
            </ClearButton>
          )}
        </DropdownContainer>
      )}
    </HeaderContainer>
  );
};

export default DateFilter;
