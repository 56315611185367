import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContainer = styled.div`
  position: relative; /* Ensure relative positioning for the back icon */
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  background-color: ${({ theme }) => theme.main};
  border-radius: 8px; /* Optional for rounded corners */
`;

const BackIcon = styled.span`
  position: absolute;
  top: 60px; /* Adjust for spacing from the top */
  left: 15px; /* Adjust for spacing from the left */
  font-size: 24px; /* Icon size */
  color: ${({ theme }) => theme.text};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

const WidgetContainer = styled.div`
  width: 100%;
  max-height: 95vh;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.popup_background};

  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    width: 8px; /* Decrease the width of the scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.scrollbarTrack};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.scrollbarThumb}; 
    border-radius: 10px;
    border: 2px solid ${({ theme }) => theme.scrollbarTrack}; /* Adds space around thumb */
  }

  /* Firefox scrollbar styles */
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.scrollbarThumb}
    ${({ theme }) => theme.scrollbarTrack};
`;

function TwitterPopup({ tweetId, onClose }) {
  const widgetRef = useRef(null);

  useEffect(() => {
    // Ensure the Twitter library is loaded
    const embedTweet = async () => {
      try {
        if (window.twttr && window.twttr.widgets) {
          // Clear any existing content
          widgetRef.current.innerHTML = "";

          // Create the tweet widget
          await window.twttr.widgets.createTweet(tweetId, widgetRef.current, {
            align: "center", // Customize widget alignment
          });
          // console.log("Tweet embedded successfully");
        } else {
          console.error("Twitter widgets.js is not loaded yet.");
        }
      } catch (error) {
        console.error("Failed to embed tweet:", error);
      }
    };

    embedTweet();
  }, [tweetId]);

  const handleBackgroundClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <Background onClick={handleBackgroundClick}>
      <PopupContainer>
        <BackIcon onClick={onClose} className="material-icons">
          keyboard_return
        </BackIcon>
        <WidgetContainer ref={widgetRef} />
      </PopupContainer>
    </Background>
  );
}

export default TwitterPopup;
