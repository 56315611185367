import styled, { keyframes, css } from "styled-components";
import "../../../assets/css/Popup.css";
import "../../../assets/css/chips.css";

const Column = styled.div`
  background-color: ${({ theme }) => theme.column_background};
  margin-left: 10px;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: calc(100% - 10px);
  color: ${({ theme }) => theme.column_text};
`;

const ThemedInput = styled.input`
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  border-color: ${({ theme }) => theme.border_color};
  width: 50%;
`;

const StrongWord = styled.strong`
  color: ${({ theme }) => theme.text};
`;

const ColumnBody = styled.div`
  width: 100%;
  position: relative;
  height: calc(100% - 50px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    transition: background-color 0.3s linear;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.border_color};
      transition: background-color 0.3s linear;
    }
  }
`;

const ColumnHeader = styled.div`
  box-sizing: border-box;
  padding: 10px;
  background-color: ${({ theme }) => theme.filter_background};
  width: 300px;
  height: 50px;
  border-bottom: 1px solid ${({ theme }) => theme.border_color};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ColumnHeaderText = styled.div`
  font-size: 16px;
  text-align: center;
  width: 150px;
  padding: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ColumnHeaderIcon = styled.i`
  font-size: 24px;
`;

const ColumnHeaderIconClick = styled.i`
  font-size: 20px;
  cursor: pointer;
  &:hover {
    opacity: 0.87;
  }
`;

const ChipContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FiltersDropDown = styled.div`
  background-color: ${({ theme }) => theme.filter_background};
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  top: 0px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  gap: 10px;
  margin-bottom: 2px;
`;

const Chip = styled.span`
  color: ${({ theme }) => theme.column_text};
  padding: 2px;
  border-radius: 3px;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid
    ${({ $active, theme }) =>
      $active ? theme.chip_active : theme.chip_inactive};
  color: ${({ $active, theme }) => ($active ? "#fff" : theme.chip_inactive)};
  background-color: ${({ $active, theme }) =>
    $active ? theme.blue : "transparent"};
`;

const LoadButton = styled.button`
  font-size: 14px;
  font-weight: bold;
  margin-top: 2px;
  padding: 10px;
  background-color: ${({ theme }) => theme.chip_inactive};
  color: ${({ theme }) => theme.text};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  &:hover {
    opacity: 0.87;
  }
`;

const SearchHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.filter_background};
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 5px 0;
  min-width: 150px;
  z-index: 10;
  top: 100%;
  right: 0;
  animation: ${({ $isOpen }) =>
    $isOpen
      ? css`
          ${fadeIn} 0.3s ease-in-out forwards
        `
      : css`
          ${fadeOut} 0.3s ease-in-out forwards
        `};
`;

const DropdownItem = styled.div`
  padding: 8px 20px;
  white-space: nowrap; // Prevents text from wrapping
  cursor: pointer;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  &:hover {
    background-color: ${({ theme }) =>
      theme === "dark"
        ? "#555555"
        : "#e0e0e0"}; // Light grey for light mode, dark grey for dark mode
  }
`;

const SourceChipContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const SourceChip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${({ $active }) => ($active ? "#007bff" : "#e0e0e0")};
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: ${({ $active }) => ($active ? "#0056b3" : "#c0c0c0")};
    transform: scale(1.1);
  }

  img {
    width: 30px;
    height: 30px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSourceImage = styled.img`
  width: 30px;
  height: 30px;
`;

const DropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: ${({ theme }) => theme.popup_background || "#fff"};
  color: ${({ theme }) => theme.text || "#333"};
  border: 1px solid ${({ theme }) => theme.border_color || "#ddd"};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 8px;
  border-radius: 4px;
  display: ${({ $show }) => ($show ? "block" : "none")};
  z-index: 1000;
  width: 65%;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: ${({ theme }) => theme.text || "#333"};

  input {
    margin-right: 8px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ $fullHeight }) => ($fullHeight ? "100%" : "auto")};
`;

export {
  Column,
  ThemedInput,
  StrongWord,
  ColumnBody,
  ColumnHeader,
  ColumnHeaderText,
  ColumnHeaderIcon,
  ColumnHeaderIconClick,
  ChipContainer,
  FiltersDropDown,
  Chip,
  LoadButton,
  SearchHeader,
  DropdownMenu,
  DropdownItem,
  SourceChipContainer,
  SourceChip,
  IconWrapper,
  StyledSourceImage,
  DropdownContainer,
  CheckboxLabel,
  LoadingContainer,
};
